$logo-brand-positiv-url: url("../../assets/images/logos/LA LEY - Positiv.svg");
$logo-brand-isotipo-positiv-url: url("../../assets/images/logos/LA LEY_isotipo - Positiv.svg");
$logo-brand-negativ-url: url("../../assets/images/logos/LA LEY - Negativ.svg");
$logo-brand-negativ-bos-url: url("../../assets/images/logos/LA LEY - Negativ Box.svg");

$logo-contractBox-url: url("../../assets/images/logos/logo_contractbox.png");
$logo-contractBox-isotipo-url: url("../../assets/images/logos/logo_contractbox_isotipo.png");
$logo-contractBox-white-url: url("../../assets/images/logos/contractbox-brand_white.svg");

$logo-santander-url: url("../../assets/images/logos/logo-santander.svg");
$logo-santander-isotipo-url: url("../../assets/images/logos/logo-santander.svg");
$logo-santander-white-url: url("../../assets/logos/logo-santander-white.svg");

.logo-branding {
    background-image: $logo-brand-positiv-url;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: block;
    height: 100%;
    width: 100%;

    &.isotipo {
        background-image: $logo-brand-isotipo-positiv-url;
    }

    &.negativ {
        background-image: $logo-brand-negativ-url;
    }
}

.logo-contractBox {
    background-image: $logo-contractBox-url;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: block;
    height: 100%;
    width: 100%;

    &.isotipo {
        background-image: $logo-contractBox-isotipo-url;
    }

    &.negative {
        background-image: $logo-contractBox-white-url;
    }
}

.logo-LLD {
    background-image: url("../../assets/images/LLD_logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    height: 100%;
    width: 100%;
}
