.validate-name {
    display: block;
    width: 100%;
    min-height: 40px;
}

.validator-text {
    display: none;
    line-height: 1.5em;

    &--error {
        color: var(--color-error);
        margin: 0 6px;
        display: inline-block !important;

        &--mandatory-option {
            color: var(--color-error);
            font-size: 1.6em;
            font-weight: 500;
            margin: 5px;
            float: left;
            clear: both;
            margin-top: -28px;
            margin-left: 192px;
        }

        &--mandatory-inline {
            color: var(--color-error);
            font-size: 1.2em;
            font-weight: 500;
        }

        span {
            color: var(--color-text);
        }
    }

    &.dni {
        max-width: 200px;
    }

    .ui-widget-overlay {
        z-index: 49;
        background: black;
        opacity: 0.2;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
    }

    select {
        &+span {
            padding: 8px 12px 8px 8px;
            font-size: 1.3em;
        }
    }

    div {
        &[aria-describedby="popup_container_alert"] {
            background-color: white;
            box-shadow: 0 0 7px 0 rgba(153, 153, 153, 1);
            width: 498px;
            height: 172px;
            border-radius: 6px;
            position: absolute !important;
            left: 50% !important;
            top: 50% !important;
            transform: translate(-50%, -50%);
            z-index: 50;

            .ui-dialog-titlebar {
                padding: 14px 14px;
                border-bottom: var(--border-default);

                button {
                    display: none;
                }
            }

            .ui-dialog-buttonpane {
                button {
                    background: var(--color-blue-300);
                    color: var(--color-white);
                    float: right;
                    border: none;
                    padding: 8px;
                    width: 74px;
                    margin: 6px 20px;
                    outline: none;
                }
            }

            .popupContainerClass {
                margin: 5px 0 10px 0;
                padding: 16px;
                border-bottom: var(--border-default);
            }
        }
    }
}

.rSelect {
    position: relative;

    .validator-text--error--mandatory-option {
        position: absolute;
        left: 5px;
        top: 0;
        margin-left: 0;
        margin-top: 0;
    }
}
