.ctbox {
    .header {
        &-logo {
            &.ctbox {
                display: flex;
                align-items: center;
            }
        }
    }
}
