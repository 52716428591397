@mixin mat-tree($theme) {
    .mat-tree {
        width: inherit;
        display: flex;
        flex-direction: column;

        &-node {
            user-select: none;
            width: inherit;
            max-width: 100%;
            gap: 8px;
            padding-right: 12px;
            &:hover {
                background-color: var(--tree-node-selected-background-color) !important;
            }
            &.node-selected {
                background-color: var(--tree-node-selected-background-color);
            }
            &.node-selected {
                .tree-node-text {
                    text-decoration: none;
                }
            }
            &.template,
            &.smartform {
                flex: initial;
            }

            &.menu {
                max-width: calc(100% - 40px);
            }

        }

        &.ctbox-elevation {
            padding: 24px;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &.cmc {

            background-color: var(--tree-background-color);

            .mat-tree-node {

                &.node-selected {
                    background-color: var(--color-white) !important;
                    color: var(--color-info) !important;

                    .tree-node-text {
                        background-color: var(--color-white) !important;
                        color: var(--color-info) !important;
                        font-weight: 400;
                        text-decoration: none;
                        & > span {
                            background-color: white !important;
                        }
                        &:hover {
                            color: var(--color-text) !important;
                        }
                    }
                }

                &:hover {
                    .tree-node-text {
                        color: var(--color-text) !important;
                    }
                }


            }
            &.inquire {
                background-color: var(--background-color-inquire) !important;
                padding-top:10px;
                margin-top: -10px;
                height: 105%;
            }
        }

        &.tree-filter {
            background-color: var(--tree-background-color);

            &-inquire {
                background-color: var(--background-color-inquire) !important;
                padding-top:10px;
                margin-top: -10px;
                height: 200%
            }

            .tree-node {
                &-icon {
                    .folder-icon {
                        color: var(--tree-folder-icon-color);
                    }

                    .expand-icon {
                        color: var(--tree-folder-icon-color);
                    }
                }
            }
        }

        &.admin-cmc {
            .ctbox-tree-actions {
                &.slider {
                    margin-right: 24px;
                }
            }
        }
    }
}
