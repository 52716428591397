@charset "UTF-8";
.logo-branding {
  background-image: url("../../assets/images/logos/LA LEY - Positiv.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  height: 100%;
  width: 100%;
}
.logo-branding.isotipo {
  background-image: url("../../assets/images/logos/LA LEY_isotipo - Positiv.svg");
}
.logo-branding.negativ {
  background-image: url("../../assets/images/logos/LA LEY - Negativ.svg");
}

.logo-contractBox {
  background-image: url("../../assets/images/logos/logo_contractbox.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  height: 100%;
  width: 100%;
}
.logo-contractBox.isotipo {
  background-image: url("../../assets/images/logos/logo_contractbox_isotipo.png");
}
.logo-contractBox.negative {
  background-image: url("../../assets/images/logos/contractbox-brand_white.svg");
}

.logo-LLD {
  background-image: url("../../assets/images/LLD_logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  height: 100%;
  width: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: var(--font-family-text);
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

b {
  font-weight: 500;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  border-radius: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500;
  line-height: 1.2;
}

:root {
  --color-dashboard: #232323;
  --color-documents: #4495cd;
  --color-templates: #629c2c;
  --color-clauses: #238274;
  --color-administration: #d2a922;
  --color-state-draft: #757575;
  --color-state-review: #7a9ae6;
  --color-state-completed: #95deff;
  --color-state-signing: #e6aa16;
  --color-state-signed: #85de66;
  --box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  --shadow-background: #f6f6f6;
  --border-color-default: #dadada;
  --border-default: 1px solid #dadada;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-info: #409bd2;
  --color-text: #232323;
  --color-text-secondary: #757575;
  --color-link: #232323;
  --color-link-hover: #000000;
  --color-link-editor: #1b3af2;
  --color-link-editor-hover: #1b3af2;
  --color-disabled: #dadada;
  --color-update: #005b92;
  --background-color-disabled: #bfbfbf;
  --background-color-deleted: #f7bcc0;
  --background-color-insert: #deead2;
  --background-color-input: #ededed;
  --tree-background-color: #ededed;
  --tree-node-selected-background-color: #f6f6f6;
  --tree-drag-background-color: #ffffff;
  --tree-folder-icon-color: #a3a3a3;
  --color-deleted: #ec5862;
  --color-delete: #e5202e;
  --color-created: #409bd2;
  --color-success: #85bc20;
  --color-error: #e5202e;
  --color-warning: #e6aa16;
  --color-warning-soft: #fcf5e3;
  --color-title: #409bd2;
  --color-landing: #0f4983;
  --color-santander: #ec0000;
  --color-santander-dark: #ad1e1e;
  --color-santander-black: #000000;
  --color-highlight: #fae16d;
  --color-folder-icon: #e6aa16;
  --color-red-500: #e5202e;
  --color-red-600: #b25955;
  --color-red-700: #92211d;
  --color-red-800: #ff4f47;
  --color-blue-100: #95deff;
  --color-blue-200: #80bde1;
  --color-blue-300: #4495cd;
  --color-blue-400: #007ac3;
  --color-blue-500: #409bd2;
  --color-blue-600: #005b92;
  --color-blue-700: #0f4983;
  --color-blue-800: #003d61;
  --color-blue-900: #001b36;
  --color-blue-A50: #eefaff;
  --color-blue-A100: #3a617e;
  --color-blue-A200: #add8e6;
  --color-blue-A400: #426f9e;
  --color-blue-A700: #207699;
  --color-green-50: #deead2;
  --color-green-100: #c6efb8;
  --color-green-200: #85de66;
  --color-green-300: #a4cd58;
  --color-green-400: #85bc20;
  --color-green-500: #629c2c;
  --color-green-700: #7ba13b;
  --color-orange-50: #fcf5e3;
  --color-orange-200: #f3d58b;
  --color-orange-500: #e6aa16;
  --color-orange-600: #d2a922;
  --color-orange-700: #c3a920;
  --color-orange-800: #a08c1b;
  --color-orange-900: #c18318;
  --color-gray-100: #f6f6f6;
  --color-gray-200: #ededed;
  --color-gray-300: #dadada;
  --color-gray-400: #bfbfbf;
  --color-gray-500: #a3a3a3;
  --color-gray-600: #757575;
  --color-gray-700: #474747;
  --color-gray-800: #232323;
  --font-family-text: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  --font-family-icons: Material Symbols Outlined;
  --display-go-back: none;
}

/* Set up a decent box model on the root element */
html {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden !important;
}

/* Basic typography style for copy text */
body {
  color: var(--color-text) !important;
  font-family: var(--font-family-text) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  height: 100% !important;
  padding: 0 !important;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since '*' has a specificity of 0, it does not override the 'html' value
 * making all elements inheriting from the root box-sizing value
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Basic styles for links */
.link {
  color: var(--color-link);
}

.link,
a {
  text-decoration: none;
}
.link:link, .link:active, .link:focus,
a:link,
a:active,
a:focus {
  color: var(--color-link);
}
.link:visited,
a:visited {
  color: var(--color-link);
}
.link:hover,
a:hover {
  color: var(--color-link-hover);
  text-decoration: underline;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

body.root {
  overflow: auto;
}

.ctbox-container {
  min-height: calc(calc(100vh - 104px) - 104px - 40px);
}
.ctbox-container .ctbox-nav {
  height: 40px;
}
.ctbox-container .root {
  min-height: calc(calc(100vh - 104px) - 104px - 40px);
}

.content-main {
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  min-width: 100%;
  padding: 0 24px 0 24px;
}
.content-main.with-tabs {
  height: calc(100vh - 153px);
  max-height: calc(100vh - 153px);
}
.content-main.with-tabs-tabs {
  height: calc(100vh - 202px);
  max-height: calc(100vh - 202px);
}
.content-main.with-tabs-actions {
  height: calc(100vh - 193px);
  max-height: calc(100vh - 193px);
}
.content-main.with-title {
  height: calc(100vh - 144px);
  max-height: calc(100vh - 144px);
}
.content-main.with-title-tabs {
  height: calc(100vh - 193px);
  max-height: calc(100vh - 193px);
}
.content-main.with-title-tabs .cke_contents {
  min-height: calc(100vh - 193px);
}
.content-main.with-title-actions {
  height: calc(100vh - 184px - 8px);
  max-height: calc(100vh - 184px - 8px);
  min-height: calc(100vh - 184px - 72px);
}
.content-main.with-title-actions .cke_contents {
  min-height: inherit;
}
.content-main.with-title-tabs-actions {
  height: calc(100vh - 234px);
  max-height: calc(100vh - 234px);
  min-height: calc(100vh - 234px - 100px);
}
.content-main.with-title-tabs-actions .cke_contents {
  min-height: inherit;
}
.content-main.with-tabs-title-tabs {
  height: calc(100vh - 240px);
  max-height: calc(100vh - 240px);
  min-height: calc(100vh - 240px);
}
.content-main.with-tabs-title-tabs .cke_contents {
  min-height: inherit;
}
.content-main.with-tabs-title-tabs-actions {
  height: calc(100vh - 283px);
  max-height: calc(100vh - 283px);
  min-height: calc(100vh - 283px);
}
.content-main.with-tabs-title-tabs-actions .cke_contents {
  min-height: inherit;
}
.content-main.with-title-bar-tabs {
  height: calc(100vh - 191px);
  max-height: calc(100vh - 191px);
  min-height: calc(100vh - 191px);
}
.content-main.with-title-bar-tabs .cke_contents {
  min-height: inherit;
}
.content-main.with-tabs-stepper {
  height: calc(100vh - 156px);
  max-height: calc(100vh - 156px);
  padding: 0;
}
.content-main.overflow-hidden, .content-main.resized {
  overflow: hidden;
  padding: 0;
}
.content-main.smart-forms {
  padding: 0 0 0 24px;
}
.content-main.with-stepper, .content-main.with-editor, .content-main.with-sidenav, .content-main.with-table-sticky {
  padding: 0;
}
.content-main.ctbox-elevation {
  min-width: calc(100% - 32px);
  padding: 0;
}
.content-main.home, .content-main.dashboard, .content-main.main {
  height: calc(100vh - 104px);
  max-height: calc(100vh - 104px);
  padding: 16px;
}
.content-main-list {
  max-width: 100%;
  min-width: 100%;
  min-height: inherit;
}
.content-main.result-list {
  padding: 0;
  min-width: auto;
}
.content-main .left-side {
  flex-grow: 0;
  height: inherit;
  max-height: inherit;
  overflow: auto;
}
.content-main .left-side.size-5 {
  min-width: 5%;
  max-width: 5%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-5 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-5 {
    min-width: 4%;
    max-width: 4%;
  }
}
.content-main .left-side.size-10 {
  min-width: 10%;
  max-width: 10%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-10 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-10 {
    min-width: 8%;
    max-width: 8%;
  }
}
.content-main .left-side.size-15 {
  min-width: 15%;
  max-width: 15%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-15 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-15 {
    min-width: 12%;
    max-width: 12%;
  }
}
.content-main .left-side.size-20 {
  min-width: 20%;
  max-width: 20%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-20 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-20 {
    min-width: 16%;
    max-width: 16%;
  }
}
.content-main .left-side.size-25 {
  min-width: 25%;
  max-width: 25%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-25 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-25 {
    min-width: 20%;
    max-width: 20%;
  }
}
.content-main .left-side.size-30 {
  min-width: 30%;
  max-width: 30%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-30 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-30 {
    min-width: 24%;
    max-width: 24%;
  }
}
.content-main .left-side.size-35 {
  min-width: 35%;
  max-width: 35%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-35 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-35 {
    min-width: 28%;
    max-width: 28%;
  }
}
.content-main .left-side.size-40 {
  min-width: 40%;
  max-width: 40%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-40 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-40 {
    min-width: 32%;
    max-width: 32%;
  }
}
.content-main .left-side.size-45 {
  min-width: 45%;
  max-width: 45%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-45 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-45 {
    min-width: 36%;
    max-width: 36%;
  }
}
.content-main .left-side.size-50 {
  min-width: 50%;
  max-width: 50%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-50 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-50 {
    min-width: 40%;
    max-width: 40%;
  }
}
.content-main .left-side.size-55 {
  min-width: 55%;
  max-width: 55%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-55 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-55 {
    min-width: 44%;
    max-width: 44%;
  }
}
.content-main .left-side.size-60 {
  min-width: 60%;
  max-width: 60%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-60 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-60 {
    min-width: 48%;
    max-width: 48%;
  }
}
.content-main .left-side.size-65 {
  min-width: 65%;
  max-width: 65%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-65 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-65 {
    min-width: 52%;
    max-width: 52%;
  }
}
.content-main .left-side.size-70 {
  min-width: 70%;
  max-width: 70%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-70 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-70 {
    min-width: 56%;
    max-width: 56%;
  }
}
.content-main .left-side.size-75 {
  min-width: 75%;
  max-width: 75%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-75 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-75 {
    min-width: 60%;
    max-width: 60%;
  }
}
.content-main .left-side.size-80 {
  min-width: 80%;
  max-width: 80%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-80 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-80 {
    min-width: 64%;
    max-width: 64%;
  }
}
.content-main .left-side.size-85 {
  min-width: 85%;
  max-width: 85%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-85 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-85 {
    min-width: 68%;
    max-width: 68%;
  }
}
.content-main .left-side.size-90 {
  min-width: 90%;
  max-width: 90%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-90 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-90 {
    min-width: 72%;
    max-width: 72%;
  }
}
.content-main .left-side.size-95 {
  min-width: 95%;
  max-width: 95%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-95 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-95 {
    min-width: 76%;
    max-width: 76%;
  }
}
.content-main .left-side.size-100 {
  min-width: 100%;
  max-width: 100%;
}
@media (max-width: 599px) {
  .content-main .left-side.size-100 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.size-100 {
    min-width: 80%;
    max-width: 80%;
  }
}
.content-main .left-side.resize-5 {
  width: 5%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-5 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-5 {
    width: 5;
  }
}
.content-main .left-side.resize-10 {
  width: 10%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-10 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-10 {
    width: 10;
  }
}
.content-main .left-side.resize-15 {
  width: 15%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-15 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-15 {
    width: 15;
  }
}
.content-main .left-side.resize-20 {
  width: 20%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-20 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-20 {
    width: 20;
  }
}
.content-main .left-side.resize-25 {
  width: 25%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-25 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-25 {
    width: 25;
  }
}
.content-main .left-side.resize-30 {
  width: 30%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-30 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-30 {
    width: 30;
  }
}
.content-main .left-side.resize-35 {
  width: 35%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-35 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-35 {
    width: 35;
  }
}
.content-main .left-side.resize-40 {
  width: 40%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-40 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-40 {
    width: 40;
  }
}
.content-main .left-side.resize-45 {
  width: 45%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-45 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-45 {
    width: 45;
  }
}
.content-main .left-side.resize-50 {
  width: 50%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-50 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-50 {
    width: 50;
  }
}
.content-main .left-side.resize-55 {
  width: 55%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-55 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-55 {
    width: 55;
  }
}
.content-main .left-side.resize-60 {
  width: 60%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-60 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-60 {
    width: 60;
  }
}
.content-main .left-side.resize-65 {
  width: 65%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-65 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-65 {
    width: 65;
  }
}
.content-main .left-side.resize-70 {
  width: 70%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-70 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-70 {
    width: 70;
  }
}
.content-main .left-side.resize-75 {
  width: 75%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-75 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-75 {
    width: 75;
  }
}
.content-main .left-side.resize-80 {
  width: 80%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-80 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-80 {
    width: 80;
  }
}
.content-main .left-side.resize-85 {
  width: 85%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-85 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-85 {
    width: 85;
  }
}
.content-main .left-side.resize-90 {
  width: 90%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-90 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-90 {
    width: 90;
  }
}
.content-main .left-side.resize-95 {
  width: 95%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-95 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-95 {
    width: 95;
  }
}
.content-main .left-side.resize-100 {
  width: 100%;
}
@media (max-width: 599px) {
  .content-main .left-side.resize-100 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .left-side.resize-100 {
    width: 100;
  }
}
.content-main .left-side.lld {
  padding: 0 16px;
}
.content-main .left-side.tree {
  width: 100%;
  min-width: 100%;
}
.content-main .right-side {
  flex-grow: 0;
  height: inherit;
  max-height: inherit;
  overflow: auto;
}
.content-main .right-side.size-5 {
  min-width: 5%;
  max-width: 5%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-5 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-5 {
    min-width: 4%;
    max-width: 4%;
  }
}
.content-main .right-side.size-10 {
  min-width: 10%;
  max-width: 10%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-10 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-10 {
    min-width: 8%;
    max-width: 8%;
  }
}
.content-main .right-side.size-15 {
  min-width: 15%;
  max-width: 15%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-15 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-15 {
    min-width: 12%;
    max-width: 12%;
  }
}
.content-main .right-side.size-20 {
  min-width: 20%;
  max-width: 20%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-20 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-20 {
    min-width: 16%;
    max-width: 16%;
  }
}
.content-main .right-side.size-25 {
  min-width: 25%;
  max-width: 25%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-25 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-25 {
    min-width: 20%;
    max-width: 20%;
  }
}
.content-main .right-side.size-30 {
  min-width: 30%;
  max-width: 30%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-30 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-30 {
    min-width: 24%;
    max-width: 24%;
  }
}
.content-main .right-side.size-35 {
  min-width: 35%;
  max-width: 35%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-35 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-35 {
    min-width: 28%;
    max-width: 28%;
  }
}
.content-main .right-side.size-40 {
  min-width: 40%;
  max-width: 40%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-40 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-40 {
    min-width: 32%;
    max-width: 32%;
  }
}
.content-main .right-side.size-45 {
  min-width: 45%;
  max-width: 45%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-45 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-45 {
    min-width: 36%;
    max-width: 36%;
  }
}
.content-main .right-side.size-50 {
  min-width: 50%;
  max-width: 50%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-50 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-50 {
    min-width: 40%;
    max-width: 40%;
  }
}
.content-main .right-side.size-55 {
  min-width: 55%;
  max-width: 55%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-55 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-55 {
    min-width: 44%;
    max-width: 44%;
  }
}
.content-main .right-side.size-60 {
  min-width: 60%;
  max-width: 60%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-60 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-60 {
    min-width: 48%;
    max-width: 48%;
  }
}
.content-main .right-side.size-65 {
  min-width: 65%;
  max-width: 65%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-65 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-65 {
    min-width: 52%;
    max-width: 52%;
  }
}
.content-main .right-side.size-70 {
  min-width: 70%;
  max-width: 70%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-70 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-70 {
    min-width: 56%;
    max-width: 56%;
  }
}
.content-main .right-side.size-75 {
  min-width: 75%;
  max-width: 75%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-75 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-75 {
    min-width: 60%;
    max-width: 60%;
  }
}
.content-main .right-side.size-80 {
  min-width: 80%;
  max-width: 80%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-80 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-80 {
    min-width: 64%;
    max-width: 64%;
  }
}
.content-main .right-side.size-85 {
  min-width: 85%;
  max-width: 85%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-85 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-85 {
    min-width: 68%;
    max-width: 68%;
  }
}
.content-main .right-side.size-90 {
  min-width: 90%;
  max-width: 90%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-90 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-90 {
    min-width: 72%;
    max-width: 72%;
  }
}
.content-main .right-side.size-95 {
  min-width: 95%;
  max-width: 95%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-95 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-95 {
    min-width: 76%;
    max-width: 76%;
  }
}
.content-main .right-side.size-100 {
  min-width: 100%;
  max-width: 100%;
}
@media (max-width: 599px) {
  .content-main .right-side.size-100 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.size-100 {
    min-width: 80%;
    max-width: 80%;
  }
}
.content-main .right-side.resize-5 {
  width: 5%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-5 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-5 {
    width: 5;
  }
}
.content-main .right-side.resize-10 {
  width: 10%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-10 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-10 {
    width: 10;
  }
}
.content-main .right-side.resize-15 {
  width: 15%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-15 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-15 {
    width: 15;
  }
}
.content-main .right-side.resize-20 {
  width: 20%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-20 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-20 {
    width: 20;
  }
}
.content-main .right-side.resize-25 {
  width: 25%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-25 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-25 {
    width: 25;
  }
}
.content-main .right-side.resize-30 {
  width: 30%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-30 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-30 {
    width: 30;
  }
}
.content-main .right-side.resize-35 {
  width: 35%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-35 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-35 {
    width: 35;
  }
}
.content-main .right-side.resize-40 {
  width: 40%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-40 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-40 {
    width: 40;
  }
}
.content-main .right-side.resize-45 {
  width: 45%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-45 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-45 {
    width: 45;
  }
}
.content-main .right-side.resize-50 {
  width: 50%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-50 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-50 {
    width: 50;
  }
}
.content-main .right-side.resize-55 {
  width: 55%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-55 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-55 {
    width: 55;
  }
}
.content-main .right-side.resize-60 {
  width: 60%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-60 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-60 {
    width: 60;
  }
}
.content-main .right-side.resize-65 {
  width: 65%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-65 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-65 {
    width: 65;
  }
}
.content-main .right-side.resize-70 {
  width: 70%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-70 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-70 {
    width: 70;
  }
}
.content-main .right-side.resize-75 {
  width: 75%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-75 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-75 {
    width: 75;
  }
}
.content-main .right-side.resize-80 {
  width: 80%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-80 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-80 {
    width: 80;
  }
}
.content-main .right-side.resize-85 {
  width: 85%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-85 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-85 {
    width: 85;
  }
}
.content-main .right-side.resize-90 {
  width: 90%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-90 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-90 {
    width: 90;
  }
}
.content-main .right-side.resize-95 {
  width: 95%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-95 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-95 {
    width: 95;
  }
}
.content-main .right-side.resize-100 {
  width: 100%;
}
@media (max-width: 599px) {
  .content-main .right-side.resize-100 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .content-main .right-side.resize-100 {
    width: 100;
  }
}
.content-main .right-side.tree {
  padding: 0 16px;
}
.content-main .border-top {
  border-top: var(--border-default);
}
.content-main .border-right {
  border-right: var(--border-default);
}
.content-main .border-left {
  border-right: var(--border-default);
}
.content-main .border-default {
  border: var(--border-default);
}
.content-main .html-viewer-main {
  height: auto;
  min-height: auto;
  border: none;
  box-shadow: none;
}
.content-main .not-found {
  border: var(--border-default);
  height: inherit;
  margin-top: 39px;
}

.resizable {
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: var(--border-color-default);
  display: flex;
  height: inherit;
  max-height: inherit;
  overflow: auto;
}
.resizable.resize-5 {
  width: 5%;
}
@media (max-width: 599px) {
  .resizable.resize-5 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-5 {
    width: 5;
  }
}
.resizable.resize-10 {
  width: 10%;
}
@media (max-width: 599px) {
  .resizable.resize-10 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-10 {
    width: 10;
  }
}
.resizable.resize-15 {
  width: 15%;
}
@media (max-width: 599px) {
  .resizable.resize-15 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-15 {
    width: 15;
  }
}
.resizable.resize-20 {
  width: 20%;
}
@media (max-width: 599px) {
  .resizable.resize-20 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-20 {
    width: 20;
  }
}
.resizable.resize-25 {
  width: 25%;
}
@media (max-width: 599px) {
  .resizable.resize-25 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-25 {
    width: 25;
  }
}
.resizable.resize-30 {
  width: 30%;
}
@media (max-width: 599px) {
  .resizable.resize-30 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-30 {
    width: 30;
  }
}
.resizable.resize-35 {
  width: 35%;
}
@media (max-width: 599px) {
  .resizable.resize-35 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-35 {
    width: 35;
  }
}
.resizable.resize-40 {
  width: 40%;
}
@media (max-width: 599px) {
  .resizable.resize-40 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-40 {
    width: 40;
  }
}
.resizable.resize-45 {
  width: 45%;
}
@media (max-width: 599px) {
  .resizable.resize-45 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-45 {
    width: 45;
  }
}
.resizable.resize-50 {
  width: 50%;
}
@media (max-width: 599px) {
  .resizable.resize-50 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-50 {
    width: 50;
  }
}
.resizable.resize-55 {
  width: 55%;
}
@media (max-width: 599px) {
  .resizable.resize-55 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-55 {
    width: 55;
  }
}
.resizable.resize-60 {
  width: 60%;
}
@media (max-width: 599px) {
  .resizable.resize-60 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-60 {
    width: 60;
  }
}
.resizable.resize-65 {
  width: 65%;
}
@media (max-width: 599px) {
  .resizable.resize-65 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-65 {
    width: 65;
  }
}
.resizable.resize-70 {
  width: 70%;
}
@media (max-width: 599px) {
  .resizable.resize-70 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-70 {
    width: 70;
  }
}
.resizable.resize-75 {
  width: 75%;
}
@media (max-width: 599px) {
  .resizable.resize-75 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-75 {
    width: 75;
  }
}
.resizable.resize-80 {
  width: 80%;
}
@media (max-width: 599px) {
  .resizable.resize-80 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-80 {
    width: 80;
  }
}
.resizable.resize-85 {
  width: 85%;
}
@media (max-width: 599px) {
  .resizable.resize-85 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-85 {
    width: 85;
  }
}
.resizable.resize-90 {
  width: 90%;
}
@media (max-width: 599px) {
  .resizable.resize-90 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-90 {
    width: 90;
  }
}
.resizable.resize-95 {
  width: 95%;
}
@media (max-width: 599px) {
  .resizable.resize-95 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-95 {
    width: 95;
  }
}
.resizable.resize-100 {
  width: 100%;
}
@media (max-width: 599px) {
  .resizable.resize-100 {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .resizable.resize-100 {
    width: 100;
  }
}
.resizable:hover, .resizable:active, .resizable:focus-visible {
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: var(--color-gray-400);
}

.title-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  min-height: 40px;
  padding: 0 16px 0 0;
}
.title-bar-info {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.title-bar.head::after {
  content: "";
  border-top: var(--border-default);
  width: 100%;
  height: 0;
  position: absolute;
  top: 40px;
}

.ctbox-edit-name {
  display: block;
  max-width: 50%;
  width: 100%;
}
.ctbox-edit-name.head {
  align-items: center;
  display: flex;
  max-width: 100%;
  gap: 8px;
  padding-right: 8px;
  width: auto;
}

.action-bar {
  background: var(--color-white);
  border-bottom: var(--border-default);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  min-width: calc(100% - 32px);
  min-height: 49px;
  padding: 0 16px;
}
.action-bar.paginator {
  min-height: 40px !important;
  border-bottom: none;
}
.action-bar.title-bar-tabs {
  min-height: 38px !important;
}
.action-bar.head {
  border-bottom: none;
}
.action-bar.sticky {
  background-color: var(--color-white);
  position: sticky !important;
  right: 0;
  left: 0;
  transition: all 500ms ease 0s;
  z-index: 1;
  top: 0;
}
@media (max-width: 599px) {
  .action-bar {
    flex-direction: column;
    padding: 0 8px;
    min-height: auto;
  }
}
.action-info {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: auto;
}
@media (max-width: 599px) {
  .action-info {
    flex-wrap: wrap;
  }
}
.action-title {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
}
.action-title .title-name {
  max-width: 100% !important;
}
.action-title.center {
  justify-content: center;
}
@media (max-width: 599px) {
  .action-title {
    flex-wrap: wrap;
  }
}
.action-title-name {
  color: var(--color-santander-dark);
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.2em;
  margin-left: 16px;
  text-align: center;
}
.action-title-status {
  color: var(--color-santander-dark);
  font-style: italic;
  font-weight: 400;
}
.action-title .notification-to-user {
  justify-content: center;
}
.action-search-cbox {
  display: flex;
}
.action-search-cbox > app-search-box {
  width: 450px;
}

.actions {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  min-height: 40px;
  padding: 4px 24px 4px 24px;
}
.actions-bar {
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.actions-title {
  align-items: center;
  display: flex;
  width: 50%;
}
.actions-buttons {
  display: flex;
  flex: 1;
  gap: 8px;
  justify-content: flex-end;
}
.actions-filters {
  margin-bottom: 24px;
}
.actions .save-container {
  display: flex;
}
.actions .notification-to-user {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.ctbox-search {
  flex: 2;
  padding: 24px;
}
.ctbox-search-bar {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding: 8px 16px;
  border-bottom: var(--border-default);
}
.ctbox-search-box {
  display: flex;
  align-items: center;
}
.ctbox-search-actions {
  display: flex;
  align-items: flex-end;
}
.ctbox-search-actions-search {
  align-items: center;
  border-left: var(--border-default);
  display: flex;
  height: 29px;
  padding-left: 5px;
}
.ctbox-search-logo {
  height: 40px !important;
  width: 40px !important;
}
.ctbox-search-title {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
}
.ctbox-search-form {
  background-color: var(--color-white);
}
.XSmall .ctbox-search-form.big, .Small .ctbox-search-form.big, .Medium .ctbox-search-form.big, .Large .ctbox-search-form.big, .XLarge .ctbox-search-form.big {
  min-width: 150px;
}
.Medium .ctbox-search-form.big, .Large .ctbox-search-form.big, .XLarge .ctbox-search-form.big {
  min-width: 300px;
}
.Large .ctbox-search-form.big, .XLarge .ctbox-search-form.big {
  min-width: 450px;
}
.ctbox-search-result .search-list .mat-list-item:hover,
.ctbox-search-result .search-list .mat-list-option:hover {
  background: none;
}
.ctbox-search-result .search-list .mat-list-item:nth-child(2n) {
  height: 36px;
  background-color: var(--shadow-background);
}
.ctbox-search-result .search-list .mat-list-text {
  flex-direction: row !important;
  align-items: center;
  gap: 8px;
  font-size: 13px;
}

.highlight {
  background-color: var(--color-highlight);
  font-style: normal;
  padding: 4px 0;
}
.highlight-navigated {
  background-color: var(--color-highlight-navigated);
  font-style: normal;
  padding: 4px 0;
}

span:has(> .highlight-navigated) {
  background-color: var(--color-background-highlight);
}

span:has(> .highlight) {
  background-color: var(--color-background-highlight);
}

.show-results {
  display: none;
  align-items: center;
  border-right: var(--border-default);
  height: 29px;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 3px;
  block-size: 29px;
  writing-mode: horizontal-tb;
}

.tabs-bar {
  background: var(--color-white);
  border-bottom: var(--border-default);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  min-height: 49px;
  padding-right: 24px;
}
.tabs-bar.sticky {
  background-color: var(--color-white);
  position: sticky !important;
  right: 0;
  left: 0;
  transition: all 500ms ease 0s;
  z-index: 1;
  top: 0;
}
.tabs-action {
  display: flex;
}
.tabs.width-title {
  align-items: initial;
}
.tabs-list {
  border-bottom: none;
}
.tabs-info {
  align-items: center;
  display: flex;
  gap: 16px;
  margin: 0 16px;
}
@media (max-width: 959px) {
  .tabs-info {
    flex-wrap: wrap;
  }
}
.tabs-info-alert {
  border-right: var(--border-default);
  padding-right: 16px;
}
.tabs-info-help {
  border-left: var(--border-default);
  padding-left: 8px;
}
@media (max-width: 599px) {
  .tabs-info-help, .tabs-info-alert {
    border: none;
    justify-content: flex-end;
    margin: 0;
    padding: 8px 0;
  }
}
.tabs-info .notification {
  white-space: nowrap;
}
.tabs .buttonbar {
  padding-right: 16px;
}

.form-group {
  align-content: center;
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 0;
}
.form-group.size-5 {
  min-width: 5%;
  max-width: 5%;
}
@media (max-width: 599px) {
  .form-group.size-5 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-5 {
    min-width: 4%;
    max-width: 4%;
  }
}
.form-group.size-10 {
  min-width: 10%;
  max-width: 10%;
}
@media (max-width: 599px) {
  .form-group.size-10 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-10 {
    min-width: 8%;
    max-width: 8%;
  }
}
.form-group.size-15 {
  min-width: 15%;
  max-width: 15%;
}
@media (max-width: 599px) {
  .form-group.size-15 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-15 {
    min-width: 12%;
    max-width: 12%;
  }
}
.form-group.size-20 {
  min-width: 20%;
  max-width: 20%;
}
@media (max-width: 599px) {
  .form-group.size-20 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-20 {
    min-width: 16%;
    max-width: 16%;
  }
}
.form-group.size-25 {
  min-width: 25%;
  max-width: 25%;
}
@media (max-width: 599px) {
  .form-group.size-25 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-25 {
    min-width: 20%;
    max-width: 20%;
  }
}
.form-group.size-30 {
  min-width: 30%;
  max-width: 30%;
}
@media (max-width: 599px) {
  .form-group.size-30 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-30 {
    min-width: 24%;
    max-width: 24%;
  }
}
.form-group.size-35 {
  min-width: 35%;
  max-width: 35%;
}
@media (max-width: 599px) {
  .form-group.size-35 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-35 {
    min-width: 28%;
    max-width: 28%;
  }
}
.form-group.size-40 {
  min-width: 40%;
  max-width: 40%;
}
@media (max-width: 599px) {
  .form-group.size-40 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-40 {
    min-width: 32%;
    max-width: 32%;
  }
}
.form-group.size-45 {
  min-width: 45%;
  max-width: 45%;
}
@media (max-width: 599px) {
  .form-group.size-45 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-45 {
    min-width: 36%;
    max-width: 36%;
  }
}
.form-group.size-50 {
  min-width: 50%;
  max-width: 50%;
}
@media (max-width: 599px) {
  .form-group.size-50 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-50 {
    min-width: 40%;
    max-width: 40%;
  }
}
.form-group.size-55 {
  min-width: 55%;
  max-width: 55%;
}
@media (max-width: 599px) {
  .form-group.size-55 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-55 {
    min-width: 44%;
    max-width: 44%;
  }
}
.form-group.size-60 {
  min-width: 60%;
  max-width: 60%;
}
@media (max-width: 599px) {
  .form-group.size-60 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-60 {
    min-width: 48%;
    max-width: 48%;
  }
}
.form-group.size-65 {
  min-width: 65%;
  max-width: 65%;
}
@media (max-width: 599px) {
  .form-group.size-65 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-65 {
    min-width: 52%;
    max-width: 52%;
  }
}
.form-group.size-70 {
  min-width: 70%;
  max-width: 70%;
}
@media (max-width: 599px) {
  .form-group.size-70 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-70 {
    min-width: 56%;
    max-width: 56%;
  }
}
.form-group.size-75 {
  min-width: 75%;
  max-width: 75%;
}
@media (max-width: 599px) {
  .form-group.size-75 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-75 {
    min-width: 60%;
    max-width: 60%;
  }
}
.form-group.size-80 {
  min-width: 80%;
  max-width: 80%;
}
@media (max-width: 599px) {
  .form-group.size-80 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-80 {
    min-width: 64%;
    max-width: 64%;
  }
}
.form-group.size-85 {
  min-width: 85%;
  max-width: 85%;
}
@media (max-width: 599px) {
  .form-group.size-85 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-85 {
    min-width: 68%;
    max-width: 68%;
  }
}
.form-group.size-90 {
  min-width: 90%;
  max-width: 90%;
}
@media (max-width: 599px) {
  .form-group.size-90 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-90 {
    min-width: 72%;
    max-width: 72%;
  }
}
.form-group.size-95 {
  min-width: 95%;
  max-width: 95%;
}
@media (max-width: 599px) {
  .form-group.size-95 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-95 {
    min-width: 76%;
    max-width: 76%;
  }
}
.form-group.size-100 {
  min-width: 100%;
  max-width: 100%;
}
@media (max-width: 599px) {
  .form-group.size-100 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group.size-100 {
    min-width: 80%;
    max-width: 80%;
  }
}
.form-group-block {
  display: block;
  margin: 16px 0;
  position: relative;
}
.form-group-block.mb32 {
  margin-bottom: 32px !important;
}
.form-group-grid {
  min-width: 100%;
  gap: 16px;
  display: grid;
}
.form-group-grid.expirations {
  grid-template-columns: 20% calc(80% - 16px);
}
.form-group-grid.sheet {
  display: grid;
  gap: 16px;
  grid-template-columns: auto 180px;
  width: 100%;
}
@media (max-width: 1279px) {
  .form-group-grid.sheet {
    display: flex;
    flex-wrap: wrap;
  }
}
.form-group-title {
  align-self: center;
  font-weight: 500;
}
.form-group-title.end {
  text-align: right;
}
.form-group-title.top {
  align-self: flex-start;
  margin-top: 6px;
}
.form-group-title.middle-border {
  align-self: flex-start;
  justify-content: space-between;
  min-width: 100%;
  position: relative;
  line-height: 32px;
}
.form-group-title.middle-border::before {
  content: "";
  position: absolute;
  bottom: 50%;
  border-bottom: var(--border-default);
  width: 100%;
  z-index: -1;
}
.form-group-label {
  background-color: var(--color-white);
  font-weight: 500;
  margin-right: 8px;
}
.form-group-flex {
  flex-wrap: wrap;
  display: flex;
  align-content: flex-start;
  gap: 16px;
}
.form-group-flex.size-5 {
  min-width: calc(5% - 16px);
  max-width: calc(5% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-5 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-5 {
    min-width: calc(4% - 16px);
    max-width: calc(4% - 16px);
  }
}
.form-group-flex.size-10 {
  min-width: calc(10% - 16px);
  max-width: calc(10% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-10 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-10 {
    min-width: calc(8% - 16px);
    max-width: calc(8% - 16px);
  }
}
.form-group-flex.size-15 {
  min-width: calc(15% - 16px);
  max-width: calc(15% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-15 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-15 {
    min-width: calc(12% - 16px);
    max-width: calc(12% - 16px);
  }
}
.form-group-flex.size-20 {
  min-width: calc(20% - 16px);
  max-width: calc(20% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-20 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-20 {
    min-width: calc(16% - 16px);
    max-width: calc(16% - 16px);
  }
}
.form-group-flex.size-25 {
  min-width: calc(25% - 16px);
  max-width: calc(25% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-25 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-25 {
    min-width: calc(20% - 16px);
    max-width: calc(20% - 16px);
  }
}
.form-group-flex.size-30 {
  min-width: calc(30% - 16px);
  max-width: calc(30% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-30 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-30 {
    min-width: calc(24% - 16px);
    max-width: calc(24% - 16px);
  }
}
.form-group-flex.size-35 {
  min-width: calc(35% - 16px);
  max-width: calc(35% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-35 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-35 {
    min-width: calc(28% - 16px);
    max-width: calc(28% - 16px);
  }
}
.form-group-flex.size-40 {
  min-width: calc(40% - 16px);
  max-width: calc(40% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-40 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-40 {
    min-width: calc(32% - 16px);
    max-width: calc(32% - 16px);
  }
}
.form-group-flex.size-45 {
  min-width: calc(45% - 16px);
  max-width: calc(45% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-45 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-45 {
    min-width: calc(36% - 16px);
    max-width: calc(36% - 16px);
  }
}
.form-group-flex.size-50 {
  min-width: calc(50% - 16px);
  max-width: calc(50% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-50 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-50 {
    min-width: calc(40% - 16px);
    max-width: calc(40% - 16px);
  }
}
.form-group-flex.size-55 {
  min-width: calc(55% - 16px);
  max-width: calc(55% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-55 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-55 {
    min-width: calc(44% - 16px);
    max-width: calc(44% - 16px);
  }
}
.form-group-flex.size-60 {
  min-width: calc(60% - 16px);
  max-width: calc(60% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-60 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-60 {
    min-width: calc(48% - 16px);
    max-width: calc(48% - 16px);
  }
}
.form-group-flex.size-65 {
  min-width: calc(65% - 16px);
  max-width: calc(65% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-65 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-65 {
    min-width: calc(52% - 16px);
    max-width: calc(52% - 16px);
  }
}
.form-group-flex.size-70 {
  min-width: calc(70% - 16px);
  max-width: calc(70% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-70 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-70 {
    min-width: calc(56% - 16px);
    max-width: calc(56% - 16px);
  }
}
.form-group-flex.size-75 {
  min-width: calc(75% - 16px);
  max-width: calc(75% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-75 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-75 {
    min-width: calc(60% - 16px);
    max-width: calc(60% - 16px);
  }
}
.form-group-flex.size-80 {
  min-width: calc(80% - 16px);
  max-width: calc(80% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-80 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-80 {
    min-width: calc(64% - 16px);
    max-width: calc(64% - 16px);
  }
}
.form-group-flex.size-85 {
  min-width: calc(85% - 16px);
  max-width: calc(85% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-85 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-85 {
    min-width: calc(68% - 16px);
    max-width: calc(68% - 16px);
  }
}
.form-group-flex.size-90 {
  min-width: calc(90% - 16px);
  max-width: calc(90% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-90 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-90 {
    min-width: calc(72% - 16px);
    max-width: calc(72% - 16px);
  }
}
.form-group-flex.size-95 {
  min-width: calc(95% - 16px);
  max-width: calc(95% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-95 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-95 {
    min-width: calc(76% - 16px);
    max-width: calc(76% - 16px);
  }
}
.form-group-flex.size-100 {
  min-width: calc(100% - 16px);
  max-width: calc(100% - 16px);
}
@media (max-width: 599px) {
  .form-group-flex.size-100 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .form-group-flex.size-100 {
    min-width: calc(80% - 16px);
    max-width: calc(80% - 16px);
  }
}
.form-group-flex.gap0 {
  gap: 0 !important;
}
.form-group-flex.gap8 {
  gap: 8px !important;
}
.form-group-flex.min100 {
  min-width: 100%;
}
.form-group-flex.center {
  align-items: center;
  align-content: center;
}
.form-group-flex.inline {
  align-items: stretch;
  display: inline-flex;
  flex-direction: row;
  padding-right: 16px;
}
.form-group-flex.frow {
  flex-direction: row;
}
.form-group-flex.fcol {
  align-items: flex-start;
  flex-direction: column;
}
.form-group-flex.end {
  flex: 1;
  justify-content: flex-end;
}
.form-group-flex.button {
  min-width: 180px;
}
.form-group-flex.border-group {
  border-left: var(--border-default);
  padding: 8px 0 8px 8px;
}
.form-group-flex.border-mail {
  border-left: var(--border-default);
  padding: 0 0 0 16px;
  gap: 16px;
}
.form-group-flex.section-check {
  padding: 0 32px;
  margin: 8px 0;
}
.form-group-flex.coment {
  max-width: 100%;
  gap: 16px 32px;
  padding: 1em 0;
  width: 100%;
}
.form-group-flex.notification {
  padding: 16px 0;
}
.form-group-error {
  font-size: 75%;
  bottom: -24px;
  position: absolute;
}
.form-group .user {
  font-size: 1em;
}
.form-group .user-email {
  margin: 0 8px 0 0;
}
.form-group .user-type {
  color: var(--color-text-secondary);
}
.form-group-checkbox {
  margin: 0 0 16px 0;
}
.form-group.expirations {
  padding: 2em;
  max-width: 80%;
  margin: 0 auto;
  gap: 16px 32px;
}
.form-group .mat-label {
  padding-right: 8px;
}
.form-group .pt-8 {
  padding-top: 8px;
}
.form-group.form-container {
  padding: 16px 0;
  gap: 40px;
}

.validate-name {
  display: block;
  width: 100%;
  min-height: 40px;
}

.validator-text {
  display: none;
  line-height: 1.5em;
}
.validator-text--error {
  color: var(--color-error);
  margin: 0 6px;
  display: inline-block !important;
}
.validator-text--error--mandatory-option {
  color: var(--color-error);
  font-size: 1.6em;
  font-weight: 500;
  margin: 5px;
  float: left;
  clear: both;
  margin-top: -28px;
  margin-left: 192px;
}
.validator-text--error--mandatory-inline {
  color: var(--color-error);
  font-size: 1.2em;
  font-weight: 500;
}
.validator-text--error span {
  color: var(--color-text);
}
.validator-text.dni {
  max-width: 200px;
}
.validator-text .ui-widget-overlay {
  z-index: 49;
  background: black;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}
.validator-text select + span {
  padding: 8px 12px 8px 8px;
  font-size: 1.3em;
}
.validator-text div[aria-describedby=popup_container_alert] {
  background-color: white;
  box-shadow: 0 0 7px 0 rgb(153, 153, 153);
  width: 498px;
  height: 172px;
  border-radius: 6px;
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 50;
}
.validator-text div[aria-describedby=popup_container_alert] .ui-dialog-titlebar {
  padding: 14px 14px;
  border-bottom: var(--border-default);
}
.validator-text div[aria-describedby=popup_container_alert] .ui-dialog-titlebar button {
  display: none;
}
.validator-text div[aria-describedby=popup_container_alert] .ui-dialog-buttonpane button {
  background: var(--color-blue-300);
  color: var(--color-white);
  float: right;
  border: none;
  padding: 8px;
  width: 74px;
  margin: 6px 20px;
  outline: none;
}
.validator-text div[aria-describedby=popup_container_alert] .popupContainerClass {
  margin: 5px 0 10px 0;
  padding: 16px;
  border-bottom: var(--border-default);
}

.rSelect {
  position: relative;
}
.rSelect .validator-text--error--mandatory-option {
  position: absolute;
  left: 5px;
  top: 0;
  margin-left: 0;
  margin-top: 0;
}

.paginator .mat-paginator-container {
  min-height: 51px;
  padding: 0 6px;
}
@media (max-width: 599px) {
  .paginator .mat-paginator-container {
    padding: 0;
  }
}
.paginator .mat-paginator-container .mat-paginator-range-label:not(:empty) {
  margin: 4px 24px 0 12px;
  font-size: 14px;
  text-align: right;
}
@media (max-width: 599px) {
  .paginator .mat-paginator-container .mat-paginator-range-label:not(:empty) {
    margin: 4px 4px 0 4px;
    font-size: 12px;
  }
}
.paginator .mat-paginator-container .mat-paginator-range-label:empty {
  display: none;
}
.paginator .mat-paginator-container .mat-paginator-icon {
  width: 24px;
}
.paginator .mat-paginator-container .mat-form-field-infix {
  width: 64px;
}

.main {
  padding: 16px;
}
.main.aditional-document {
  padding: 39px 0 100px 0;
}
.main-close {
  position: absolute;
  right: 29px;
  top: 25px;
}
@media (max-width: 599px) {
  .main-close {
    right: 12px;
    top: 8px;
  }
}
.main-title {
  text-align: center;
  font-size: 22px;
}
@media (max-width: 599px) {
  .main-title {
    margin: 0 16px;
    line-height: 27px;
  }
}
.main-subtitle {
  text-align: center;
  font-size: 19px;
  margin: 13px 0 48px 0;
  color: var(--color-text-secondary);
}
@media (max-width: 599px) {
  .main-subtitle {
    margin: 13px 16px 48px 16px;
    line-height: 25px;
  }
}

.ctbox-cards-group {
  align-items: flex-start;
  display: flex;
  gap: 32px;
  justify-content: center;
}
.ctbox-cards-group .ctbox-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 260px;
}
.ctbox-cards-group .ctbox-card-link {
  align-content: center;
  align-items: center;
  background-color: var(--color-white);
  background-position: center 32px;
  background-repeat: no-repeat;
  border: var(--border-default);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 240px;
  width: 260px;
  padding: 32px;
}
.ctbox-cards-group .ctbox-card-link.cmc-bp, .ctbox-cards-group .ctbox-card-link.cmc-bc, .ctbox-cards-group .ctbox-card-link.cmc-aj {
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 4px solid var(--color-santander);
}
.ctbox-cards-group .ctbox-card-link-title {
  color: var(--color-text);
  font-size: 19px;
  font-weight: 500;
  outline: none;
  text-decoration: none;
}
.ctbox-cards-group .ctbox-card-link-text {
  color: var(--color-text-secondary);
  font-size: 12px;
  min-height: 85%;
  outline: none;
  padding: 24px 0 0 0;
  text-decoration: none;
}
.ctbox-cards-group .ctbox-card-link-button {
  align-self: flex-end;
}
.ctbox-cards-group .ctbox-card-link.from-tdc.documents {
  background-image: url("../../assets/images/contracts-from-template.svg");
}
.ctbox-cards-group .ctbox-card-link.from-tdc.templates {
  background-image: url("../../assets/images/templates-lld-model.svg");
}
.ctbox-cards-group .ctbox-card-link.from-tdc.clauses {
  background-image: url("../../assets/images/clauses-library-wk-model.svg");
}
.ctbox-cards-group .ctbox-card-link.from-file.documents {
  background-image: url("../../assets/images/contracts-upload-document.svg");
}
.ctbox-cards-group .ctbox-card-link.from-file.templates {
  background-image: url("../../assets/images/templates-upload-document.svg");
}
.ctbox-cards-group .ctbox-card-link.from-file.clauses {
  background-image: url("../../assets/images/clauses-upload-document.svg");
}
.ctbox-cards-group .ctbox-card-link.from-file.cmc {
  background-image: url("../../assets/images/selection-upload-document.svg");
}
.ctbox-cards-group .ctbox-card-link.from-new.documents {
  background-image: url("../../assets/images/contracts-create-document.svg");
}
.ctbox-cards-group .ctbox-card-link.from-new.templates {
  background-image: url("../../assets/images/templates-create-document.svg");
}
.ctbox-cards-group .ctbox-card-link.from-new.clauses {
  background-image: url("../../assets/images/clauses-library-create-document.svg");
}
.ctbox-cards-group .ctbox-card-link.from-new.cmc {
  background-image: url("../../assets/images/selection-create-document.svg");
}
.ctbox-cards-group .ctbox-card-link:focus, .ctbox-cards-group .ctbox-card-link:hover {
  outline: none;
  text-decoration: none;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
}
.ctbox-cards-group .ctbox-card-link:active {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.ctbox-cards-group .ctbox-card-link.help {
  height: 130px;
  width: 120px;
  background-size: 80px;
}
.ctbox-cards-group .ctbox-card-text {
  color: var(--color-text-secondary);
  font-size: 15px;
  line-height: 20px;
  text-align: center;
}

.ctbox-border {
  box-shadow: var(--box-shadow);
  padding: 16px;
  border: var(--border-default);
  display: block;
}

.ctbox-elevation {
  box-shadow: var(--box-shadow);
  margin: 16px;
  border: var(--border-default);
  position: relative;
}

.ctbox-block {
  display: block;
}

.html-viewer {
  display: block;
  padding: 16px;
  height: inherit;
  min-height: inherit;
}
.html-viewer-main {
  border: var(--border-default);
  box-shadow: var(--box-shadow);
  display: block;
  min-height: inherit;
  height: inherit;
}
.html-viewer-main.read-only, .html-viewer-main.comment-text {
  border: none;
  box-shadow: none;
  padding: 0;
}
.html-viewer.read-only {
  pointer-events: none;
  opacity: 0.8;
  box-shadow: none;
  border: none;
}
.html-viewer.regulations, .html-viewer.smart-forms {
  border: none;
  box-shadow: none;
  padding: 0;
  max-height: inherit;
  width: 100%;
  height: inherit;
}
.html-viewer.background {
  background-color: var(--shadow-background) !important;
}
.html-viewer .read-only-clause {
  background-color: transparent !important;
  display: block;
  margin: 0 !important;
  padding: 0 !important;
}
.html-viewer .read-only-clause .read-only-clause-buttons,
.html-viewer .read-only-clause .read-only-clause-icon, .html-viewer .read-only-clause::before {
  display: none;
}

.wysiwyg {
  align-items: flex-start;
  align-content: flex-start;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  max-height: inherit;
  min-height: inherit;
  height: inherit;
  width: 100%;
}
.wysiwyg-hidden {
  display: none !important;
}
.wysiwyg-main {
  flex: 1;
  width: 100%;
  height: inherit;
}
.wysiwyg-ckeditor {
  display: block;
  min-height: inherit;
  height: inherit;
}
.wysiwyg-editor {
  width: 100%;
  flex: 1;
  min-height: inherit;
}
.wysiwyg-editor.width-comments {
  width: calc(75% - 8px);
}
.wysiwyg-comments {
  width: calc(25% - 8px);
}
.wysiwyg-comments-hidden {
  display: none;
}
.wysiwyg-comments .comments {
  border: var(--border-default);
}
.wysiwyg-comments .comments-title {
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
  background-color: var(--shadow-background);
}
.wysiwyg-comments .comments-button {
  background-color: var(--shadow-background);
  border-bottom: var(--border-default);
  padding: 0 16px 16px 16px;
}
.wysiwyg-comments .comments-text {
  background: var(--color-white);
  border-top: var(--border-default);
  color: var(--color-text-secondary);
  font-size: 16px;
  line-height: 24px;
  padding: 24px 40px;
  text-align: center;
}
.wysiwyg-comments .comments-text .cke_button_icon {
  width: 24px;
  height: 24px;
  float: none;
}
.wysiwyg-comments .comments-text .cke_button__annotate_icon {
  background-image: url("../../plugins/lance-tmce/lance/icons/add-comment_cke.png");
}
.wysiwyg-comments .comments-container {
  background-color: var(--shadow-background);
}
.wysiwyg-comments .comments-top {
  margin: 0;
}
.wysiwyg-comments .comments .lance-annotations-container {
  border: none !important;
}
.wysiwyg-container {
  margin: 0;
  min-height: inherit;
  height: inherit;
  position: relative;
  width: 100%;
}
.wysiwyg-container-form {
  margin: 0;
  min-height: inherit;
  height: inherit;
  position: relative;
  width: 100%;
}
.wysiwyg-container-error {
  background-color: var(--shadow-background);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.wysiwyg .cke_dialog_contents input[type=radio],
.wysiwyg .cke_dialog_contents input[type=checkbox] {
  border: var(--border-default) !important;
  -webkit-transition: border 0.1s ease-out;
  transition: border 0.1s ease-out;
  background-color: var(--color-white);
  margin: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.wysiwyg .cke_dialog_contents input[type=checkbox]:hover,
.wysiwyg .cke_dialog_contents input[type=radio]:hover,
.wysiwyg .cke_dialog_contents input[type=checkbox]:focus,
.wysiwyg .cke_dialog_contents input[type=radio]:focus {
  border: 1px solid var(--color-black) !important;
}
.wysiwyg .cke_dialog_contents input[type=radio][disabled=disabled] + *,
.wysiwyg .cke_dialog_contents input[type=checkbox][disabled=disabled] + * {
  background-color: var(--color-gray-200);
  color: var(--color-gray-500);
  cursor: not-allowed;
}
.wysiwyg .cke_dialog_contents input[type=radio]:checked,
.wysiwyg .cke_dialog_contents input[type=checkbox]:checked {
  background-position: center;
  background-repeat: no-repeat;
}
.wysiwyg .cke_dialog_contents input[type=radio] {
  border-radius: 8px;
}
.wysiwyg .cke_button_icon {
  background-size: 16px !important;
}
.wysiwyg .cke_button_on {
  background: var(--color-disabled) !important;
}
.wysiwyg .cke_button__clausefromlibrary_icon {
  background-image: url("../../assets/icons/embedsemantic.png") !important;
}
.wysiwyg .cke_button__insertclauseandmodal_icon {
  background-image: url("../../assets/icons/form.png") !important;
}
.wysiwyg .cke_button__clausefromtree_icon {
  width: 14px;
  background-color: var(--color-info) !important;
  mask-image: url("../../assets/icons/square-list.svg") !important;
}
.wysiwyg .cke_button__helpnotes_icon {
  background-color: var(--color-info) !important;
  mask-image: url("../../assets/icons/helpnotes.svg") !important;
}
.wysiwyg .cke_button__insertsignatureandmodal_icon {
  width: 18px;
  height: 16px;
  background-color: var(--color-info) !important;
  mask-image: url("../../assets/icons/signature.svg") !important;
}
.wysiwyg .cke_button__maximize_icon {
  background-image: none !important;
  background-color: var(--color-info) !important;
  mask-image: url("../../assets/icons/ico-maximize.svg") !important;
}
.wysiwyg .cke_button__flite-acceptall_icon {
  background-image: url("../../plugins/flite-cke/flite/icons/flite-acceptall.png");
}
.wysiwyg .cke_button__flite-acceptone_icon {
  background-image: url("../../plugins/flite-cke/flite/icons/flite-acceptone.png");
}
.wysiwyg .cke_button__flite-rejectall_icon {
  background-image: url("../../plugins/flite-cke/flite/icons/flite-rejectall.png");
}
.wysiwyg .cke_button__flite-rejectone_icon {
  background-image: url("../../plugins/flite-cke/flite/icons/flite-rejectone.png");
}
.wysiwyg .cke_button__flite-toggleshow_icon {
  background-image: url("../../plugins/flite-cke/flite/icons/flite-toggleshow.png");
}
.wysiwyg .cke_button__nextchangeflitebutton_icon {
  background-image: url("../../plugins/flite-cke/flite/icons/flite-nextchange.png");
}
.wysiwyg .cke_button__previouschangeflitebutton_icon {
  background-image: url("../../plugins/flite-cke/flite/icons/flite-prevchange.png");
}
.wysiwyg .cke_button__annotate_icon {
  background-image: url("../../plugins/lance-tmce/lance/icons/add-comment_cke.png");
}
.wysiwyg .cke_toolbar_start {
  display: none;
}
.wysiwyg .cke_top {
  display: flex;
  flex-wrap: wrap;
  border-top: var(--border-default);
  border-bottom: var(--border-default);
}
.wysiwyg .cke_toolbox {
  display: flex;
  flex-wrap: wrap;
}
.wysiwyg .version-editor-without-track-changes .cke_top,
.wysiwyg .version-editor-without-track-changes .cke_toolbox {
  display: none;
}
.wysiwyg .cke_toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 5px 0 5px;
}
.wysiwyg .cke_toolbar .cke_toolgroup {
  display: flex;
  align-items: center;
  margin: 0;
  min-height: 30px;
}
.wysiwyg .cke_toolbar .cke_toolgroup::after {
  border-right: 2px solid var(--border-color-default);
  content: "";
  display: block;
  margin: 0;
  min-height: 20px;
}
.wysiwyg .cke_toolbar .cke_toolbar_separator {
  border-right: 2px solid var(--border-color-default);
  display: block;
  margin: 0;
  min-height: 20px;
  width: 0;
}
.wysiwyg .cke_toolbar .cke_voice_label[data-voice_label] ~ .cke_toolgroup::before {
  display: block !important;
}
.wysiwyg .cke_toolbar .cke_voice_label[data-voice_label=clauses] ~ .cke_toolgroup::before {
  content: "Cláusulas";
}
.wysiwyg .cke_toolbar .cke_voice_label[data-voice_label=Formularios] ~ .cke_toolgroup::before {
  content: "Opciones";
}
.wysiwyg .cke_toolbar .cke_voice_label[data-voice_label=signature] ~ .cke_toolgroup::before {
  content: "Firma";
}
.wysiwyg .cke_toolbar .cke_voice_label[data-voice_label=Herramientas] ~ .cke_toolgroup::before {
  content: "Maximizar";
}
.wysiwyg .cke_toolbar .cke_voice_label[data-voice_label=flite] ~ .cke_toolgroup::before {
  content: "Control de cambios";
}
.wysiwyg .cke_inner {
  border: var(--border-default);
  border-top: 0;
  height: inherit;
}
.wysiwyg a.cke_button_off:hover,
.wysiwyg a.cke_button_off:focus,
.wysiwyg a.cke_button_off:active,
.wysiwyg a.cke_button_disabled:hover,
.wysiwyg a.cke_button_disabled:focus,
.wysiwyg a.cke_button_disabled:active {
  background: var(--color-disabled) !important;
}
.wysiwyg .cke_maximized .cke_top {
  background: var(--shadow-background);
  min-height: 68px;
}
.wysiwyg .text-clause p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wysiwyg .text-clause {
  max-width: 640px;
  max-height: 0;
}
.wysiwyg .cke_button__creatediv {
  display: none !important;
}
.wysiwyg .mark-clause {
  margin: 5px;
  padding: 5px;
  background: var(--color-blue-A200);
  border: none;
}

.ccn em.aj {
  display: none;
}

.input-plugin-validate:invalid {
  outline: red solid 2px;
}

.signature {
  display: flex;
  width: 100%;
  gap: 16px;
}
.signature-sign-rol {
  display: none;
}
.signature-sign-placement {
  height: 100px;
  vertical-align: bottom;
}
.signature-in-editor {
  margin: 5px;
  padding: 5px;
  border: none;
  background: var(--shadow-background);
  width: 300px;
  height: 140px;
  display: inline-block;
}
.signature-container {
  flex: 1;
}
.signature-title {
  display: flex;
  width: 100%;
}
.signature-title-label {
  font-size: 16px;
  line-height: 32px;
  display: flex;
  gap: 8px;
}
.signature-text {
  padding-left: 40px;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.signature-message {
  display: flex;
  align-items: center;
}
.signature-warning {
  color: var(--color-error);
}
.signature-signer {
  margin: 24px 0;
}
.signature-type {
  margin: 0 0 16px 3px;
}
.signature-type-number {
  border: var(--border-default);
  border-radius: 20px;
  display: inline-block;
  font-size: 13px;
  margin: 0 8px 0 0;
  text-align: center;
  height: 20px;
  line-height: 20px;
  width: 20px;
}
.signature-type-name {
  font-size: 15px;
  font-weight: 500;
}
.signature-data {
  overflow: hidden;
}
.signature-data-left {
  width: 50%;
  float: left;
}
.signature-data-right {
  width: 50%;
  float: right;
}
.signature-data-user {
  margin: 0 0 12px 30px;
}
.signature-data-user .title {
  font-weight: 500;
  margin: 0 0 2px 0;
  font-size: 14px;
}
.signature-data-user .subtitle {
  color: var(--color-text-secondary);
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 16px;
}
.signature-data-user .pending {
  color: var(--color-state-signing);
  font-weight: 400;
}
.signature-info {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  gap: 16px;
  min-width: 25vw;
}
.signature-info-block label {
  margin: 0 16px 0 8px;
  color: var(--color-gray-700);
  vertical-align: 3px;
}
.signature-info-block input {
  outline: none;
}
.signature-info-block .title {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  gap: 8px;
  align-items: center;
}
.signature-info-expiration input[type=text] {
  width: 30px;
  height: 20px;
  vertical-align: middle;
  border: var(--border-default);
}
.signature-list {
  min-height: 60px;
  overflow: hidden;
  display: block;
}
.signature-list-item {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  cursor: move;
}
.signature-inline {
  margin-left: 32px;
}

.cke_widget_wrapper_signature-in-editor {
  display: inline-block;
}

.group-change-sel del.diffmod,
.group-change-sel del.diffdel {
  margin: 1px;
}
.group-change-sel del.diffmod::before {
  content: "";
  top: 8px;
}
.group-change-sel img.diffdel {
  border-left: solid var(--color-text-secondary);
  box-sizing: border-box;
  background-color: transparent !important;
}
.group-change-sel img.diffins {
  border-left: solid var(--color-text-secondary);
  background-color: transparent !important;
  box-sizing: border-box;
}
.group-change-sel .diffins,
.group-change-sel .diffdel,
.group-change-sel .diffmod,
.group-change-sel .mod {
  outline: 3px dotted var(--color-blue-600);
  margin: 0 4px;
}
.group-change-sel del {
  text-decoration: line-through;
  background-color: var(--background-color-deleted);
  color: var(--color-delete);
  padding: 2px;
}
.group-change-sel del select {
  background-color: var(--background-color-deleted) !important;
  padding: 2px;
}
.group-change-sel del input {
  background-color: var(--background-color-deleted) !important;
  padding: 2px;
}
.group-change-sel ins {
  text-decoration: none;
  background-color: var(--background-color-insert) !important;
  color: var(--color-success);
  padding: 2px;
}
.group-change-sel ins select {
  background-color: var(--background-color-insert) !important;
  color: var(--color-success);
  padding: 2px;
}
.group-change-sel ins input {
  background-color: var(--background-color-insert) !important;
  color: var(--color-success);
  padding: 2px;
}

.not-found {
  color: var(--color-info);
  font-size: 16px;
  line-height: 1.7em;
  padding: 32px;
  text-align: center;
}
.not-found.cmc {
  padding: 64px 32px;
}

.contextual-help {
  display: block;
}
.contextual-help-separator {
  border-left: var(--border-default);
  display: block;
}

.versions-sidenav.mat-drawer {
  display: flex !important;
  width: 25%;
}
.versions-sidenav.mat-drawer.mat-drawer-side {
  border-right: 1px solid transparent;
}
.versions-sidenav.mat-drawer .mat-drawer-inner-container {
  display: flex !important;
}
.versions-sidenav.mat-drawer[style*="visibility: hidden"], .versions-sidenav:not(.mat-drawer-opened) {
  display: flex !important;
  transform: translate3d(0, 0, 0) !important;
  visibility: visible !important;
  width: 166px !important;
  overflow: hidden;
}
.versions-sidenav-button {
  margin: 12px 0;
}
.versions-header {
  display: flex;
  justify-content: space-between;
  padding: 12px 8px;
  width: 100%;
}
.versions-header-buttons {
  display: flex;
  gap: 16px;
}
.versions-editor {
  background-color: var(--color-white);
  padding-right: 24px;
}
.versions-editor.folded {
  max-width: calc(100% - 166px) !important;
  margin: 0 0 0 166px !important;
}
.versions-list {
  padding: 0 0 0 24px;
  width: 100%;
}

.spinner {
  transform: scale(2, 4);
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-content: center;
}
.spinner .preload-icon {
  color: #333;
  display: block;
  font-size: 0;
}
.spinner .preload-icon > div {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: currentColor;
  border: 0 solid currentColor;
  display: inline-block;
  width: 4px;
  height: 12px;
  margin: 1px;
  position: relative;
  -webkit-animation: kf-bouncedelay 1.4s infinite ease-in-out both;
  animation: kf-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .preload-icon .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .preload-icon .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.content-message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 64px auto;
}
.content-message .message {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--color-white);
  max-width: 500px;
  min-width: 30vw;
  padding: 32px;
}
@media (max-width: 992px) {
  .content-message .message {
    margin: 0 32px;
    max-width: 100vw;
  }
}
.content-message .message-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.content-message .message .text {
  font-size: 1.1em;
  line-height: 1.5em;
  margin: 16px auto 0 auto;
}
.content-message .message .data {
  margin: 16px 0;
}
.content-message .message .button {
  margin-top: 32px;
}

.qc-cmp2-persistent-link {
  display: none;
}

.cdk-drop-list .cdk-drag {
  position: relative;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.cdk-drop-list .cdk-drag:not(.cdk-drag-disabled):active {
  cursor: move;
}
.cdk-drop-list .cdk-drag-preview {
  background-color: var(--shadow-background);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cdk-drop-list .cdk-drag-preview.signature-list-item {
  max-width: 400px !important;
  min-height: 200px !important;
  padding: 32px;
  background-color: rgba(255, 255, 255, 0.5);
}
.cdk-drop-list .cdk-drag-preview.cdk-tree-node {
  max-width: auto !important;
  min-height: auto !important;
  padding: 0;
}
.cdk-drop-list .cdk-drag-animating,
.cdk-drop-list .cdk-drop-list-dragging .mat-tree-node:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.cdk-drop-list .cdk-drag-animating:hover,
.cdk-drop-list .cdk-drop-list-dragging .mat-tree-node:not(.cdk-drag-placeholder):hover {
  background-color: var(--color-blue-100);
}
.cdk-drop-list .cdk-drag-placeholder {
  opacity: 1;
}
.cdk-drop-list .cdk-drag-disabled {
  cursor: default;
}
.cdk-drop-list .over-drop-target {
  background-color: var(--color-warning-soft);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cdk-drop-list .over-drop-target.cdk-tree-node {
  max-width: auto !important;
  min-height: auto !important;
  padding: 0 20px;
}

.viewer {
  display: flex;
  flex-direction: column;
  max-height: inherit;
  height: inherit;
}
.viewer-main {
  display: block;
  height: inherit;
  max-height: inherit;
  overflow-wrap: break-word;
}
.viewer .original-clause {
  background-color: var(--color-blue-A200) !important;
  height: auto !important;
  margin: 16px;
}
.viewer .original-clause .original-clause {
  margin: 0;
}
.viewer-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 8px;
  height: 52px;
  width: 100%;
}
.viewer-header {
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.viewer-header.comparation {
  display: flex;
  flex-direction: row;
  max-height: inherit;
  gap: 16px;
}
.viewer-body {
  max-height: calc(90vh - 120px);
  min-height: calc(90vh - 156px);
  width: 100%;
  overflow: auto;
  padding: 0 0 2px 0;
}
.viewer-body.header {
  max-height: calc(90vh - 172px);
  min-height: calc(90vh - 172px);
  height: calc(90vh - 172px);
}
.viewer-body.comparation {
  display: flex;
  flex-direction: row;
  max-height: inherit;
  gap: 16px;
}
.viewer-actions {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  gap: 8px;
}
.viewer-pagination-number {
  font-size: 14px;
  font-weight: 400;
}
.viewer-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.viewer-error {
  background-color: var(--color-white);
  color: var(--color-error);
  font-weight: 400;
  padding: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.viewer-error-sub {
  color: var(--color-error);
  padding-left: 4px;
}
.viewer-html {
  border: var(--border-default);
  overflow: auto;
  width: 100%;
}
.viewer-html.overflow {
  height: inherit;
  max-height: initial;
  min-height: initial;
  overflow: hidden;
}
.viewer-html .html-viewer-main:not(.overflow),
.viewer-html .wysiwyg-editor,
.viewer-html .cke_inner,
.viewer-html .cke_chrome,
.viewer-html .cke_contents {
  height: inherit !important;
}
.viewer-html .html-viewer-main {
  border: none;
  box-shadow: none;
}
.viewer .size-50 {
  height: inherit;
  max-width: calc(50% - 8px);
}
.viewer .size-50 .wysiwyg {
  height: inherit !important;
}
.viewer-comment {
  border-bottom: var(--border-default);
  background-color: var(--color-white) !important;
  position: -webkit-sticky;
  position: sticky;
  padding: 10px;
  top: 0;
  z-index: 1;
}
.viewer-comment .comment {
  background-color: var(--shadow-background) !important;
  padding: 16px;
}
.viewer-comment .comment-title {
  font-weight: 500;
  margin-bottom: 16px;
}
.viewer-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
.viewer-title {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.viewer-title.pagination {
  max-width: calc(100% - 160px);
}
.viewer-title-label {
  font-weight: 500;
  white-space: nowrap;
}
.viewer-title-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 16px);
}

.updates-button {
  display: flex;
}
.updates-button.warning .mat-button {
  border: 1px solid var(--color-warning);
}
.updates-menu {
  max-width: 100% !important;
  width: 100%;
  height: 100%;
}
.updates-content {
  align-content: center;
  align-items: flex-start;
  column-gap: 32px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px;
}

.tree-sidenav.mat-drawer {
  display: flex !important;
  width: 25%;
}
@media (max-width: 599px) {
  .tree-sidenav.mat-drawer {
    width: 50%;
  }
}
.tree-sidenav.mat-drawer.mat-drawer-side {
  border-right: none;
}
.tree-sidenav.mat-drawer .mat-drawer-inner-container {
  display: flex !important;
}
.tree-sidenav.mat-drawer-opened {
  min-width: 180px;
}
.tree-sidenav.mat-drawer.select-folder-stepper {
  border-right: var(--border-default) !important;
}
.tree-sidenav.mat-drawer.modal-tree-text {
  width: 50%;
  height: calc(80vh - 48px);
  max-height: calc(80vh - 48px);
  min-height: calc(80vh - 48px);
}
.tree-sidenav.mat-drawer.modal-tree-text .folder-tree {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: inherit;
}
.tree-sidenav.mat-drawer.modal-tree-text .folder-tree-search {
  min-height: 40px;
  padding: 0;
}
.tree-sidenav.mat-drawer.modal-tree-text .folder-tree .ctbox-tree {
  margin-top: 40px;
  max-height: calc(100% - 40px);
  min-height: calc(100% - 40px);
}
.tree-sidenav.mat-drawer[style*="visibility: hidden"], .tree-sidenav:not(.mat-drawer-opened) {
  display: flex !important;
  transform: translate3d(0, 0, 0) !important;
  visibility: visible !important;
  width: 32px !important;
  overflow: hidden;
}
.tree-sidenav-button {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  top: 38px;
  width: 32px;
}
.tree-sidenav-button.opened .mat-focus-indicator {
  border-radius: 100%;
}
.tree-sidenav.folded .folder-tree-container,
.tree-sidenav.folded .ctbox-tree {
  display: none !important;
}
.tree-sidenav.result-list .tree-sidenav-button {
  border-bottom: 6px solid var(--color-santander-dark);
  top: 0 !important;
  height: 40px;
}
.tree-header {
  display: flex;
  justify-content: space-between;
  padding: 12px 8px;
  width: 100%;
}
.tree-header-buttons {
  display: flex;
  gap: 16px;
}
.tree-editor {
  background-color: var(--color-white);
}
.tree-editor.folded {
  max-width: calc(100% - 32px) !important;
  margin: 0 0 0 32px !important;
}
.tree-list {
  padding: 0 0 0 24px;
  width: 100%;
}

.ctbox .header-logo.ctbox {
  display: flex;
  align-items: center;
}

.jump-line {
  white-space: pre-wrap;
}

.folder-tree {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.folder-tree .ctbox-tree {
  display: block;
  border: var(--border-default);
  background-color: var(--tree-background-color);
  height: inherit;
  max-height: calc(100% - 80px);
  min-height: calc(100% - 80px);
  overflow: hidden;
  padding-top: 8px;
  padding-left: 5px;
  border-right: 3px solid var(--color-gray-500);
}
.folder-tree .ctbox-tree .mat-tree {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: block;
}
.folder-tree .ctbox-tree.tree-filter {
  max-height: calc(100% - 10px);
  min-height: calc(100% - 10px);
}
.folder-tree-side-nav {
  display: flex;
  height: inherit;
  width: 100%;
}
.folder-tree-search {
  min-height: 79px;
  padding: 40px 0 0 32px;
}
.folder-tree-container {
  background-color: var(--tree-background-color);
  display: flex;
  flex-direction: column;
  height: inherit;
  padding: 0;
}
.folder-tree-tabs {
  min-height: 40px;
  padding-left: 24px;
}
.folder-tree-result {
  display: block;
  height: inherit;
  max-height: calc(100% - 40px);
  min-height: calc(100% - 40px);
}
.folder-tree-result .ctbox-tree {
  border: none;
}
.folder-tree-result-list {
  height: calc(100% - 40px);
  max-height: calc(100% - 40px);
  min-height: calc(100% - 40px);
}
.folder-tree-result-list-inquire {
  height: calc(100% - 40px);
  max-height: calc(100% - 40px);
  min-height: calc(100% - 40px);
  background-color: var(--background-color-inquire) !important;
}
.folder-tree-filter {
  display: flex;
  justify-content: center;
}

.ctbox .documents .ctbox-container-body .main-title {
  color: var(--color-documents);
}

.ctbox .smartforms .ctbox-container-body .main-title,
.ctbox .templates .ctbox-container-body .main-title {
  color: var(--color-templates);
}

.ctbox .clauses .ctbox-container-body .main-title {
  color: var(--color-clauses);
}

.ctbox .mat-h1,
.ctbox .mat-headline,
.ctbox .mat-typography .mat-h1,
.ctbox .mat-typography .mat-headline,
.ctbox .mat-typography h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.ctbox .mat-h2,
.ctbox .mat-title,
.ctbox .mat-typography .mat-h2,
.ctbox .mat-typography .mat-title,
.ctbox .mat-typography h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.ctbox .mat-h3,
.ctbox .mat-subheading-2,
.ctbox .mat-typography .mat-h3,
.ctbox .mat-typography .mat-subheading-2,
.ctbox .mat-typography h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.ctbox .mat-h4,
.ctbox .mat-subheading-1,
.ctbox .mat-typography .mat-h4,
.ctbox .mat-typography .mat-subheading-1,
.ctbox .mat-typography h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.ctbox .mat-h5,
.ctbox .mat-typography .mat-h5,
.ctbox .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 24px Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  margin: 0 0 12px;
}
.ctbox .mat-h6,
.ctbox .mat-typography .mat-h6,
.ctbox .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 24px Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  margin: 0 0 12px;
}
.ctbox .mat-body-strong,
.ctbox .mat-body-2,
.ctbox .mat-typography .mat-body-strong,
.ctbox .mat-typography .mat-body-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  letter-spacing: normal;
}
.ctbox .mat-body,
.ctbox .mat-body-1,
.ctbox .mat-typography .mat-body,
.ctbox .mat-typography .mat-body-1,
.ctbox .mat-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  letter-spacing: normal;
}
.ctbox .mat-body p,
.ctbox .mat-body-1 p,
.ctbox .mat-typography .mat-body p,
.ctbox .mat-typography .mat-body-1 p,
.ctbox .mat-typography p {
  margin: 0 0 12px;
}
.ctbox .mat-small,
.ctbox .mat-caption,
.ctbox .mat-typography .mat-small,
.ctbox .mat-typography .mat-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  letter-spacing: normal;
}
.ctbox .mat-display-4,
.ctbox .mat-typography .mat-display-4 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  font-family: Material Symbols Outlined;
  letter-spacing: normal;
  margin: 0 0 56px;
}
.ctbox .mat-display-3,
.ctbox .mat-typography .mat-display-3 {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.ctbox .mat-display-2,
.ctbox .mat-typography .mat-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.ctbox .mat-display-1,
.ctbox .mat-typography .mat-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.ctbox {
  --mat-badge-text-font: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.ctbox {
  --mat-bottom-sheet-container-text-font: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 24px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

.ctbox {
  --mat-legacy-button-toggle-text-font: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  --mat-standard-button-toggle-text-font: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
}

.ctbox {
  --mat-datepicker-calendar-text-font: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 400;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 400;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

.ctbox {
  --mat-expansion-header-text-font: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  --mat-expansion-header-text-size: 15px;
  --mat-expansion-header-text-weight: 400;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  --mat-expansion-container-text-line-height: 24px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

.ctbox {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

.ctbox {
  --mat-stepper-container-text-font: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  --mat-stepper-header-label-text-font: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 500;
}

.ctbox {
  --mat-toolbar-title-text-font: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 22px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

.ctbox .mat-tree {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
}
.ctbox .mat-tree-node,
.ctbox .mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.ctbox .mat-option {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  font-size: 16px;
}
.ctbox .mat-optgroup-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  letter-spacing: normal;
}
.ctbox .mat-button, .ctbox .mat-raised-button, .ctbox .mat-icon-button, .ctbox .mat-stroked-button,
.ctbox .mat-flat-button, .ctbox .mat-fab, .ctbox .mat-mini-fab {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.ctbox .mat-datepicker-content .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Fira Sans, FiraSans, Roboto, Arial, sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 24px);
  font-weight: var(--mdc-typography-button-font-weight, 400);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.ctbox .mat-card {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
}
.ctbox .mat-card-title {
  font-size: 32px;
  font-weight: 500;
}
.ctbox .mat-card-header .mat-card-title {
  font-size: 22px;
}
.ctbox .mat-card-subtitle,
.ctbox .mat-card-content {
  font-size: 14px;
}
.ctbox .mat-tooltip {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.ctbox .mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.ctbox input.mat-input-element {
  margin-top: -0.0625em;
}
.ctbox .mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  letter-spacing: normal;
}
.ctbox .mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.ctbox .mat-form-field-prefix .mat-icon,
.ctbox .mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.ctbox .mat-form-field-prefix .mat-icon-button,
.ctbox .mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.ctbox .mat-form-field-prefix .mat-icon-button .mat-icon,
.ctbox .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.ctbox .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.ctbox .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.ctbox .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.ctbox .mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}
.ctbox .mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.ctbox .mat-form-field-label {
  top: 1.34375em;
}
.ctbox .mat-form-field-underline {
  bottom: 1.34375em;
}
.ctbox .mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.ctbox .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.ctbox .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.ctbox .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.ctbox .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.ctbox .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.ctbox .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.ctbox .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.ctbox .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.ctbox .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .ctbox .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .ctbox .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .ctbox .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .ctbox .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.ctbox .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.ctbox .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.ctbox .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.ctbox .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.ctbox .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}
.ctbox .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.ctbox .mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.ctbox .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.ctbox .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.ctbox .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}
.ctbox .mat-select {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
}
.ctbox .mat-select-trigger {
  height: 1.125em;
}
.ctbox .mat-checkbox {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
}
.ctbox .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.ctbox .mat-dialog-title {
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  letter-spacing: normal;
}
.ctbox .mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.ctbox .mat-chip .mat-chip-trailing-icon.mat-icon,
.ctbox .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.ctbox .mat-slide-toggle-content {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
}
.ctbox .mat-tab-group {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
}
.ctbox .mat-tab-label, .ctbox .mat-tab-link {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.ctbox .mat-radio-button {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
}
.ctbox .mat-slider-thumb-label-text {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.ctbox .mat-menu-item {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.ctbox .mat-list-item {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
}
.ctbox .mat-list-option {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
}
.ctbox .mat-list-base .mat-list-item {
  font-size: 16px;
}
.ctbox .mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.ctbox .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.ctbox .mat-list-base .mat-list-option {
  font-size: 16px;
}
.ctbox .mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.ctbox .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.ctbox .mat-list-base .mat-subheader {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.ctbox .mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.ctbox .mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.ctbox .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.ctbox .mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.ctbox .mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.ctbox .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.ctbox .mat-list-base[dense] .mat-subheader {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.ctbox .mat-paginator,
.ctbox .mat-paginator-page-size .mat-select-trigger {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  font-size: 12px;
}
.ctbox .mat-simple-snackbar {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
  font-size: 14px;
}
.ctbox .mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 400;
}
.ctbox .mat-table {
  font-family: Fira Sans, FiraSans, Roboto, Arial, sans-serif;
}
.ctbox .mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.ctbox .mat-cell, .ctbox .mat-footer-cell {
  font-size: 14px;
}
.ctbox .mat-ripple {
  overflow: hidden;
  position: relative;
}
.ctbox .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.ctbox .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.ctbox .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .ctbox .mat-ripple-element {
  display: none;
}

.ctbox .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .ctbox .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.ctbox .cdk-overlay-container, .ctbox .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.ctbox .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.ctbox .cdk-overlay-container:empty {
  display: none;
}
.ctbox .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.ctbox .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.ctbox .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.ctbox .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .ctbox .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.ctbox .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.ctbox .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.ctbox .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.ctbox .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.ctbox .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.ctbox .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.ctbox textarea.cdk-textarea-autosize {
  resize: none;
}
.ctbox textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.ctbox textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.ctbox .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.ctbox .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.ctbox .mat-focus-indicator {
  position: relative;
}
.ctbox .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.ctbox .mat-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .ctbox {
  --mat-focus-indicator-display: block;
}

.ctbox .mat-mdc-focus-indicator {
  position: relative;
}
.ctbox .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.ctbox .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.cdk-high-contrast-active .ctbox {
  --mat-mdc-focus-indicator-display: block;
}

.ctbox .mat-button, .ctbox .mat-icon-button, .ctbox .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.ctbox .mat-button.mat-primary, .ctbox .mat-icon-button.mat-primary, .ctbox .mat-stroked-button.mat-primary {
  color: #409bd2;
}
.ctbox .mat-button.mat-accent, .ctbox .mat-icon-button.mat-accent, .ctbox .mat-stroked-button.mat-accent {
  color: #7cb342;
}
.ctbox .mat-button.mat-warn, .ctbox .mat-icon-button.mat-warn, .ctbox .mat-stroked-button.mat-warn {
  color: #e53935;
}
.ctbox .mat-button.mat-primary.mat-button-disabled, .ctbox .mat-button.mat-accent.mat-button-disabled, .ctbox .mat-button.mat-warn.mat-button-disabled, .ctbox .mat-button.mat-button-disabled.mat-button-disabled, .ctbox .mat-icon-button.mat-primary.mat-button-disabled, .ctbox .mat-icon-button.mat-accent.mat-button-disabled, .ctbox .mat-icon-button.mat-warn.mat-button-disabled, .ctbox .mat-icon-button.mat-button-disabled.mat-button-disabled, .ctbox .mat-stroked-button.mat-primary.mat-button-disabled, .ctbox .mat-stroked-button.mat-accent.mat-button-disabled, .ctbox .mat-stroked-button.mat-warn.mat-button-disabled, .ctbox .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.ctbox .mat-button.mat-primary .mat-button-focus-overlay, .ctbox .mat-icon-button.mat-primary .mat-button-focus-overlay, .ctbox .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #409bd2;
}
.ctbox .mat-button.mat-accent .mat-button-focus-overlay, .ctbox .mat-icon-button.mat-accent .mat-button-focus-overlay, .ctbox .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #7cb342;
}
.ctbox .mat-button.mat-warn .mat-button-focus-overlay, .ctbox .mat-icon-button.mat-warn .mat-button-focus-overlay, .ctbox .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e53935;
}
.ctbox .mat-button.mat-button-disabled .mat-button-focus-overlay, .ctbox .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .ctbox .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.ctbox .mat-button .mat-ripple-element, .ctbox .mat-icon-button .mat-ripple-element, .ctbox .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.ctbox .mat-button-focus-overlay {
  background: black;
}
.ctbox .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.ctbox .mat-flat-button, .ctbox .mat-raised-button, .ctbox .mat-fab, .ctbox .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.ctbox .mat-flat-button.mat-primary, .ctbox .mat-raised-button.mat-primary, .ctbox .mat-fab.mat-primary, .ctbox .mat-mini-fab.mat-primary {
  color: white;
}
.ctbox .mat-flat-button.mat-accent, .ctbox .mat-raised-button.mat-accent, .ctbox .mat-fab.mat-accent, .ctbox .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-flat-button.mat-warn, .ctbox .mat-raised-button.mat-warn, .ctbox .mat-fab.mat-warn, .ctbox .mat-mini-fab.mat-warn {
  color: white;
}
.ctbox .mat-flat-button.mat-primary.mat-button-disabled, .ctbox .mat-flat-button.mat-accent.mat-button-disabled, .ctbox .mat-flat-button.mat-warn.mat-button-disabled, .ctbox .mat-flat-button.mat-button-disabled.mat-button-disabled, .ctbox .mat-raised-button.mat-primary.mat-button-disabled, .ctbox .mat-raised-button.mat-accent.mat-button-disabled, .ctbox .mat-raised-button.mat-warn.mat-button-disabled, .ctbox .mat-raised-button.mat-button-disabled.mat-button-disabled, .ctbox .mat-fab.mat-primary.mat-button-disabled, .ctbox .mat-fab.mat-accent.mat-button-disabled, .ctbox .mat-fab.mat-warn.mat-button-disabled, .ctbox .mat-fab.mat-button-disabled.mat-button-disabled, .ctbox .mat-mini-fab.mat-primary.mat-button-disabled, .ctbox .mat-mini-fab.mat-accent.mat-button-disabled, .ctbox .mat-mini-fab.mat-warn.mat-button-disabled, .ctbox .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.ctbox .mat-flat-button.mat-primary, .ctbox .mat-raised-button.mat-primary, .ctbox .mat-fab.mat-primary, .ctbox .mat-mini-fab.mat-primary {
  background-color: #409bd2;
}
.ctbox .mat-flat-button.mat-accent, .ctbox .mat-raised-button.mat-accent, .ctbox .mat-fab.mat-accent, .ctbox .mat-mini-fab.mat-accent {
  background-color: #7cb342;
}
.ctbox .mat-flat-button.mat-warn, .ctbox .mat-raised-button.mat-warn, .ctbox .mat-fab.mat-warn, .ctbox .mat-mini-fab.mat-warn {
  background-color: #e53935;
}
.ctbox .mat-flat-button.mat-primary.mat-button-disabled, .ctbox .mat-flat-button.mat-accent.mat-button-disabled, .ctbox .mat-flat-button.mat-warn.mat-button-disabled, .ctbox .mat-flat-button.mat-button-disabled.mat-button-disabled, .ctbox .mat-raised-button.mat-primary.mat-button-disabled, .ctbox .mat-raised-button.mat-accent.mat-button-disabled, .ctbox .mat-raised-button.mat-warn.mat-button-disabled, .ctbox .mat-raised-button.mat-button-disabled.mat-button-disabled, .ctbox .mat-fab.mat-primary.mat-button-disabled, .ctbox .mat-fab.mat-accent.mat-button-disabled, .ctbox .mat-fab.mat-warn.mat-button-disabled, .ctbox .mat-fab.mat-button-disabled.mat-button-disabled, .ctbox .mat-mini-fab.mat-primary.mat-button-disabled, .ctbox .mat-mini-fab.mat-accent.mat-button-disabled, .ctbox .mat-mini-fab.mat-warn.mat-button-disabled, .ctbox .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.ctbox .mat-flat-button.mat-primary .mat-ripple-element, .ctbox .mat-raised-button.mat-primary .mat-ripple-element, .ctbox .mat-fab.mat-primary .mat-ripple-element, .ctbox .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.ctbox .mat-flat-button.mat-accent .mat-ripple-element, .ctbox .mat-raised-button.mat-accent .mat-ripple-element, .ctbox .mat-fab.mat-accent .mat-ripple-element, .ctbox .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-flat-button.mat-warn .mat-ripple-element, .ctbox .mat-raised-button.mat-warn .mat-ripple-element, .ctbox .mat-fab.mat-warn .mat-ripple-element, .ctbox .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.ctbox .mat-stroked-button:not([class*=mat-elevation-z]), .ctbox .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-fab:not([class*=mat-elevation-z]), .ctbox .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .ctbox .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .ctbox .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.ctbox .mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #409bd2;
}
.ctbox .mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #7cb342;
}
.ctbox .mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #e53935;
}
.ctbox .mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.ctbox .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #409bd2;
  --mdc-filled-button-label-text-color: #000;
}
.ctbox .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #7cb342;
  --mdc-filled-button-label-text-color: #000;
}
.ctbox .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #e53935;
  --mdc-filled-button-label-text-color: #fff;
}
.ctbox .mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.ctbox .mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #409bd2;
  --mdc-protected-button-label-text-color: #000;
}
.ctbox .mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #7cb342;
  --mdc-protected-button-label-text-color: #000;
}
.ctbox .mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #e53935;
  --mdc-protected-button-label-text-color: #fff;
}
.ctbox .mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.ctbox .mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.ctbox .mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.ctbox .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #409bd2;
}
.ctbox .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #7cb342;
}
.ctbox .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #e53935;
}
.ctbox .mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.ctbox .mat-datepicker-content .mat-mdc-button, .ctbox .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .ctbox .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.ctbox .mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ctbox .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .ctbox .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ctbox .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ctbox .mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .ctbox .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ctbox .mat-datepicker-content .mat-mdc-button.mat-primary, .ctbox .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #409bd2;
  --mat-mdc-button-ripple-color: rgba(64, 155, 210, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-button.mat-accent, .ctbox .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #7cb342;
  --mat-mdc-button-ripple-color: rgba(124, 179, 66, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-button.mat-warn, .ctbox .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #e53935;
  --mat-mdc-button-ripple-color: rgba(229, 57, 53, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-raised-button, .ctbox .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .ctbox .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.ctbox .mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ctbox .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .ctbox .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ctbox .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ctbox .mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .ctbox .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ctbox .mat-datepicker-content .mat-mdc-raised-button.mat-primary, .ctbox .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-raised-button.mat-accent, .ctbox .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-raised-button.mat-warn, .ctbox .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.ctbox .mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .ctbox .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ctbox .mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.ctbox .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #409bd2;
  --mat-mdc-button-persistent-ripple-color: #409bd2;
  --mat-mdc-button-ripple-color: rgba(64, 155, 210, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #7cb342;
  --mat-mdc-button-persistent-ripple-color: #7cb342;
  --mat-mdc-button-ripple-color: rgba(124, 179, 66, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #e53935;
  --mat-mdc-button-persistent-ripple-color: #e53935;
  --mat-mdc-button-ripple-color: rgba(229, 57, 53, 0.1);
}
.ctbox .mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-option:hover:not(.mat-option-disabled), .ctbox .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.ctbox .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.ctbox .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #409bd2;
}
.ctbox .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #7cb342;
}
.ctbox .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e53935;
}
.ctbox .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.ctbox .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.ctbox .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.ctbox .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.ctbox .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #409bd2;
}
.ctbox .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.ctbox .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #409bd2;
}
.ctbox .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.ctbox .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.ctbox .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.ctbox .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #7cb342;
}
.ctbox .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.ctbox .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #7cb342;
}
.ctbox .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.ctbox .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.ctbox .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.ctbox .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #7cb342;
}
.ctbox .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.ctbox .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #7cb342;
}
.ctbox .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.ctbox .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.ctbox .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.ctbox .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e53935;
}
.ctbox .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.ctbox .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e53935;
}
.ctbox .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.ctbox .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}
.ctbox .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.ctbox .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.ctbox .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.ctbox .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.ctbox .mat-app-background, .ctbox.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.ctbox .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.ctbox .mat-progress-bar-background {
  fill: #cce2f0;
}
.ctbox .mat-progress-bar-buffer {
  background-color: #cce2f0;
}
.ctbox .mat-progress-bar-fill::after {
  background-color: #409bd2;
}
.ctbox .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #dbe8cc;
}
.ctbox .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #dbe8cc;
}
.ctbox .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #7cb342;
}
.ctbox .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cac9;
}
.ctbox .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cac9;
}
.ctbox .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e53935;
}
.ctbox .mat-progress-spinner circle, .ctbox .mat-spinner circle {
  stroke: #409bd2;
}
.ctbox .mat-progress-spinner.mat-accent circle, .ctbox .mat-spinner.mat-accent circle {
  stroke: #7cb342;
}
.ctbox .mat-progress-spinner.mat-warn circle, .ctbox .mat-spinner.mat-warn circle {
  stroke: #e53935;
}
.ctbox .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.ctbox .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.ctbox .mat-input-element:disabled,
.ctbox .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-input-element {
  caret-color: #409bd2;
}
.ctbox .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.ctbox .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.ctbox .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.ctbox .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.ctbox .mat-form-field.mat-accent .mat-input-element {
  caret-color: #7cb342;
}
.ctbox .mat-form-field.mat-warn .mat-input-element,
.ctbox .mat-form-field-invalid .mat-input-element {
  caret-color: #e53935;
}
.ctbox .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e53935;
}
.ctbox .mat-form-field-disabled .mat-form-field-prefix,
.ctbox .mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.ctbox .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.ctbox .mat-form-field.mat-focused .mat-form-field-label {
  color: #409bd2;
}
.ctbox .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #7cb342;
}
.ctbox .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e53935;
}
.ctbox .mat-focused .mat-form-field-required-marker {
  color: #7cb342;
}
.ctbox .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #409bd2;
}
.ctbox .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #7cb342;
}
.ctbox .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e53935;
}
.ctbox .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #409bd2;
}
.ctbox .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #7cb342;
}
.ctbox .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e53935;
}
.ctbox .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e53935;
}
.ctbox .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.ctbox .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e53935;
}
.ctbox .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.ctbox .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e53935;
}
.ctbox .mat-error {
  color: #e53935;
}
.ctbox .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.ctbox .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.ctbox .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.ctbox .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.ctbox .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.ctbox .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.ctbox .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.ctbox .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.ctbox .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.ctbox .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.ctbox .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.ctbox .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #409bd2;
}
.ctbox .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #7cb342;
}
.ctbox .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e53935;
}
.ctbox .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e53935;
}
.ctbox .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.ctbox .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.ctbox .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.ctbox .mat-select-panel {
  background: white;
}
.ctbox .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.ctbox .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #409bd2;
}
.ctbox .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #7cb342;
}
.ctbox .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e53935;
}
.ctbox .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e53935;
}
.ctbox .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.ctbox .mat-checkbox-checkmark {
  fill: #fafafa;
}
.ctbox .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.ctbox .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.ctbox .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .ctbox .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #409bd2;
}
.ctbox .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .ctbox .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #7cb342;
}
.ctbox .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .ctbox .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e53935;
}
.ctbox .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .ctbox .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.ctbox .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.ctbox .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.ctbox .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.ctbox .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #409bd2;
}
.ctbox .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.ctbox .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #7cb342;
}
.ctbox .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.ctbox .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e53935;
}
.ctbox .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.ctbox .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.ctbox .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.ctbox .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.ctbox .mat-chip.mat-standard-chip::after {
  background: black;
}
.ctbox .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #409bd2;
  color: white;
}
.ctbox .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.ctbox .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.ctbox .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e53935;
  color: white;
}
.ctbox .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.ctbox .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.ctbox .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #7cb342;
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.ctbox .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #7cb342;
}
.ctbox .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(124, 179, 66, 0.54);
}
.ctbox .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #7cb342;
}
.ctbox .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #409bd2;
}
.ctbox .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(64, 155, 210, 0.54);
}
.ctbox .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #409bd2;
}
.ctbox .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e53935;
}
.ctbox .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(229, 57, 53, 0.54);
}
.ctbox .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e53935;
}
.ctbox .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.ctbox .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.ctbox .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.ctbox .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #409bd2;
}
.ctbox .mat-radio-button.mat-primary .mat-radio-inner-circle,
.ctbox .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .ctbox .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .ctbox .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #409bd2;
}
.ctbox .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #7cb342;
}
.ctbox .mat-radio-button.mat-accent .mat-radio-inner-circle,
.ctbox .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .ctbox .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .ctbox .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #7cb342;
}
.ctbox .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e53935;
}
.ctbox .mat-radio-button.mat-warn .mat-radio-inner-circle,
.ctbox .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .ctbox .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .ctbox .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e53935;
}
.ctbox .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.ctbox .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.ctbox .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.ctbox .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.ctbox .mat-slider.mat-primary .mat-slider-track-fill,
.ctbox .mat-slider.mat-primary .mat-slider-thumb,
.ctbox .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #409bd2;
}
.ctbox .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.ctbox .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(64, 155, 210, 0.2);
}
.ctbox .mat-slider.mat-accent .mat-slider-track-fill,
.ctbox .mat-slider.mat-accent .mat-slider-thumb,
.ctbox .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #7cb342;
}
.ctbox .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(124, 179, 66, 0.2);
}
.ctbox .mat-slider.mat-warn .mat-slider-track-fill,
.ctbox .mat-slider.mat-warn .mat-slider-thumb,
.ctbox .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e53935;
}
.ctbox .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.ctbox .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(229, 57, 53, 0.2);
}
.ctbox .mat-slider:hover .mat-slider-track-background,
.ctbox .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-slider.mat-slider-disabled .mat-slider-track-background,
.ctbox .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.ctbox .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.ctbox .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.ctbox .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.ctbox .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.ctbox .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.ctbox .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.ctbox .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.ctbox .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .ctbox .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .ctbox .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.ctbox .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.ctbox .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.ctbox .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.ctbox .mat-menu-panel {
  background: white;
}
.ctbox .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-menu-item[disabled],
.ctbox .mat-menu-item[disabled] .mat-menu-submenu-icon,
.ctbox .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-menu-item .mat-icon-no-color,
.ctbox .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.ctbox .mat-menu-item:hover:not([disabled]),
.ctbox .mat-menu-item.cdk-program-focused:not([disabled]),
.ctbox .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.ctbox .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.ctbox .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.ctbox .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-list-option:hover, .ctbox .mat-list-option:focus,
.ctbox .mat-nav-list .mat-list-item:hover,
.ctbox .mat-nav-list .mat-list-item:focus,
.ctbox .mat-action-list .mat-list-item:hover,
.ctbox .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.ctbox .mat-list-single-selected-option, .ctbox .mat-list-single-selected-option:hover, .ctbox .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.ctbox .mat-paginator {
  background: white;
}
.ctbox .mat-paginator,
.ctbox .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.ctbox .mat-paginator-decrement,
.ctbox .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.ctbox .mat-paginator-first,
.ctbox .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.ctbox .mat-icon-button[disabled] .mat-paginator-decrement,
.ctbox .mat-icon-button[disabled] .mat-paginator-increment,
.ctbox .mat-icon-button[disabled] .mat-paginator-first,
.ctbox .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-tab-nav-bar,
.ctbox .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ctbox .mat-tab-group-inverted-header .mat-tab-nav-bar,
.ctbox .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.ctbox .mat-tab-label, .ctbox .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-tab-label.mat-tab-disabled, .ctbox .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.ctbox .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.ctbox .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.ctbox .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .ctbox .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.ctbox .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.ctbox .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .ctbox .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .ctbox .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.ctbox .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.ctbox .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(0, 91, 146, 0.3);
}
.ctbox .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .ctbox .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.ctbox .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.ctbox .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.ctbox .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.ctbox .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-tab-group.mat-primary .mat-ink-bar, .ctbox .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #409bd2;
}
.ctbox .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .ctbox .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .ctbox .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .ctbox .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.ctbox .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .ctbox .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.ctbox .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.ctbox .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .ctbox .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .ctbox .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.ctbox .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.ctbox .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(85, 139, 47, 0.3);
}
.ctbox .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .ctbox .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.ctbox .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.ctbox .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.ctbox .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.ctbox .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-tab-group.mat-accent .mat-ink-bar, .ctbox .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #7cb342;
}
.ctbox .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .ctbox .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .ctbox .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .ctbox .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .ctbox .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.ctbox .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.ctbox .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .ctbox .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .ctbox .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.ctbox .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.ctbox .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(198, 40, 40, 0.3);
}
.ctbox .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .ctbox .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.ctbox .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.ctbox .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.ctbox .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.ctbox .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-tab-group.mat-warn .mat-ink-bar, .ctbox .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e53935;
}
.ctbox .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .ctbox .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .ctbox .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .ctbox .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.ctbox .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .ctbox .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.ctbox .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.ctbox .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .ctbox .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .ctbox .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.ctbox .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.ctbox .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(0, 91, 146, 0.3);
}
.ctbox .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .ctbox .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.ctbox .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.ctbox .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.ctbox .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.ctbox .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-tab-group.mat-background-primary > .mat-tab-header, .ctbox .mat-tab-group.mat-background-primary > .mat-tab-link-container, .ctbox .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #409bd2;
}
.ctbox .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .ctbox .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.ctbox .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .ctbox .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.ctbox .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.ctbox .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.ctbox .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.ctbox .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.ctbox .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.ctbox .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.ctbox .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.ctbox .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .ctbox .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.ctbox .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.ctbox .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .ctbox .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .ctbox .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.ctbox .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.ctbox .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(85, 139, 47, 0.3);
}
.ctbox .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .ctbox .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.ctbox .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.ctbox .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.ctbox .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.ctbox .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-tab-group.mat-background-accent > .mat-tab-header, .ctbox .mat-tab-group.mat-background-accent > .mat-tab-link-container, .ctbox .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #7cb342;
}
.ctbox .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .ctbox .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .ctbox .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.ctbox .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.ctbox .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.ctbox .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.ctbox .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.ctbox .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.ctbox .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.ctbox .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .ctbox .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.ctbox .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.ctbox .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .ctbox .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .ctbox .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.ctbox .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.ctbox .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(198, 40, 40, 0.3);
}
.ctbox .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .ctbox .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.ctbox .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.ctbox .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.ctbox .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.ctbox .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.ctbox .mat-tab-group.mat-background-warn > .mat-tab-header, .ctbox .mat-tab-group.mat-background-warn > .mat-tab-link-container, .ctbox .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e53935;
}
.ctbox .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .ctbox .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.ctbox .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .ctbox .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.ctbox .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.ctbox .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.ctbox .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.ctbox .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.ctbox .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.ctbox .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.ctbox .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.ctbox .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.ctbox .mat-simple-snackbar-action {
  color: #7cb342;
}
.ctbox .mat-table {
  background: white;
}
.ctbox .mat-table thead, .ctbox .mat-table tbody, .ctbox .mat-table tfoot,
.ctbox mat-header-row, .ctbox mat-row, .ctbox mat-footer-row,
.ctbox [mat-header-row], .ctbox [mat-row], .ctbox [mat-footer-row],
.ctbox .mat-table-sticky {
  background: inherit;
}
.ctbox mat-row, .ctbox mat-header-row, .ctbox mat-footer-row,
.ctbox th.mat-header-cell, .ctbox td.mat-cell, .ctbox td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.ctbox .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.ctbox .mat-cell, .ctbox .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-badge {
  position: relative;
}
.ctbox .mat-badge.mat-badge {
  overflow: visible;
}
.ctbox .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .ctbox .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.ctbox .mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}
.ctbox .mat-badge-hidden .mat-badge-content {
  display: none;
}
.ctbox .ng-animate-disabled .mat-badge-content,
.ctbox .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.ctbox .mat-badge-content.mat-badge-active {
  transform: none;
}
.ctbox .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.ctbox .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.ctbox .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.ctbox .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .ctbox .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.ctbox .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .ctbox .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.ctbox .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .ctbox .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.ctbox .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .ctbox .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.ctbox .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.ctbox .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.ctbox .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.ctbox .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .ctbox .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.ctbox .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .ctbox .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.ctbox .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .ctbox .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.ctbox .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .ctbox .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.ctbox .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.ctbox .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.ctbox .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.ctbox .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .ctbox .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.ctbox .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .ctbox .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.ctbox .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .ctbox .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.ctbox .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .ctbox .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.ctbox {
  --mat-badge-background-color: #409bd2;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.ctbox .mat-badge-accent {
  --mat-badge-background-color: #7cb342;
  --mat-badge-text-color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-badge-warn {
  --mat-badge-background-color: #e53935;
  --mat-badge-text-color: white;
}
.ctbox {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

.ctbox {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

.ctbox {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #409bd2;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(64, 155, 210, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(64, 155, 210, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(64, 155, 210, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #409bd2;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(64, 155, 210, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.ctbox .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #7cb342;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(124, 179, 66, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(124, 179, 66, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(124, 179, 66, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(124, 179, 66, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.ctbox .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #e53935;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(229, 57, 53, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(229, 57, 53, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(229, 57, 53, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(229, 57, 53, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.ctbox .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #7cb342;
}
.ctbox .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #e53935;
}
.ctbox {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

.ctbox {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

.ctbox {
  --mat-icon-color: inherit;
}

.ctbox .mat-icon.mat-primary {
  --mat-icon-color: #409bd2;
}
.ctbox .mat-icon.mat-accent {
  --mat-icon-color: #7cb342;
}
.ctbox .mat-icon.mat-warn {
  --mat-icon-color: #e53935;
}
.ctbox {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

.ctbox {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #409bd2;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #409bd2;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #409bd2;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #e53935;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #e53935;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.ctbox .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #7cb342;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #7cb342;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #7cb342;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #e53935;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #e53935;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #e53935;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.ctbox .mat-sort-header-arrow {
  color: #757575;
}
.ctbox {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.ctbox .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #409bd2;
  --mat-toolbar-container-text-color: white;
}
.ctbox .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #7cb342;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}
.ctbox .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #e53935;
  --mat-toolbar-container-text-color: white;
}
.ctbox .mat-tree {
  background: white;
}
.ctbox .mat-tree-node,
.ctbox .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.ctbox .form-sign {
  padding: 0 0 20px 0;
}
.ctbox .mat-calendar-body-cell-content.mat-focus-indicator {
  position: absolute !important;
}
.ctbox .mat-radio-indent {
  padding-left: 32px;
}
.ctbox .mat-radio-indent .mat-radio-label {
  white-space: normal !important;
}
.ctbox .mat-radio-indent.center .mat-radio-container {
  align-self: center;
}
.ctbox .mat-radio-container {
  max-height: 14px;
}
.ctbox .mat-radio-container .mat-radio-outer-circle,
.ctbox .mat-radio-container .mat-radio-inner-circle {
  max-width: 14px;
  max-height: 14px;
}
.ctbox .mat-checkbox-inner-container .mat-ripple-element,
.ctbox .mat-checkbox-inner-container .mat-radio-ripple,
.ctbox .mat-radio-container .mat-ripple-element,
.ctbox .mat-radio-container .mat-radio-ripple {
  display: none;
}
.ctbox .mat-radio-label-content {
  line-height: initial;
  padding-left: 0;
}
.ctbox .mat-select-arrow-wrapper {
  display: flex;
  padding: 5px 0 0 0;
}
.ctbox .mat-form-field {
  width: 100%;
  line-height: 19px;
}
.ctbox .mat-form-field.size-5 {
  min-width: 5%;
  max-width: 5%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-5 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-5 {
    min-width: 4%;
    max-width: 4%;
  }
}
.ctbox .mat-form-field.size-10 {
  min-width: 10%;
  max-width: 10%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-10 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-10 {
    min-width: 8%;
    max-width: 8%;
  }
}
.ctbox .mat-form-field.size-15 {
  min-width: 15%;
  max-width: 15%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-15 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-15 {
    min-width: 12%;
    max-width: 12%;
  }
}
.ctbox .mat-form-field.size-20 {
  min-width: 20%;
  max-width: 20%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-20 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-20 {
    min-width: 16%;
    max-width: 16%;
  }
}
.ctbox .mat-form-field.size-25 {
  min-width: 25%;
  max-width: 25%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-25 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-25 {
    min-width: 20%;
    max-width: 20%;
  }
}
.ctbox .mat-form-field.size-30 {
  min-width: 30%;
  max-width: 30%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-30 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-30 {
    min-width: 24%;
    max-width: 24%;
  }
}
.ctbox .mat-form-field.size-35 {
  min-width: 35%;
  max-width: 35%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-35 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-35 {
    min-width: 28%;
    max-width: 28%;
  }
}
.ctbox .mat-form-field.size-40 {
  min-width: 40%;
  max-width: 40%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-40 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-40 {
    min-width: 32%;
    max-width: 32%;
  }
}
.ctbox .mat-form-field.size-45 {
  min-width: 45%;
  max-width: 45%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-45 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-45 {
    min-width: 36%;
    max-width: 36%;
  }
}
.ctbox .mat-form-field.size-50 {
  min-width: 50%;
  max-width: 50%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-50 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-50 {
    min-width: 40%;
    max-width: 40%;
  }
}
.ctbox .mat-form-field.size-55 {
  min-width: 55%;
  max-width: 55%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-55 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-55 {
    min-width: 44%;
    max-width: 44%;
  }
}
.ctbox .mat-form-field.size-60 {
  min-width: 60%;
  max-width: 60%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-60 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-60 {
    min-width: 48%;
    max-width: 48%;
  }
}
.ctbox .mat-form-field.size-65 {
  min-width: 65%;
  max-width: 65%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-65 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-65 {
    min-width: 52%;
    max-width: 52%;
  }
}
.ctbox .mat-form-field.size-70 {
  min-width: 70%;
  max-width: 70%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-70 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-70 {
    min-width: 56%;
    max-width: 56%;
  }
}
.ctbox .mat-form-field.size-75 {
  min-width: 75%;
  max-width: 75%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-75 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-75 {
    min-width: 60%;
    max-width: 60%;
  }
}
.ctbox .mat-form-field.size-80 {
  min-width: 80%;
  max-width: 80%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-80 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-80 {
    min-width: 64%;
    max-width: 64%;
  }
}
.ctbox .mat-form-field.size-85 {
  min-width: 85%;
  max-width: 85%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-85 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-85 {
    min-width: 68%;
    max-width: 68%;
  }
}
.ctbox .mat-form-field.size-90 {
  min-width: 90%;
  max-width: 90%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-90 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-90 {
    min-width: 72%;
    max-width: 72%;
  }
}
.ctbox .mat-form-field.size-95 {
  min-width: 95%;
  max-width: 95%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-95 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-95 {
    min-width: 76%;
    max-width: 76%;
  }
}
.ctbox .mat-form-field.size-100 {
  min-width: 100%;
  max-width: 100%;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.size-100 {
    min-width: 100%;
  }
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.size-100 {
    min-width: 80%;
    max-width: 80%;
  }
}
.ctbox .mat-form-field.ctbox-search-form .mat-form-field-infix {
  max-width: calc(100% - 20px);
}
.ctbox .mat-form-field.ctbox-search-form .mat-form-field-suffix {
  display: flex;
  height: 31px;
  max-width: 16px;
  top: 0;
}
.ctbox .mat-form-field.ctbox-search-form.double .mat-form-field-infix {
  max-width: calc(100% - 38px);
}
.ctbox .mat-form-field.ctbox-search-form.buttonsShow .mat-form-field-infix {
  max-width: calc(100% - 74px) !important;
}
.ctbox .mat-form-field.select308 {
  width: 308px;
}
@media (max-width: 599px) {
  .ctbox .mat-form-field.select308 {
    width: 100%;
  }
}
.ctbox .mat-form-field.ctbox-tree {
  padding: 8px 0 16px 40px;
  background: transparent;
  border: none;
}
.ctbox .mat-form-field.ctbox-tree .mat-form-field-infix {
  width: 100%;
}
.ctbox .mat-form-field.ctbox-tree-node {
  padding: 0 0 16px 0;
}
.ctbox .mat-form-field.ctbox-tree-node .mat-form-field-infix {
  width: 100%;
}
.ctbox .mat-form-field.select13 {
  max-width: 130px;
}
.ctbox .mat-form-field.name {
  min-width: 390px;
}
@media (max-width: 959px) {
  .ctbox .mat-form-field.name {
    min-width: 190px;
  }
}
.ctbox .mat-form-field.name[appearance=none] .mat-form-field-flex,
.ctbox .mat-form-field.name[appearance=none] .mat-form-field-subscript-wrapper {
  padding-left: 0;
}
.ctbox .mat-form-field.name[appearance=none] .mat-error {
  padding: 1.8em 0.5em 0 0;
}
.ctbox .mat-form-field.number {
  max-width: 48px;
}
.ctbox .mat-form-field.number input[matinput]::-webkit-outer-spin-button,
.ctbox .mat-form-field.number input[matinput]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ctbox .mat-form-field.number input[matinput][type=number] {
  -moz-appearance: textfield;
}
.ctbox .mat-form-field.months {
  max-width: 100px;
}
.ctbox .mat-form-field.date {
  width: 180px;
  max-width: 180px;
}
.ctbox .mat-form-field.date.big .mat-form-field-infix {
  width: 270px;
}
.ctbox .mat-form-field .mat-form-field-outline-start,
.ctbox .mat-form-field .mat-form-field-outline-gap,
.ctbox .mat-form-field .mat-form-field-outline-end {
  color: var(--color-disabled);
  border-width: 1px !important;
}
.ctbox .mat-form-field.coment .mat-input-element {
  min-height: 60px;
}
.ctbox .mat-form-field.days {
  max-width: 45px;
}
.ctbox .mat-form-field.days .mat-form-field-subscript-wrapper {
  overflow: visible !important;
  min-width: 320px;
  left: -160px;
}
.ctbox .mat-form-field.mat-form-field-appearance-outline textarea,
.ctbox .mat-form-field.mat-form-field-appearance-outline input {
  border: none !important;
}
.ctbox .mat-form-field.mat-form-field-appearance-outline.shape-oval {
  padding: 8px 0 0 0;
}
.ctbox .mat-form-field.mat-form-field-appearance-outline.shape-oval .mat-form-field-suffix {
  max-width: 24px;
  top: 6px;
}
.ctbox .mat-form-field.mat-form-field-appearance-outline.shape-oval .mat-form-field-outline-end {
  border-radius: 0 30px 30px 0 !important;
}
.ctbox .mat-form-field.mat-form-field-appearance-outline.shape-oval .mat-form-field-outline-start {
  border-radius: 30px 0 0 30px !important;
  flex-grow: 1;
}
.ctbox .mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-start,
.ctbox .mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0;
}
.ctbox .mat-form-field.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0 0 0.3em 0;
  min-width: auto;
}
.ctbox .mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0;
  padding-bottom: 0;
}
.ctbox .mat-form-field.mat-form-field-appearance-outline .mat-error {
  padding: 1.2em 0 0 0;
  margin-bottom: 1em;
}
.ctbox .mat-form-field.mat-form-field-appearance-outline .mat-form-field-label {
  color: var(--color-gray-800);
}
.ctbox .mat-form-field.mat-form-field-appearance-outline .mat-form-field-label.mat-empty {
  margin-top: -1em;
}
.ctbox .mat-form-field.mat-form-field-appearance-outline .mat-form-field-suffix {
  max-width: 16px;
  align-self: center;
}
.ctbox .mat-form-field.mat-form-field-appearance-outline.with-hint {
  padding: 8px 0 32px 0;
}
.ctbox .mat-form-field.readonly {
  pointer-events: none;
}
.ctbox .mat-form-field.readonly .mat-form-field-outline-start,
.ctbox .mat-form-field.readonly .mat-form-field-outline-gap,
.ctbox .mat-form-field.readonly .mat-form-field-outline-end {
  border-color: transparent;
}
.ctbox .mat-form-field.disabled, .ctbox .mat-form-field.mat-form-field-disabled {
  pointer-events: none;
}
.ctbox .mat-form-field.disabled .mat-form-field-outline, .ctbox .mat-form-field.mat-form-field-disabled .mat-form-field-outline {
  background-color: var(--shadow-background);
}
.ctbox .mat-form-field.disabled .mat-datepicker-toggle,
.ctbox .mat-form-field.disabled .mat-form-field-label,
.ctbox .mat-form-field.disabled .mat-input-element, .ctbox .mat-form-field.mat-form-field-disabled .mat-datepicker-toggle,
.ctbox .mat-form-field.mat-form-field-disabled .mat-form-field-label,
.ctbox .mat-form-field.mat-form-field-disabled .mat-input-element {
  color: var(--color-gray-500);
}
.ctbox .mat-form-field.mat-focused .mat-form-field-label.mat-empty {
  margin-top: -0.25em;
}
.ctbox .mat-form-field.mat-form-field-invalid .mat-form-field-outline-start,
.ctbox .mat-form-field.mat-form-field-invalid .mat-form-field-outline-gap,
.ctbox .mat-form-field.mat-form-field-invalid .mat-form-field-outline-end,
.ctbox .mat-form-field.mat-form-field-invalid .mat-datepicker-toggle,
.ctbox .mat-form-field.mat-form-field-invalid .mat-form-field-label,
.ctbox .mat-form-field.mat-form-field-invalid .mat-input-element {
  color: var(--color-error) !important;
}
.ctbox .mat-form-field.mat-form-field-invalid .ctbox-search-actions-search {
  border-left-color: var(--color-error) !important;
}
.ctbox .mat-form-field .mat-hint {
  margin: 0.5em 0;
}
.ctbox .mat-checkbox.filter .mat-checkbox-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}
.ctbox .mat-checkbox-frame {
  border-width: 1px !important;
}
.ctbox .mat-checkbox.checklist-leaf-node {
  background-color: var(--color-white);
}
.ctbox .mat-checkbox-disabled .mat-checkbox-frame {
  background-color: var(--shadow-background);
}
.ctbox .mat-table caption {
  display: none;
}
.ctbox .mat-table.table-list {
  width: 100%;
}
.ctbox .mat-table.table-list .icon {
  display: inline-block;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  margin: 3px 0 0 0;
  width: 24px;
  height: 24px;
}
.ctbox .mat-table.table-list tr.mat-header-row {
  height: auto;
}
.ctbox .mat-table.table-list tr.mat-header-row.xl {
  height: 56px;
}
.ctbox .mat-table.table-list th.mat-header-cell {
  color: var(--color-text);
  border-bottom-color: var(--border-color-default);
  font-size: 14px;
  padding: 4px;
  vertical-align: middle;
  line-height: 24px;
}
.ctbox .mat-table.table-list .mat-row:nth-child(odd) {
  background-color: var(--shadow-background);
}
.ctbox .mat-table.table-list td.mat-cell {
  border-bottom: none;
  color: var(--color-text);
  cursor: default;
  font-size: 13px;
  line-height: 1.75em;
  padding: 0 4px;
  vertical-align: middle;
}
.ctbox .mat-table.table-list td.mat-cell.mat-column-expandedDetail {
  padding: 0;
}
.ctbox .mat-table.table-list td.mat-cell.mat-column-expandedDetail .mat-column-description {
  margin-left: 40px;
}
.ctbox .mat-table.table-bg-white {
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}
.ctbox .mat-table.table-bg-white tr.mat-row,
.ctbox .mat-table.table-bg-white tr.mat-footer-row,
.ctbox .mat-table.table-bg-white tr.mat-header-row {
  height: auto;
}
.ctbox .mat-table.table-bg-white .mat-cell {
  vertical-align: middle;
  padding: 4px 8px 0 0;
}
.ctbox .mat-table.table-bg-white .mat-cell-check {
  width: 32px;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
}
.ctbox .mat-table.table-bg-white .mat-row:nth-child(even) {
  background-color: var(--shadow-background);
}
.ctbox .mat-table.table-bg-white .mat-row:focus, .ctbox .mat-table.table-bg-white .mat-row:hover, .ctbox .mat-table.table-bg-white .mat-row:active {
  background-color: var(--color-gray-200);
  transition: background-color 200ms ease-in-out;
}
.ctbox .mat-table.table-bg-white .mat-row.selected {
  background-color: var(--color-orange-50);
}
.ctbox .mat-table.table-bg-white .mat-row.selected:focus, .ctbox .mat-table.table-bg-white .mat-row.selected:hover, .ctbox .mat-table.table-bg-white .mat-row.selected:active {
  background-color: var(--color-orange-200);
  transition: background-color 200ms ease-in-out;
}
.ctbox .mat-table.table-permission {
  width: 100%;
}
.ctbox .mat-table.table-permission th.mat-header-cell {
  background-color: var(--color-gray-200);
  border-left: 1px solid var(--color-gray-400);
  padding: 8px;
}
.ctbox .mat-table.table-permission td.mat-cell {
  padding: 8px 16px;
  border-left: 1px solid var(--color-gray-400);
}
.ctbox .mat-table.table-ctbox-border {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  border: var(--border-default);
}
.ctbox .mat-table.table-legal-alert th.mat-header-cell {
  color: var(--color-santander-dark);
  font-size: 1.2em;
  padding: 4px;
  vertical-align: top;
}
.ctbox .mat-table.table-legal-alert th.mat-header-cell .mat-sort-header-content {
  padding: 4px 0;
}
.ctbox .mat-table.table-legal-alert td.mat-cell {
  padding: 8px;
}
.ctbox .mat-paginator-container {
  min-height: auto;
}
.ctbox .mat-paginator-range-label {
  margin: 0;
}
.ctbox .mat-cell-flex {
  display: flex;
  align-items: center;
}
.ctbox .mat-cell-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ctbox .mat-cell-content .mat-cell-text {
  font-size: 1em;
}
.ctbox .mat-cell-content .mat-cell-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ctbox .mat-cell-content .mat-cell-icon.administrator {
  color: var(--color-white);
  background-color: var(--color-administration);
}
.ctbox .mat-sort-header-content {
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
}
.ctbox .mat-column-icon, .ctbox .mat-column-iconAdditional, .ctbox .mat-column-inForceColumn {
  width: 60px;
  max-width: 60px;
  vertical-align: middle;
}
.ctbox .mat-column-nameVersion, .ctbox .mat-column-annexedName, .ctbox .mat-column-contractName, .ctbox .mat-column-name {
  width: 100%;
  max-width: 200px;
}
.ctbox .mat-column-signers, .ctbox .mat-column-modificationTimeStamp {
  min-width: 150px;
}
.ctbox .mat-column-documentStatus {
  min-width: 150px;
}
.ctbox .mat-column-lastModificationUser, .ctbox .mat-column-creationUser {
  min-width: 200px;
}
.ctbox .mat-column-clauseFolder, .ctbox .mat-column-templateFolder, .ctbox .mat-column-contractFolder {
  max-width: 200px;
}
.ctbox .mat-cell .name,
.ctbox .mat-cell .link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ctbox .mat-tooltip {
  background-color: var(--color-white);
  border: var(--border-default);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  color: var(--color-text-secondary) !important;
  font-size: 13px;
  margin: 0;
  max-width: 450px !important;
  padding: 8px !important;
}
.ctbox .mat-tab-nav-bar,
.ctbox .mat-tab-header {
  border-bottom: none;
}
.ctbox .mat-tab-list .mat-ink-bar,
.ctbox .mat-tab-nav-bar .mat-ink-bar {
  height: 0;
}
.ctbox .mat-tab-list .mat-tab-label,
.ctbox .mat-tab-list .mat-tab-link,
.ctbox .mat-tab-nav-bar .mat-tab-label,
.ctbox .mat-tab-nav-bar .mat-tab-link {
  opacity: 1;
  border-bottom: 6px solid transparent;
}
.ctbox .mat-tab-list.mat-primary.documents .cdk-focused,
.ctbox .mat-tab-list.mat-primary.documents .mat-tab-label-active,
.ctbox .mat-tab-nav-bar.mat-primary.documents .cdk-focused,
.ctbox .mat-tab-nav-bar.mat-primary.documents .mat-tab-label-active {
  border-bottom: 6px solid var(--color-documents);
}
.ctbox .mat-tab-list.mat-primary.documents .cdk-focused.draft,
.ctbox .mat-tab-list.mat-primary.documents .mat-tab-label-active.draft,
.ctbox .mat-tab-nav-bar.mat-primary.documents .cdk-focused.draft,
.ctbox .mat-tab-nav-bar.mat-primary.documents .mat-tab-label-active.draft {
  border-bottom-color: var(--color-state-draft) !important;
}
.ctbox .mat-tab-list.mat-primary.documents .cdk-focused.review,
.ctbox .mat-tab-list.mat-primary.documents .mat-tab-label-active.review,
.ctbox .mat-tab-nav-bar.mat-primary.documents .cdk-focused.review,
.ctbox .mat-tab-nav-bar.mat-primary.documents .mat-tab-label-active.review {
  border-bottom-color: var(--color-state-review) !important;
}
.ctbox .mat-tab-list.mat-primary.documents .cdk-focused.completed,
.ctbox .mat-tab-list.mat-primary.documents .mat-tab-label-active.completed,
.ctbox .mat-tab-nav-bar.mat-primary.documents .cdk-focused.completed,
.ctbox .mat-tab-nav-bar.mat-primary.documents .mat-tab-label-active.completed {
  border-bottom-color: var(--color-state-completed) !important;
}
.ctbox .mat-tab-list.mat-primary.documents .cdk-focused.signing,
.ctbox .mat-tab-list.mat-primary.documents .mat-tab-label-active.signing,
.ctbox .mat-tab-nav-bar.mat-primary.documents .cdk-focused.signing,
.ctbox .mat-tab-nav-bar.mat-primary.documents .mat-tab-label-active.signing {
  border-bottom-color: var(--color-state-signing) !important;
}
.ctbox .mat-tab-list.mat-primary.documents .cdk-focused.signed,
.ctbox .mat-tab-list.mat-primary.documents .mat-tab-label-active.signed,
.ctbox .mat-tab-nav-bar.mat-primary.documents .cdk-focused.signed,
.ctbox .mat-tab-nav-bar.mat-primary.documents .mat-tab-label-active.signed {
  border-bottom-color: var(--color-state-signed) !important;
}
.ctbox .mat-tab-list.mat-primary.templates .mat-tab-label-active,
.ctbox .mat-tab-nav-bar.mat-primary.templates .mat-tab-label-active {
  border-bottom: 6px solid var(--color-templates);
}
.ctbox .mat-tab-list.mat-primary.clauses .mat-tab-label-active,
.ctbox .mat-tab-nav-bar.mat-primary.clauses .mat-tab-label-active {
  border-bottom: 6px solid var(--color-clauses);
}
.ctbox .mat-tab-list.mat-primary.administration .mat-tab-label-active,
.ctbox .mat-tab-nav-bar.mat-primary.administration .mat-tab-label-active {
  border-bottom: 6px solid var(--color-administration);
}
.ctbox .mat-tab-list.menu, .ctbox .mat-tab-list.landing-tabs,
.ctbox .mat-tab-nav-bar.menu,
.ctbox .mat-tab-nav-bar.landing-tabs {
  border-bottom: none;
  overflow: visible;
  padding: 0;
}
.ctbox .mat-tab-list.menu.ctbox, .ctbox .mat-tab-list.landing-tabs.ctbox,
.ctbox .mat-tab-nav-bar.menu.ctbox,
.ctbox .mat-tab-nav-bar.landing-tabs.ctbox {
  background-color: var(--color-blue-700);
}
.ctbox .mat-tab-list.menu .disabled, .ctbox .mat-tab-list.landing-tabs .disabled,
.ctbox .mat-tab-nav-bar.menu .disabled,
.ctbox .mat-tab-nav-bar.landing-tabs .disabled {
  opacity: 0.5 !important;
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link, .ctbox .mat-tab-list.landing-tabs .mat-tab-links .mat-tab-label,
.ctbox .mat-tab-list.landing-tabs .mat-tab-links .mat-tab-link,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link,
.ctbox .mat-tab-nav-bar.landing-tabs .mat-tab-links .mat-tab-label,
.ctbox .mat-tab-nav-bar.landing-tabs .mat-tab-links .mat-tab-link {
  color: var(--color-white);
  font-size: 16px;
  font-weight: 300;
  opacity: 1;
  text-decoration: none !important;
  border: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.22);
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label-active,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link-active, .ctbox .mat-tab-list.landing-tabs .mat-tab-links .mat-tab-label-active,
.ctbox .mat-tab-list.landing-tabs .mat-tab-links .mat-tab-link-active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label-active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link-active,
.ctbox .mat-tab-nav-bar.landing-tabs .mat-tab-links .mat-tab-label-active,
.ctbox .mat-tab-nav-bar.landing-tabs .mat-tab-links .mat-tab-link-active {
  background-color: var(--color-blue-800);
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.free,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.free, .ctbox .mat-tab-list.landing-tabs .mat-tab-links .mat-tab-label.free,
.ctbox .mat-tab-list.landing-tabs .mat-tab-links .mat-tab-link.free,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.free,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.free,
.ctbox .mat-tab-nav-bar.landing-tabs .mat-tab-links .mat-tab-label.free,
.ctbox .mat-tab-nav-bar.landing-tabs .mat-tab-links .mat-tab-link.free {
  background-color: var(--color-red-500);
  color: var(--color-white);
  margin-left: 80px;
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.free.active,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.free.active, .ctbox .mat-tab-list.landing-tabs .mat-tab-links .mat-tab-label.free.active,
.ctbox .mat-tab-list.landing-tabs .mat-tab-links .mat-tab-link.free.active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.free.active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.free.active,
.ctbox .mat-tab-nav-bar.landing-tabs .mat-tab-links .mat-tab-label.free.active,
.ctbox .mat-tab-nav-bar.landing-tabs .mat-tab-links .mat-tab-link.free.active {
  background-color: var(--color-red-500);
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label:hover,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link:hover, .ctbox .mat-tab-list.landing-tabs .mat-tab-links .mat-tab-label:hover,
.ctbox .mat-tab-list.landing-tabs .mat-tab-links .mat-tab-link:hover,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label:hover,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link:hover,
.ctbox .mat-tab-nav-bar.landing-tabs .mat-tab-links .mat-tab-label:hover,
.ctbox .mat-tab-nav-bar.landing-tabs .mat-tab-links .mat-tab-link:hover {
  background-color: var(--color-blue-400);
}
.ctbox .mat-tab-list.menu .mat-ink-bar, .ctbox .mat-tab-list.landing-tabs .mat-ink-bar,
.ctbox .mat-tab-nav-bar.menu .mat-ink-bar,
.ctbox .mat-tab-nav-bar.landing-tabs .mat-ink-bar {
  background-color: inherit;
  height: 0;
}
.ctbox .mat-tab-list.menu.Small, .ctbox .mat-tab-list.menu.XSmall, .ctbox .mat-tab-list.landing-tabs.Small, .ctbox .mat-tab-list.landing-tabs.XSmall,
.ctbox .mat-tab-nav-bar.menu.Small,
.ctbox .mat-tab-nav-bar.menu.XSmall,
.ctbox .mat-tab-nav-bar.landing-tabs.Small,
.ctbox .mat-tab-nav-bar.landing-tabs.XSmall {
  width: 100%;
}
.ctbox .mat-tab-list.menu.Small .mat-tab-links, .ctbox .mat-tab-list.menu.XSmall .mat-tab-links, .ctbox .mat-tab-list.landing-tabs.Small .mat-tab-links, .ctbox .mat-tab-list.landing-tabs.XSmall .mat-tab-links,
.ctbox .mat-tab-nav-bar.menu.Small .mat-tab-links,
.ctbox .mat-tab-nav-bar.menu.XSmall .mat-tab-links,
.ctbox .mat-tab-nav-bar.landing-tabs.Small .mat-tab-links,
.ctbox .mat-tab-nav-bar.landing-tabs.XSmall .mat-tab-links {
  flex-direction: column;
  justify-content: center;
}
.ctbox .mat-tab-list.menu.Small .mat-tab-links .free, .ctbox .mat-tab-list.menu.XSmall .mat-tab-links .free, .ctbox .mat-tab-list.landing-tabs.Small .mat-tab-links .free, .ctbox .mat-tab-list.landing-tabs.XSmall .mat-tab-links .free,
.ctbox .mat-tab-nav-bar.menu.Small .mat-tab-links .free,
.ctbox .mat-tab-nav-bar.menu.XSmall .mat-tab-links .free,
.ctbox .mat-tab-nav-bar.landing-tabs.Small .mat-tab-links .free,
.ctbox .mat-tab-nav-bar.landing-tabs.XSmall .mat-tab-links .free {
  margin-left: 0;
}
.ctbox .mat-tab-list.menu,
.ctbox .mat-tab-nav-bar.menu {
  width: 100vw;
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link {
  min-height: 32px;
  max-height: 40px;
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.tab,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.tab,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.tab,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.tab {
  border-right: 1px solid rgba(255, 255, 255, 0.22);
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.tab-dashboard,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.tab-dashboard,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.tab-dashboard,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.tab-dashboard {
  background-color: var(--color-dashboard);
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.tab-dashboard.active, .ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.tab-dashboard:hover,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.tab-dashboard.active,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.tab-dashboard:hover,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.tab-dashboard.active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.tab-dashboard:hover,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.tab-dashboard.active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.tab-dashboard:hover {
  background-color: var(--color-dashboard);
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.tab-contracts.active, .ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.tab-contracts:hover,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.tab-contracts.active,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.tab-contracts:hover,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.tab-contracts.active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.tab-contracts:hover,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.tab-contracts.active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.tab-contracts:hover {
  background-color: var(--color-documents);
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.tab-templates.active, .ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.tab-templates:hover,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.tab-templates.active,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.tab-templates:hover,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.tab-templates.active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.tab-templates:hover,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.tab-templates.active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.tab-templates:hover {
  background-color: var(--color-templates);
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.tab-clauses.active, .ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.tab-clauses:hover,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.tab-clauses.active,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.tab-clauses:hover,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.tab-clauses.active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.tab-clauses:hover,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.tab-clauses.active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.tab-clauses:hover {
  background-color: var(--color-clauses);
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.tab-administration.ctbox,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.tab-administration.ctbox,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.tab-administration.ctbox,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.tab-administration.ctbox {
  position: absolute;
  right: 218px;
  border-left: 1px solid rgba(255, 255, 255, 0.22);
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.tab-administration.ctbox.active, .ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.tab-administration.ctbox:hover,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.tab-administration.ctbox.active,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.tab-administration.ctbox:hover,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.tab-administration.ctbox.active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.tab-administration.ctbox:hover,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.tab-administration.ctbox.active,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.tab-administration.ctbox:hover {
  background-color: var(--color-administration);
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.logo,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.logo,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.logo,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.logo {
  background-color: var(--color-text);
  height: 40px;
  position: absolute;
  right: 0;
  width: 218px;
}
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.logo.Small, .ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-label.logo.XSmall,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.logo.Small,
.ctbox .mat-tab-list.menu .mat-tab-links .mat-tab-link.logo.XSmall,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.logo.Small,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-label.logo.XSmall,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.logo.Small,
.ctbox .mat-tab-nav-bar.menu .mat-tab-links .mat-tab-link.logo.XSmall {
  display: none;
}
.ctbox .mat-tab-list.menu.Medium .mat-tab-links, .ctbox .mat-tab-list.menu.Small .mat-tab-links, .ctbox .mat-tab-list.menu.XSmall .mat-tab-links,
.ctbox .mat-tab-nav-bar.menu.Medium .mat-tab-links,
.ctbox .mat-tab-nav-bar.menu.Small .mat-tab-links,
.ctbox .mat-tab-nav-bar.menu.XSmall .mat-tab-links {
  justify-content: space-between;
}
.ctbox .mat-tab-list.menu.Medium .mat-tab-links .mat-tab-label,
.ctbox .mat-tab-list.menu.Medium .mat-tab-links .mat-tab-link, .ctbox .mat-tab-list.menu.Small .mat-tab-links .mat-tab-label,
.ctbox .mat-tab-list.menu.Small .mat-tab-links .mat-tab-link, .ctbox .mat-tab-list.menu.XSmall .mat-tab-links .mat-tab-label,
.ctbox .mat-tab-list.menu.XSmall .mat-tab-links .mat-tab-link,
.ctbox .mat-tab-nav-bar.menu.Medium .mat-tab-links .mat-tab-label,
.ctbox .mat-tab-nav-bar.menu.Medium .mat-tab-links .mat-tab-link,
.ctbox .mat-tab-nav-bar.menu.Small .mat-tab-links .mat-tab-label,
.ctbox .mat-tab-nav-bar.menu.Small .mat-tab-links .mat-tab-link,
.ctbox .mat-tab-nav-bar.menu.XSmall .mat-tab-links .mat-tab-label,
.ctbox .mat-tab-nav-bar.menu.XSmall .mat-tab-links .mat-tab-link {
  min-width: auto;
  flex: 1;
}
.ctbox .mat-tab-list.menu.Medium .mat-tab-links .mat-tab-label.logo, .ctbox .mat-tab-list.menu.Medium .mat-tab-links .mat-tab-label.tab-administration,
.ctbox .mat-tab-list.menu.Medium .mat-tab-links .mat-tab-link.logo,
.ctbox .mat-tab-list.menu.Medium .mat-tab-links .mat-tab-link.tab-administration, .ctbox .mat-tab-list.menu.Small .mat-tab-links .mat-tab-label.logo, .ctbox .mat-tab-list.menu.Small .mat-tab-links .mat-tab-label.tab-administration,
.ctbox .mat-tab-list.menu.Small .mat-tab-links .mat-tab-link.logo,
.ctbox .mat-tab-list.menu.Small .mat-tab-links .mat-tab-link.tab-administration, .ctbox .mat-tab-list.menu.XSmall .mat-tab-links .mat-tab-label.logo, .ctbox .mat-tab-list.menu.XSmall .mat-tab-links .mat-tab-label.tab-administration,
.ctbox .mat-tab-list.menu.XSmall .mat-tab-links .mat-tab-link.logo,
.ctbox .mat-tab-list.menu.XSmall .mat-tab-links .mat-tab-link.tab-administration,
.ctbox .mat-tab-nav-bar.menu.Medium .mat-tab-links .mat-tab-label.logo,
.ctbox .mat-tab-nav-bar.menu.Medium .mat-tab-links .mat-tab-label.tab-administration,
.ctbox .mat-tab-nav-bar.menu.Medium .mat-tab-links .mat-tab-link.logo,
.ctbox .mat-tab-nav-bar.menu.Medium .mat-tab-links .mat-tab-link.tab-administration,
.ctbox .mat-tab-nav-bar.menu.Small .mat-tab-links .mat-tab-label.logo,
.ctbox .mat-tab-nav-bar.menu.Small .mat-tab-links .mat-tab-label.tab-administration,
.ctbox .mat-tab-nav-bar.menu.Small .mat-tab-links .mat-tab-link.logo,
.ctbox .mat-tab-nav-bar.menu.Small .mat-tab-links .mat-tab-link.tab-administration,
.ctbox .mat-tab-nav-bar.menu.XSmall .mat-tab-links .mat-tab-label.logo,
.ctbox .mat-tab-nav-bar.menu.XSmall .mat-tab-links .mat-tab-label.tab-administration,
.ctbox .mat-tab-nav-bar.menu.XSmall .mat-tab-links .mat-tab-link.logo,
.ctbox .mat-tab-nav-bar.menu.XSmall .mat-tab-links .mat-tab-link.tab-administration {
  position: relative !important;
  right: 0;
}
.ctbox .mat-tab-list.templates .mat-tab-link, .ctbox .mat-tab-list.documents .mat-tab-link,
.ctbox .mat-tab-nav-bar.templates .mat-tab-link,
.ctbox .mat-tab-nav-bar.documents .mat-tab-link {
  padding: 0 8px;
  min-height: 48px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
}
.ctbox .mat-tab-group.templates .mat-tab-label-active {
  border-bottom: 6px solid var(--color-templates);
}
.ctbox .mat-tab-group.clauses .mat-tab-label-active {
  border-bottom: 6px solid var(--color-clauses);
}
.ctbox .mat-tab-group.administration .mat-tab-label-active {
  border-bottom: 6px solid var(--color-administration);
}
.ctbox .mat-tab-group.documents .mat-tab-label-active {
  border-bottom: 6px solid var(--color-documents);
}
.ctbox .mat-tab-group.draft .mat-tab-label-active {
  border-bottom: 6px solid var(--color-state-draft);
}
.ctbox .mat-tab-group.review .mat-tab-label-active {
  border-bottom: 6px solid var(--color-state-review);
}
.ctbox .mat-tab-group.signing .mat-tab-label-active {
  border-bottom: 6px solid var(--color-state-signing);
}
.ctbox .mat-tab-group.signed .mat-tab-label-active {
  border-bottom: 6px solid var(--color-state-signed);
}
.ctbox .mat-tab-group.completed .mat-tab-label-active {
  border-bottom: 6px solid var(--color-state-completed);
}
.ctbox .mat-tab-group.santander {
  border-bottom: 6px solid var(--color-santander);
}
.ctbox .mat-tab-group.santander .mat-tab-label-active {
  border-bottom: 6px solid var(--color-santander-dark);
}
.ctbox .mat-tab-group.c-help .mat-tab-label {
  height: 48px;
  padding: 0 8px;
  min-width: 50px;
}
.ctbox .mat-tab-group.c-help .mat-tab-header {
  height: 48px;
}
.ctbox .mat-tab-group.c-help .mat-tab-body-wrapper {
  overflow: auto;
  height: calc(100% - 48px);
  max-height: 56vh;
}
.ctbox .mat-tree {
  width: inherit;
  display: flex;
  flex-direction: column;
}
.ctbox .mat-tree-node {
  user-select: none;
  width: inherit;
  max-width: 100%;
  gap: 8px;
  padding-right: 12px;
}
.ctbox .mat-tree-node:hover {
  background-color: var(--tree-node-selected-background-color) !important;
}
.ctbox .mat-tree-node.node-selected {
  background-color: var(--tree-node-selected-background-color);
}
.ctbox .mat-tree-node.node-selected .tree-node-text {
  text-decoration: none;
}
.ctbox .mat-tree-node.template, .ctbox .mat-tree-node.smartform {
  flex: initial;
}
.ctbox .mat-tree-node.menu {
  max-width: calc(100% - 40px);
}
.ctbox .mat-tree.ctbox-elevation {
  padding: 24px;
}
.ctbox .mat-tree.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.ctbox .mat-tree.cmc {
  background-color: var(--tree-background-color);
}
.ctbox .mat-tree.cmc .mat-tree-node.node-selected {
  background-color: var(--color-white) !important;
  color: var(--color-info) !important;
}
.ctbox .mat-tree.cmc .mat-tree-node.node-selected .tree-node-text {
  background-color: var(--color-white) !important;
  color: var(--color-info) !important;
  font-weight: 400;
  text-decoration: none;
}
.ctbox .mat-tree.cmc .mat-tree-node.node-selected .tree-node-text > span {
  background-color: white !important;
}
.ctbox .mat-tree.cmc .mat-tree-node.node-selected .tree-node-text:hover {
  color: var(--color-text) !important;
}
.ctbox .mat-tree.cmc .mat-tree-node:hover .tree-node-text {
  color: var(--color-text) !important;
}
.ctbox .mat-tree.cmc.inquire {
  background-color: var(--background-color-inquire) !important;
  padding-top: 10px;
  margin-top: -10px;
  height: 105%;
}
.ctbox .mat-tree.tree-filter {
  background-color: var(--tree-background-color);
}
.ctbox .mat-tree.tree-filter-inquire {
  background-color: var(--background-color-inquire) !important;
  padding-top: 10px;
  margin-top: -10px;
  height: 200%;
}
.ctbox .mat-tree.tree-filter .tree-node-icon .folder-icon {
  color: var(--tree-folder-icon-color);
}
.ctbox .mat-tree.tree-filter .tree-node-icon .expand-icon {
  color: var(--tree-folder-icon-color);
}
.ctbox .mat-tree.admin-cmc .ctbox-tree-actions.slider {
  margin-right: 24px;
}
.ctbox .mat-menu-item.save {
  height: 32px;
  line-height: 32px;
}
.ctbox .mat-menu-panel {
  min-height: auto;
  max-width: 100%;
}
.ctbox .mat-menu-panel.help-body .mat-menu-content:not(:empty) {
  padding-top: 0;
  padding-bottom: 0;
}
.ctbox .mat-menu-panel.landing-tabs {
  width: 90vw;
}
.ctbox .mat-menu-panel.landing-tabs .mat-menu-content:not(:empty) {
  padding-top: 0;
  padding-bottom: 0;
}
.ctbox .mat-menu-panel.document-state {
  max-width: 180px;
  overflow: hidden;
}
.ctbox .mat-menu-panel.document-state .mat-menu-content:not(:empty) {
  padding-top: 0;
  padding-bottom: 0;
}
.ctbox .mat-menu-panel.document-state .mat-menu-item {
  height: auto;
}
.ctbox .mat-menu-panel.document-state .mat-icon {
  margin-right: 0;
}
.ctbox .mat-menu-panel.filter-state .mat-menu-content {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
}
.ctbox .document-state {
  max-width: 180px;
  overflow: hidden;
}
.ctbox .document-state.mat-menu-trigger.disabled {
  pointer-events: none;
}
.ctbox .link-app {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
}
.ctbox .link-img {
  height: 24px !important;
  width: 24px !important;
}
.ctbox .link-text {
  display: block;
  color: var(--color-text);
}
.ctbox .user-data-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 296px;
  padding: 16px;
  text-align: center;
}
.ctbox .user-data-details .full-name,
.ctbox .user-data-details .email {
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: center;
}
.ctbox .actions-progress-bar {
  height: 32px;
  padding: 0 16px;
}
.ctbox .actions-progress-bar .progress-result {
  display: flex;
  gap: 16px;
}
.ctbox .actions-progress-bar .progress-done {
  font-weight: 400;
}
.ctbox .actions-progress-bar .mat-progress-bar.smart-forms {
  width: 100%;
  height: 10px;
  border-radius: 12px;
  margin: 6px 0;
}
.ctbox .wf-user-order.template .mat-badge-content {
  background-color: var(--color-white);
  border: 1px solid var(--color-template);
  color: var(--color-documents);
}
.ctbox .wf-user-order.document .mat-badge-content {
  background-color: var(--color-white);
  border: 1px solid var(--color-documents);
  color: var(--color-documents);
}
.ctbox .wf-user-order.blocked .mat-badge-content {
  background-color: var(--color-white);
  border: 1px solid var(--color-disabled);
  color: var(--color-disabled);
}
.ctbox .mat-badge-inline {
  display: inline-flex !important;
}
.ctbox .mat-badge-inline .mat-badge-content {
  font-weight: normal;
  position: initial !important;
}
.ctbox .mat-badge-content {
  color: var(--color-white);
}
.ctbox .mat-badge-large .mat-badge-content {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 0.75em;
  font-weight: normal;
  display: block !important;
  position: initial !important;
}
.ctbox .mat-badge-all .mat-badge-content {
  background-color: var(--color-white);
  border: 1px solid var(--color-documents);
  color: var(--color-documents);
}
.ctbox .mat-badge-draft .mat-badge-content {
  background-color: var(--color-state-draft) !important;
}
.ctbox .mat-badge-review .mat-badge-content {
  background-color: var(--color-state-review) !important;
}
.ctbox .mat-badge-completed .mat-badge-content {
  background-color: var(--color-state-completed) !important;
}
.ctbox .mat-badge-signing .mat-badge-content {
  background-color: var(--color-state-signing) !important;
}
.ctbox .mat-badge-signed .mat-badge-content {
  background-color: var(--color-state-signed) !important;
}
.ctbox .XL .mat-dialog-content {
  max-height: calc(85vh - 48px);
  min-height: calc(80vh - 55px);
}
.ctbox .M .mat-dialog-content {
  max-height: calc(50vh - 48px);
  min-height: calc(50vh - 44px);
}
.ctbox .mat-dialog-container {
  padding: 16px;
  /* redimensionar modales */
  overflow: auto;
}
.ctbox .mat-dialog-header {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.ctbox .mat-dialog-title {
  margin: 0;
  padding: 0;
}
.ctbox .mat-dialog-close {
  margin: 0;
  padding: 0;
}
.ctbox .mat-dialog-content {
  margin: 0 -16px;
  padding: 0 16px;
  max-height: calc(80vh - 48px);
}
.ctbox .mat-dialog-content.border {
  border: var(--border-default);
}
.ctbox .mat-dialog-content.warning {
  min-width: 30vw;
}
.ctbox .mat-dialog-content.no-actions {
  height: calc(100% - 64px) !important;
  min-height: calc(100% - 28px) !important;
  max-height: initial !important;
}
.ctbox .mat-dialog-content.not-overflow {
  overflow: hidden;
}
.ctbox .mat-dialog-content.tree, .ctbox .mat-dialog-content.tree-modal {
  border: var(--border-default);
  height: inherit;
  height: 62vh;
  min-height: 62vh;
  max-height: 62vh;
  margin: 0;
  padding: 0;
}
.ctbox .mat-dialog-actions {
  justify-content: flex-end;
  margin: 0;
  min-height: 32px;
  gap: 16px;
}
.ctbox .generic-dialog-block {
  display: block;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}
.ctbox .generic-dialog-content-inherit {
  display: block;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}
.ctbox .save-as {
  display: flex;
  flex-direction: column;
}
.ctbox .save-as-version {
  padding: 8px 0 32px 0;
}
.ctbox .save-as-name {
  padding: 8px 0 16px;
  margin: 0 32px;
}
.ctbox .save-as-tree {
  border: none;
  height: 63vh;
}
.ctbox .save-as-tree-search-box {
  display: block;
  height: 32px;
}
.ctbox .save-as .ctbox-tree {
  border: none;
  height: calc(63vh - 32px);
  max-height: inherit !important;
  overflow: auto;
}
.ctbox .mat-expansion-panel.workflow:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: transparent;
}
.ctbox .mat-expansion-panel.workflow .mat-expansion-panel-header {
  color: var(--color-info);
  padding: 0 2px;
}
.ctbox .mat-expansion-panel.workflow .mat-expansion-panel-header.mat-expanded {
  max-height: 32px;
}
.ctbox .mat-expansion-panel.workflow .mat-content::before {
  content: "";
  border-bottom: 1px solid var(--color-info);
  bottom: 50%;
  position: absolute;
  width: 100%;
}
.ctbox .mat-expansion-panel.workflow .mat-expansion-panel-header-title {
  color: var(--color-info);
}
.ctbox .mat-expansion-panel.workflow .mat-expansion-panel-header-title label {
  background: var(--color-white);
  padding-right: 16px;
  z-index: 1;
}
.ctbox .mat-expansion-panel.workflow .mat-expansion-indicator {
  background: var(--color-white);
  z-index: 1;
}
.ctbox .mat-expansion-panel.workflow .mat-expansion-indicator::after {
  color: var(--color-info);
}
.ctbox .mat-expansion-panel.workflow .mat-expansion-panel-body {
  padding: 0;
}
.ctbox .mat-expansion-panel.list-clauses {
  border-bottom: var(--border-default);
}
.ctbox .mat-expansion-panel.list-clauses.mat-expansion-panel-spacing {
  margin: 0;
}
.ctbox .mat-expansion-panel.list-clauses .mat-expansion-panel-header-title {
  color: var(--color-default);
}
.ctbox .mat-expansion-panel.list-clauses .mat-expansion-panel-header-description {
  padding: 12px;
  line-height: 24px;
}
.ctbox .mat-button,
.ctbox .mat-icon-button,
.ctbox .mat-raised-button,
.ctbox .mat-flat-button {
  line-height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 0;
}
.ctbox .mat-button.mat-icon-button-left,
.ctbox .mat-icon-button.mat-icon-button-left,
.ctbox .mat-raised-button.mat-icon-button-left,
.ctbox .mat-flat-button.mat-icon-button-left {
  padding: 0;
}
.ctbox .mat-button.mat-icon-button-right,
.ctbox .mat-icon-button.mat-icon-button-right,
.ctbox .mat-raised-button.mat-icon-button-right,
.ctbox .mat-flat-button.mat-icon-button-right {
  padding: 0;
}
.ctbox .mat-button-icon,
.ctbox .mat-icon-button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  min-width: 32px !important;
  padding: 0;
}
.ctbox .mat-button-icon.Small, .ctbox .mat-button-icon.XSmall,
.ctbox .mat-icon-button.Small,
.ctbox .mat-icon-button.XSmall {
  width: 38px;
  height: 38px;
}
.ctbox .mat-button-icon.xsmall,
.ctbox .mat-icon-button.xsmall {
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  min-width: 20px !important;
  min-height: 20px !important;
  font-size: 20px !important;
}
.ctbox .mat-button-icon[class*=mat-calendar],
.ctbox .mat-icon-button[class*=mat-calendar] {
  width: 40px;
  height: 40px;
  line-height: 40px;
  min-width: 40px !important;
}
.ctbox .mat-button-icon.hidden,
.ctbox .mat-icon-button.hidden {
  display: var(--display-go-back);
}
.ctbox .mat-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ctbox .mat-icon svg {
  max-width: 18px;
  max-height: 24px;
}
.ctbox .mat-icon,
.ctbox .material-symbols-outlined {
  font-family: var(--font-family-icons);
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}
.ctbox .mat-icon.filled,
.ctbox .material-symbols-outlined.filled {
  font-variation-settings: "FILL" 1;
}
.ctbox .mat-icon.xxs,
.ctbox .material-symbols-outlined.xxs {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  font-size: 14px !important;
}
.ctbox .mat-icon.small,
.ctbox .material-symbols-outlined.small {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  font-size: 16px !important;
}
.ctbox .mat-icon.xsmall,
.ctbox .material-symbols-outlined.xsmall {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  font-size: 20px !important;
}
.ctbox .mat-icon.medium,
.ctbox .material-symbols-outlined.medium {
  min-width: 36px;
  min-height: 36px;
  font-size: 36px !important;
}
.ctbox .mat-icon.big,
.ctbox .material-symbols-outlined.big {
  min-width: 48px;
  min-height: 48px;
  font-size: 48px !important;
}
.ctbox .mat-icon.xl,
.ctbox .material-symbols-outlined.xl {
  min-width: 56px;
  min-height: 56px;
  font-size: 56px !important;
}
.ctbox .mat-icon.state,
.ctbox .material-symbols-outlined.state {
  width: 32px;
  height: 32px;
  background-color: transparent !important;
}
.ctbox .mat-icon.title,
.ctbox .material-symbols-outlined.title {
  font-size: 32px !important;
}
.ctbox .mat-icon.warning,
.ctbox .material-symbols-outlined.warning {
  color: var(--color-warning);
}
.ctbox .mat-icon.info,
.ctbox .material-symbols-outlined.info {
  color: var(--color-info);
}
.ctbox .mat-icon.done, .ctbox .mat-icon.true,
.ctbox .material-symbols-outlined.done,
.ctbox .material-symbols-outlined.true {
  color: var(--color-success);
}
.ctbox .mat-icon.error, .ctbox .mat-icon.false,
.ctbox .material-symbols-outlined.error,
.ctbox .material-symbols-outlined.false {
  color: var(--color-error);
}
.ctbox .mat-icon.draft,
.ctbox .material-symbols-outlined.draft {
  color: var(--color-state-draft) !important;
}
.ctbox .mat-icon.review,
.ctbox .material-symbols-outlined.review {
  color: var(--color-state-review) !important;
}
.ctbox .mat-icon.completed,
.ctbox .material-symbols-outlined.completed {
  color: var(--color-state-completed) !important;
}
.ctbox .mat-icon.signing,
.ctbox .material-symbols-outlined.signing {
  color: var(--color-state-signing) !important;
}
.ctbox .mat-icon.signed,
.ctbox .material-symbols-outlined.signed {
  color: var(--color-state-signed) !important;
}
.ctbox .mat-icon.template-clause.cmc,
.ctbox .material-symbols-outlined.template-clause.cmc {
  border-radius: 100%;
  border-right-color: var(--color-santander-dark);
  border-top-color: var(--color-disabled);
  border-bottom-color: var(--color-santander-dark);
  border-left-color: var(--color-disabled);
  border-width: 12px;
  border-style: solid;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
}
.ctbox .mat-icon.template-document,
.ctbox .material-symbols-outlined.template-document {
  border-radius: 100%;
  border-right-color: var(--color-templates);
  border-top-color: var(--color-documents);
  border-bottom-color: var(--color-templates);
  border-left-color: var(--color-documents);
  border-width: 10px;
  border-style: solid;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
}
.ctbox .mat-icon.type,
.ctbox .material-symbols-outlined.type {
  font-family: var(--font-family-sans-serif) !important;
  font-size: 0;
}
.ctbox .mat-icon.type:before,
.ctbox .material-symbols-outlined.type:before {
  align-content: center;
  align-items: center;
  background-color: var(--color-disabled);
  border-radius: 50%;
  color: var(--color-white);
  content: " ";
  display: flex;
  justify-content: center;
  font-size: 12px;
  height: 22px;
  width: 22px;
}
.ctbox .mat-icon.type.document:before,
.ctbox .material-symbols-outlined.type.document:before {
  background-color: var(--color-documents);
  content: "D";
}
.ctbox .mat-icon.type.template:before,
.ctbox .material-symbols-outlined.type.template:before {
  background-color: var(--color-templates);
  content: "P";
}
.ctbox .mat-icon.type.template.cmc:before,
.ctbox .material-symbols-outlined.type.template.cmc:before {
  background-color: var(--color-disabled);
}
.ctbox .mat-icon.type.clause.cmc:before,
.ctbox .material-symbols-outlined.type.clause.cmc:before {
  background-color: var(--color-santander-dark);
  content: "C";
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-primary .mat-button-text-right,
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-primary .mat-button-text-left {
  background: #409bd2;
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-primary .mat-icon {
  background-color: #1c5477;
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-accent {
  background: #8bc34a;
  color: var(--color-white);
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-accent .mat-icon {
  background-color: #496a23;
  color: var(--color-white);
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-templates {
  background: var(--color-templates);
  color: var(--color-white);
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-templates .mat-icon {
  background-color: #243910;
  color: var(--color-white);
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-clauses {
  background: var(--color-clauses);
  color: var(--color-white);
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-clauses .mat-icon {
  background-color: #13463e;
  color: var(--color-white);
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-administration {
  background: var(--color-administration);
  color: var(--color-white);
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-administration .mat-icon {
  background-color: #72540b;
  color: var(--color-white);
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-warn, .ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-error {
  background: var(--color-error);
  color: var(--color-white);
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-warn .mat-icon, .ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-error .mat-icon {
  background-color: #a21309;
  color: var(--color-white);
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-warn .mat-icon svg, .ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-error .mat-icon svg {
  fill: inherit;
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-santander {
  background: var(--color-santander);
  color: var(--color-white);
}
.ctbox .mat-flat-button:not(.ctbox .mat-flat-button.mat-button-disabled).mat-santander .mat-icon {
  color: var(--color-white);
  background-color: var(--color-santander-dark);
}
.ctbox .mat-stroked-button {
  border-radius: 0;
  line-height: 30px;
  min-width: 30px;
}
.ctbox .mat-stroked-button:not(.ctbox .mat-stroked-button.mat-button-disabled).mat-primary {
  border-color: #409bd2;
  background: var(--color-white);
  color: #409bd2;
}
.ctbox .mat-stroked-button:not(.ctbox .mat-stroked-button.mat-button-disabled).mat-primary .mat-icon {
  background-color: #409bd2;
  color: var(--color-white);
}
.ctbox .mat-stroked-button:not(.ctbox .mat-stroked-button.mat-button-disabled).mat-accent {
  border-color: #8bc34a;
}
.ctbox .mat-stroked-button:not(.ctbox .mat-stroked-button.mat-button-disabled).mat-accent .mat-icon {
  background-color: #496a23;
  color: var(--color-white);
}
.ctbox .mat-stroked-button:not(.ctbox .mat-stroked-button.mat-button-disabled).mat-warn {
  border-color: #f44336;
}
.ctbox .mat-stroked-button:not(.ctbox .mat-stroked-button.mat-button-disabled).mat-warn .mat-icon {
  background-color: #a21309;
  color: var(--color-white);
}
.ctbox .mat-stroked-button:not(.ctbox .mat-stroked-button.mat-button-disabled).mat-templates {
  border-color: var(--color-templates);
  color: var(--color-templates);
}
.ctbox .mat-stroked-button:not(.ctbox .mat-stroked-button.mat-button-disabled).mat-templates.mat-icon-button-left .mat-icon {
  border-right: 1px solid var(--color-templates);
  color: var(--color-templates);
}
.ctbox .mat-stroked-button:not(.ctbox .mat-stroked-button.mat-button-disabled).mat-clauses {
  border-color: var(--color-clauses);
  color: var(--color-clauses);
}
.ctbox .mat-stroked-button:not(.ctbox .mat-stroked-button.mat-button-disabled).mat-clauses.mat-icon-button-left .mat-icon {
  border-right: 1px solid var(--color-clauses);
  color: var(--color-clauses);
}
.ctbox .mat-stroked-button:not(.ctbox .mat-stroked-button.mat-button-disabled).mat-administration {
  border-color: var(--color-administration);
  color: var(--color-administration);
}
.ctbox .mat-stroked-button:not(.ctbox .mat-stroked-button.mat-button-disabled).mat-administration.mat-icon-button-left .mat-icon {
  border-right: 1px solid var(--color-administration);
  color: var(--color-administration);
}
.ctbox .mat-stroked-button.mat-permission-discard {
  border-radius: 8px;
  border-color: var(--color-error);
  color: var(--color-error);
  font-size: 12px;
  line-height: 16px;
  min-height: 20px;
}
.ctbox .mat-stroked-button.mat-permission-discard:hover {
  background-color: var(--color-error);
  color: var(--color-white);
}
.ctbox .mat-stroked-button.mat-permission-assign {
  border-radius: 8px;
  border-color: var(--color-success);
  color: var(--color-success);
  font-size: 12px;
  line-height: 16px;
  min-height: 20px;
}
.ctbox .mat-stroked-button.mat-permission-assign:hover {
  background-color: var(--color-success);
  color: var(--color-white);
}
.ctbox .mat-stroked-button .mat-icon {
  height: 30px;
  width: 30px;
}
.ctbox .mat-button-wrapper {
  display: flex;
  align-items: center;
}
.ctbox .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  line-height: 24px !important;
  height: 24px !important;
  width: 24px !important;
  max-width: 24px !important;
  min-width: 24px !important;
  max-height: 24px !important;
  min-height: 24px !important;
}
.ctbox .mat-rrss {
  background-color: transparent;
  color: var(--color-white);
}
.ctbox .mat-info {
  color: var(--color-info);
}
.ctbox .mat-success {
  color: var(--color-success);
}
.ctbox .mat-success.border {
  border: 1px solid var(--color-success);
}
.ctbox .mat-delete {
  color: var(--color-delete);
}
.ctbox .mat-state {
  border: var(--border-default);
}
.ctbox .mat-state.no-border {
  border-color: transparent;
}
.ctbox .mat-datepicker-close-button::before {
  content: "\e5cd";
  display: block;
  font-family: var(--font-family-icons);
  font-size: 24px;
  line-height: 36px;
}
.ctbox .mat-datepicker-close-button .mat-button-wrapper {
  display: none;
}
.ctbox .mat-button-focus-overlay {
  background: transparent;
}
.ctbox .mat-button-disabled,
.ctbox .disabled {
  cursor: not-allowed;
  pointer-events: none !important;
  border-color: var(--color-disabled) !important;
}
.ctbox .mat-button-disabled.mat-icon-button-right .mat-icon, .ctbox .mat-button-disabled.mat-icon-button-left .mat-icon,
.ctbox .disabled.mat-icon-button-right .mat-icon,
.ctbox .disabled.mat-icon-button-left .mat-icon {
  background-color: var(--background-color-disabled);
}
.ctbox .mat-button-text-right,
.ctbox .mat-button-text-left {
  padding: 0 16px;
}
.ctbox .xl .mat-button-text-left,
.ctbox .xl .mat-button-text-right {
  font-size: 30px;
}
.ctbox .text-default *[class*=mat-button-text] {
  color: var(--color-text);
}
.ctbox .cursor-default {
  cursor: default;
}
.ctbox .mat-account {
  color: var(--color-white);
}
.ctbox .mat-account .mat-button-wrapper {
  justify-content: center;
}
.ctbox .mat-account-login {
  background-color: var(--color-blue-300);
}
.ctbox .mat-account-login:hover {
  background-color: var(--color-blue-700) !important;
}
.ctbox .mat-account-admin {
  background-color: var(--color-blue-700);
}
.ctbox .mat-account-admin:hover {
  background-color: var(--color-blue-300) !important;
}
.ctbox .mat-account-logout {
  background-color: var(--color-gray-300);
  color: var(--color-gray-700);
}
.ctbox .mat-account-logout:hover {
  background-color: var(--color-gray-700) !important;
  color: var(--color-white);
}
.ctbox .border-left {
  border-left: var(--border-default) !important;
}
.ctbox .mat-menu-item {
  height: 32px;
}
.ctbox .mat-slider.three-states {
  cursor: default;
  width: 48px;
  max-height: 22px;
  min-width: 48px !important;
  max-width: 48px !important;
  padding: 0;
}
.ctbox .mat-slider.three-states .mat-slide-toggle-content {
  padding: 0;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.ctbox .mat-slider.three-states.mat-slider-disabled {
  opacity: 0.38;
}
.ctbox .mat-slider.three-states .mat-slider-wrapper {
  border-radius: 16px;
  min-height: 22px !important;
  min-width: 48px !important;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
}
.ctbox .mat-slider.three-states .mat-slider-wrapper .mat-slider-track-wrapper {
  align-items: center;
  background-color: var(--color-gray-300);
  display: flex;
  min-height: 22px;
}
.ctbox .mat-slider.three-states .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-fill,
.ctbox .mat-slider.three-states .mat-slider-wrapper .mat-slider-track-wrapper .mat-slider-track-background {
  display: none;
}
.ctbox .mat-slider.three-states .mat-slider-wrapper .mat-slider-track-wrapper::before, .ctbox .mat-slider.three-states .mat-slider-wrapper .mat-slider-track-wrapper::after {
  font-family: var(--font-family-icons);
  font-size: 15px;
  color: var(--color-white);
  text-align: center;
  width: 48px;
}
.ctbox .mat-slider.three-states .mat-slider-wrapper .mat-slider-track-wrapper::before {
  content: "\e5cd";
}
.ctbox .mat-slider.three-states .mat-slider-wrapper .mat-slider-track-wrapper::after {
  content: "\e5ca";
}
.ctbox .mat-slider.three-states .mat-slider-wrapper .mat-slider-ticks-container {
  display: none;
}
.ctbox .mat-slider.three-states .mat-slider-wrapper .mat-slider-thumb-container {
  min-height: 22px;
  min-width: 48px;
  top: 0;
}
.ctbox .mat-slider.three-states .mat-slider-wrapper .mat-slider-thumb {
  color: var(--color-white);
  background-color: var(--color-white);
  border: none;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  top: 3px;
  right: -8px;
  height: 16px !important;
  width: 16px !important;
  transform: unset !important;
}
.ctbox .mat-slider.three-states .mat-slider-wrapper .mat-slider-thumb::before {
  border-radius: 16px;
  content: "";
  font-family: var(--font-family-icons);
  font-size: 15px;
}
.ctbox .mat-slider.three-states.state-true .mat-slider-thumb-container .mat-slider-thumb {
  right: 4px;
  background-color: var(--color-success);
}
.ctbox .mat-slider.three-states.state-true .mat-slider-thumb-container .mat-slider-thumb::before {
  content: "\e5ca";
}
.ctbox .mat-slider.three-states.state-false .mat-slider-thumb-container .mat-slider-thumb {
  right: -20px;
  background-color: var(--color-error);
}
.ctbox .mat-slider.three-states.state-false .mat-slider-thumb-container .mat-slider-thumb::before {
  content: "\e5cd";
}
.ctbox .mat-slider.three-states.state-none .mat-slider-thumb-container .mat-slider-thumb {
  right: -8px;
}
.ctbox .mat-slider.three-states.state-disabled .mat-slider-thumb-container .mat-slider-thumb {
  background-color: var(--color-white);
}
.ctbox .mat-slide-toggle {
  height: 32px;
}
.ctbox .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background: var(--color-gray-300);
}
.ctbox .mat-slide-toggle .mat-slide-toggle-bar {
  height: 22px;
  width: 48px;
  border-radius: 16px;
  background: var(--color-gray-300);
}
.ctbox .mat-slide-toggle .mat-slide-toggle-bar::before {
  content: "\e5cd";
  font-family: var(--font-family-icons);
  position: absolute;
  left: 0;
  color: var(--color-white);
  font-size: 15px;
  width: 24px;
  text-align: center;
}
.ctbox .mat-slide-toggle.neutro:not(.mat-checked) .mat-slide-toggle-thumb {
  background-color: var(--color-white);
}
.ctbox .mat-slide-toggle .mat-slide-toggle-thumb {
  height: 16px;
  width: 16px;
  background-color: var(--color-error);
  color: var(--color-white);
}
.ctbox .mat-slide-toggle .mat-slide-toggle-thumb-container {
  height: 22px;
  width: 22px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 16px;
}
.ctbox .mat-slide-toggle .mat-slide-toggle-thumb-container::before {
  content: "\e5cd";
  font-family: var(--font-family-icons);
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-white);
  font-size: 15px;
  width: 24px;
  text-align: center;
}
.ctbox .mat-slide-toggle .mat-slide-toggle-thumb-container::after {
  content: "\e5ca";
  font-family: var(--font-family-icons);
  position: absolute;
  top: 0;
  left: 24px;
  color: var(--color-white);
  font-size: 15px;
  width: 24px;
  text-align: center;
}
.ctbox .mat-slide-toggle .mat-slide-toggle-ripple {
  display: none;
}
.ctbox .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(24px, 0, 0);
}
.ctbox .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container::before {
  content: "\e5ca";
}
.ctbox .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container::after {
  content: "\e5ca";
  opacity: 0;
  display: none;
}
.ctbox .mat-slide-toggle.mat-disabled.help {
  opacity: 1 !important;
}
.ctbox .administration .mat-button,
.ctbox .administration .mat-icon-button,
.ctbox .administration .mat-raised-button,
.ctbox .administration .mat-flat-button {
  line-height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 0;
}
.ctbox .administration .mat-button.mat-icon-button-left,
.ctbox .administration .mat-icon-button.mat-icon-button-left,
.ctbox .administration .mat-raised-button.mat-icon-button-left,
.ctbox .administration .mat-flat-button.mat-icon-button-left {
  padding: 0;
}
.ctbox .administration .mat-button.mat-icon-button-right,
.ctbox .administration .mat-icon-button.mat-icon-button-right,
.ctbox .administration .mat-raised-button.mat-icon-button-right,
.ctbox .administration .mat-flat-button.mat-icon-button-right {
  padding: 0;
}
.ctbox .administration .mat-button-icon,
.ctbox .administration .mat-icon-button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  min-width: 32px !important;
  padding: 0;
}
.ctbox .administration .mat-button-icon.Small, .ctbox .administration .mat-button-icon.XSmall,
.ctbox .administration .mat-icon-button.Small,
.ctbox .administration .mat-icon-button.XSmall {
  width: 38px;
  height: 38px;
}
.ctbox .administration .mat-button-icon.xsmall,
.ctbox .administration .mat-icon-button.xsmall {
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  min-width: 20px !important;
  min-height: 20px !important;
  font-size: 20px !important;
}
.ctbox .administration .mat-button-icon[class*=mat-calendar],
.ctbox .administration .mat-icon-button[class*=mat-calendar] {
  width: 40px;
  height: 40px;
  line-height: 40px;
  min-width: 40px !important;
}
.ctbox .administration .mat-button-icon.hidden,
.ctbox .administration .mat-icon-button.hidden {
  display: var(--display-go-back);
}
.ctbox .administration .mat-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ctbox .administration .mat-icon svg {
  max-width: 18px;
  max-height: 24px;
}
.ctbox .administration .mat-icon,
.ctbox .administration .material-symbols-outlined {
  font-family: var(--font-family-icons);
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}
.ctbox .administration .mat-icon.filled,
.ctbox .administration .material-symbols-outlined.filled {
  font-variation-settings: "FILL" 1;
}
.ctbox .administration .mat-icon.xxs,
.ctbox .administration .material-symbols-outlined.xxs {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  font-size: 14px !important;
}
.ctbox .administration .mat-icon.small,
.ctbox .administration .material-symbols-outlined.small {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  font-size: 16px !important;
}
.ctbox .administration .mat-icon.xsmall,
.ctbox .administration .material-symbols-outlined.xsmall {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  font-size: 20px !important;
}
.ctbox .administration .mat-icon.medium,
.ctbox .administration .material-symbols-outlined.medium {
  min-width: 36px;
  min-height: 36px;
  font-size: 36px !important;
}
.ctbox .administration .mat-icon.big,
.ctbox .administration .material-symbols-outlined.big {
  min-width: 48px;
  min-height: 48px;
  font-size: 48px !important;
}
.ctbox .administration .mat-icon.xl,
.ctbox .administration .material-symbols-outlined.xl {
  min-width: 56px;
  min-height: 56px;
  font-size: 56px !important;
}
.ctbox .administration .mat-icon.state,
.ctbox .administration .material-symbols-outlined.state {
  width: 32px;
  height: 32px;
  background-color: transparent !important;
}
.ctbox .administration .mat-icon.title,
.ctbox .administration .material-symbols-outlined.title {
  font-size: 32px !important;
}
.ctbox .administration .mat-icon.warning,
.ctbox .administration .material-symbols-outlined.warning {
  color: var(--color-warning);
}
.ctbox .administration .mat-icon.info,
.ctbox .administration .material-symbols-outlined.info {
  color: var(--color-info);
}
.ctbox .administration .mat-icon.done, .ctbox .administration .mat-icon.true,
.ctbox .administration .material-symbols-outlined.done,
.ctbox .administration .material-symbols-outlined.true {
  color: var(--color-success);
}
.ctbox .administration .mat-icon.error, .ctbox .administration .mat-icon.false,
.ctbox .administration .material-symbols-outlined.error,
.ctbox .administration .material-symbols-outlined.false {
  color: var(--color-error);
}
.ctbox .administration .mat-icon.draft,
.ctbox .administration .material-symbols-outlined.draft {
  color: var(--color-state-draft) !important;
}
.ctbox .administration .mat-icon.review,
.ctbox .administration .material-symbols-outlined.review {
  color: var(--color-state-review) !important;
}
.ctbox .administration .mat-icon.completed,
.ctbox .administration .material-symbols-outlined.completed {
  color: var(--color-state-completed) !important;
}
.ctbox .administration .mat-icon.signing,
.ctbox .administration .material-symbols-outlined.signing {
  color: var(--color-state-signing) !important;
}
.ctbox .administration .mat-icon.signed,
.ctbox .administration .material-symbols-outlined.signed {
  color: var(--color-state-signed) !important;
}
.ctbox .administration .mat-icon.template-clause.cmc,
.ctbox .administration .material-symbols-outlined.template-clause.cmc {
  border-radius: 100%;
  border-right-color: var(--color-santander-dark);
  border-top-color: var(--color-disabled);
  border-bottom-color: var(--color-santander-dark);
  border-left-color: var(--color-disabled);
  border-width: 12px;
  border-style: solid;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
}
.ctbox .administration .mat-icon.template-document,
.ctbox .administration .material-symbols-outlined.template-document {
  border-radius: 100%;
  border-right-color: var(--color-templates);
  border-top-color: var(--color-documents);
  border-bottom-color: var(--color-templates);
  border-left-color: var(--color-documents);
  border-width: 10px;
  border-style: solid;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
}
.ctbox .administration .mat-icon.type,
.ctbox .administration .material-symbols-outlined.type {
  font-family: var(--font-family-sans-serif) !important;
  font-size: 0;
}
.ctbox .administration .mat-icon.type:before,
.ctbox .administration .material-symbols-outlined.type:before {
  align-content: center;
  align-items: center;
  background-color: var(--color-disabled);
  border-radius: 50%;
  color: var(--color-white);
  content: " ";
  display: flex;
  justify-content: center;
  font-size: 12px;
  height: 22px;
  width: 22px;
}
.ctbox .administration .mat-icon.type.document:before,
.ctbox .administration .material-symbols-outlined.type.document:before {
  background-color: var(--color-documents);
  content: "D";
}
.ctbox .administration .mat-icon.type.template:before,
.ctbox .administration .material-symbols-outlined.type.template:before {
  background-color: var(--color-templates);
  content: "P";
}
.ctbox .administration .mat-icon.type.template.cmc:before,
.ctbox .administration .material-symbols-outlined.type.template.cmc:before {
  background-color: var(--color-disabled);
}
.ctbox .administration .mat-icon.type.clause.cmc:before,
.ctbox .administration .material-symbols-outlined.type.clause.cmc:before {
  background-color: var(--color-santander-dark);
  content: "C";
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-primary .mat-button-text-right,
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-primary .mat-button-text-left {
  background: #e6aa16;
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-primary .mat-icon {
  background-color: #72540b;
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-accent {
  background: #a3a3a3;
  color: var(--color-white);
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-accent .mat-icon {
  background-color: #636363;
  color: var(--color-white);
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-templates {
  background: var(--color-templates);
  color: var(--color-white);
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-templates .mat-icon {
  background-color: #243910;
  color: var(--color-white);
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-clauses {
  background: var(--color-clauses);
  color: var(--color-white);
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-clauses .mat-icon {
  background-color: #13463e;
  color: var(--color-white);
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-administration {
  background: var(--color-administration);
  color: var(--color-white);
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-administration .mat-icon {
  background-color: #72540b;
  color: var(--color-white);
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-warn, .ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-error {
  background: var(--color-error);
  color: var(--color-white);
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-warn .mat-icon, .ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-error .mat-icon {
  background-color: #a21309;
  color: var(--color-white);
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-warn .mat-icon svg, .ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-error .mat-icon svg {
  fill: inherit;
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-santander {
  background: var(--color-santander);
  color: var(--color-white);
}
.ctbox .administration .mat-flat-button:not(.ctbox .administration .mat-flat-button.mat-button-disabled).mat-santander .mat-icon {
  color: var(--color-white);
  background-color: var(--color-santander-dark);
}
.ctbox .administration .mat-stroked-button {
  border-radius: 0;
  line-height: 30px;
  min-width: 30px;
}
.ctbox .administration .mat-stroked-button:not(.ctbox .administration .mat-stroked-button.mat-button-disabled).mat-primary {
  border-color: #e6aa16;
  background: var(--color-white);
  color: #e6aa16;
}
.ctbox .administration .mat-stroked-button:not(.ctbox .administration .mat-stroked-button.mat-button-disabled).mat-primary .mat-icon {
  background-color: #e6aa16;
  color: var(--color-white);
}
.ctbox .administration .mat-stroked-button:not(.ctbox .administration .mat-stroked-button.mat-button-disabled).mat-accent {
  border-color: #a3a3a3;
}
.ctbox .administration .mat-stroked-button:not(.ctbox .administration .mat-stroked-button.mat-button-disabled).mat-accent .mat-icon {
  background-color: #636363;
  color: var(--color-white);
}
.ctbox .administration .mat-stroked-button:not(.ctbox .administration .mat-stroked-button.mat-button-disabled).mat-warn {
  border-color: #f44336;
}
.ctbox .administration .mat-stroked-button:not(.ctbox .administration .mat-stroked-button.mat-button-disabled).mat-warn .mat-icon {
  background-color: #a21309;
  color: var(--color-white);
}
.ctbox .administration .mat-stroked-button:not(.ctbox .administration .mat-stroked-button.mat-button-disabled).mat-templates {
  border-color: var(--color-templates);
  color: var(--color-templates);
}
.ctbox .administration .mat-stroked-button:not(.ctbox .administration .mat-stroked-button.mat-button-disabled).mat-templates.mat-icon-button-left .mat-icon {
  border-right: 1px solid var(--color-templates);
  color: var(--color-templates);
}
.ctbox .administration .mat-stroked-button:not(.ctbox .administration .mat-stroked-button.mat-button-disabled).mat-clauses {
  border-color: var(--color-clauses);
  color: var(--color-clauses);
}
.ctbox .administration .mat-stroked-button:not(.ctbox .administration .mat-stroked-button.mat-button-disabled).mat-clauses.mat-icon-button-left .mat-icon {
  border-right: 1px solid var(--color-clauses);
  color: var(--color-clauses);
}
.ctbox .administration .mat-stroked-button:not(.ctbox .administration .mat-stroked-button.mat-button-disabled).mat-administration {
  border-color: var(--color-administration);
  color: var(--color-administration);
}
.ctbox .administration .mat-stroked-button:not(.ctbox .administration .mat-stroked-button.mat-button-disabled).mat-administration.mat-icon-button-left .mat-icon {
  border-right: 1px solid var(--color-administration);
  color: var(--color-administration);
}
.ctbox .administration .mat-stroked-button.mat-permission-discard {
  border-radius: 8px;
  border-color: var(--color-error);
  color: var(--color-error);
  font-size: 12px;
  line-height: 16px;
  min-height: 20px;
}
.ctbox .administration .mat-stroked-button.mat-permission-discard:hover {
  background-color: var(--color-error);
  color: var(--color-white);
}
.ctbox .administration .mat-stroked-button.mat-permission-assign {
  border-radius: 8px;
  border-color: var(--color-success);
  color: var(--color-success);
  font-size: 12px;
  line-height: 16px;
  min-height: 20px;
}
.ctbox .administration .mat-stroked-button.mat-permission-assign:hover {
  background-color: var(--color-success);
  color: var(--color-white);
}
.ctbox .administration .mat-stroked-button .mat-icon {
  height: 30px;
  width: 30px;
}
.ctbox .administration .mat-button-wrapper {
  display: flex;
  align-items: center;
}
.ctbox .administration .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  line-height: 24px !important;
  height: 24px !important;
  width: 24px !important;
  max-width: 24px !important;
  min-width: 24px !important;
  max-height: 24px !important;
  min-height: 24px !important;
}
.ctbox .administration .mat-rrss {
  background-color: transparent;
  color: var(--color-white);
}
.ctbox .administration .mat-info {
  color: var(--color-info);
}
.ctbox .administration .mat-success {
  color: var(--color-success);
}
.ctbox .administration .mat-success.border {
  border: 1px solid var(--color-success);
}
.ctbox .administration .mat-delete {
  color: var(--color-delete);
}
.ctbox .administration .mat-state {
  border: var(--border-default);
}
.ctbox .administration .mat-state.no-border {
  border-color: transparent;
}
.ctbox .administration .mat-datepicker-close-button::before {
  content: "\e5cd";
  display: block;
  font-family: var(--font-family-icons);
  font-size: 24px;
  line-height: 36px;
}
.ctbox .administration .mat-datepicker-close-button .mat-button-wrapper {
  display: none;
}
.ctbox .administration .mat-button-focus-overlay {
  background: transparent;
}
.ctbox .administration .mat-button-disabled,
.ctbox .administration .disabled {
  cursor: not-allowed;
  pointer-events: none !important;
  border-color: var(--color-disabled) !important;
}
.ctbox .administration .mat-button-disabled.mat-icon-button-right .mat-icon, .ctbox .administration .mat-button-disabled.mat-icon-button-left .mat-icon,
.ctbox .administration .disabled.mat-icon-button-right .mat-icon,
.ctbox .administration .disabled.mat-icon-button-left .mat-icon {
  background-color: var(--background-color-disabled);
}
.ctbox .administration .mat-button-text-right,
.ctbox .administration .mat-button-text-left {
  padding: 0 16px;
}
.ctbox .administration .xl .mat-button-text-left,
.ctbox .administration .xl .mat-button-text-right {
  font-size: 30px;
}
.ctbox .administration .text-default *[class*=mat-button-text] {
  color: var(--color-text);
}
.ctbox .administration .cursor-default {
  cursor: default;
}
.ctbox .administration .mat-account {
  color: var(--color-white);
}
.ctbox .administration .mat-account .mat-button-wrapper {
  justify-content: center;
}
.ctbox .administration .mat-account-login {
  background-color: var(--color-blue-300);
}
.ctbox .administration .mat-account-login:hover {
  background-color: var(--color-blue-700) !important;
}
.ctbox .administration .mat-account-admin {
  background-color: var(--color-blue-700);
}
.ctbox .administration .mat-account-admin:hover {
  background-color: var(--color-blue-300) !important;
}
.ctbox .administration .mat-account-logout {
  background-color: var(--color-gray-300);
  color: var(--color-gray-700);
}
.ctbox .administration .mat-account-logout:hover {
  background-color: var(--color-gray-700) !important;
  color: var(--color-white);
}
.ctbox .administration .border-left {
  border-left: var(--border-default) !important;
}
.ctbox .administration .mat-menu-item {
  height: 32px;
}
.ctbox .clauses .mat-button,
.ctbox .clauses .mat-icon-button,
.ctbox .clauses .mat-raised-button,
.ctbox .clauses .mat-flat-button {
  line-height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 0;
}
.ctbox .clauses .mat-button.mat-icon-button-left,
.ctbox .clauses .mat-icon-button.mat-icon-button-left,
.ctbox .clauses .mat-raised-button.mat-icon-button-left,
.ctbox .clauses .mat-flat-button.mat-icon-button-left {
  padding: 0;
}
.ctbox .clauses .mat-button.mat-icon-button-right,
.ctbox .clauses .mat-icon-button.mat-icon-button-right,
.ctbox .clauses .mat-raised-button.mat-icon-button-right,
.ctbox .clauses .mat-flat-button.mat-icon-button-right {
  padding: 0;
}
.ctbox .clauses .mat-button-icon,
.ctbox .clauses .mat-icon-button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  min-width: 32px !important;
  padding: 0;
}
.ctbox .clauses .mat-button-icon.Small, .ctbox .clauses .mat-button-icon.XSmall,
.ctbox .clauses .mat-icon-button.Small,
.ctbox .clauses .mat-icon-button.XSmall {
  width: 38px;
  height: 38px;
}
.ctbox .clauses .mat-button-icon.xsmall,
.ctbox .clauses .mat-icon-button.xsmall {
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  min-width: 20px !important;
  min-height: 20px !important;
  font-size: 20px !important;
}
.ctbox .clauses .mat-button-icon[class*=mat-calendar],
.ctbox .clauses .mat-icon-button[class*=mat-calendar] {
  width: 40px;
  height: 40px;
  line-height: 40px;
  min-width: 40px !important;
}
.ctbox .clauses .mat-button-icon.hidden,
.ctbox .clauses .mat-icon-button.hidden {
  display: var(--display-go-back);
}
.ctbox .clauses .mat-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ctbox .clauses .mat-icon svg {
  max-width: 18px;
  max-height: 24px;
}
.ctbox .clauses .mat-icon,
.ctbox .clauses .material-symbols-outlined {
  font-family: var(--font-family-icons);
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}
.ctbox .clauses .mat-icon.filled,
.ctbox .clauses .material-symbols-outlined.filled {
  font-variation-settings: "FILL" 1;
}
.ctbox .clauses .mat-icon.xxs,
.ctbox .clauses .material-symbols-outlined.xxs {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  font-size: 14px !important;
}
.ctbox .clauses .mat-icon.small,
.ctbox .clauses .material-symbols-outlined.small {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  font-size: 16px !important;
}
.ctbox .clauses .mat-icon.xsmall,
.ctbox .clauses .material-symbols-outlined.xsmall {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  font-size: 20px !important;
}
.ctbox .clauses .mat-icon.medium,
.ctbox .clauses .material-symbols-outlined.medium {
  min-width: 36px;
  min-height: 36px;
  font-size: 36px !important;
}
.ctbox .clauses .mat-icon.big,
.ctbox .clauses .material-symbols-outlined.big {
  min-width: 48px;
  min-height: 48px;
  font-size: 48px !important;
}
.ctbox .clauses .mat-icon.xl,
.ctbox .clauses .material-symbols-outlined.xl {
  min-width: 56px;
  min-height: 56px;
  font-size: 56px !important;
}
.ctbox .clauses .mat-icon.state,
.ctbox .clauses .material-symbols-outlined.state {
  width: 32px;
  height: 32px;
  background-color: transparent !important;
}
.ctbox .clauses .mat-icon.title,
.ctbox .clauses .material-symbols-outlined.title {
  font-size: 32px !important;
}
.ctbox .clauses .mat-icon.warning,
.ctbox .clauses .material-symbols-outlined.warning {
  color: var(--color-warning);
}
.ctbox .clauses .mat-icon.info,
.ctbox .clauses .material-symbols-outlined.info {
  color: var(--color-info);
}
.ctbox .clauses .mat-icon.done, .ctbox .clauses .mat-icon.true,
.ctbox .clauses .material-symbols-outlined.done,
.ctbox .clauses .material-symbols-outlined.true {
  color: var(--color-success);
}
.ctbox .clauses .mat-icon.error, .ctbox .clauses .mat-icon.false,
.ctbox .clauses .material-symbols-outlined.error,
.ctbox .clauses .material-symbols-outlined.false {
  color: var(--color-error);
}
.ctbox .clauses .mat-icon.draft,
.ctbox .clauses .material-symbols-outlined.draft {
  color: var(--color-state-draft) !important;
}
.ctbox .clauses .mat-icon.review,
.ctbox .clauses .material-symbols-outlined.review {
  color: var(--color-state-review) !important;
}
.ctbox .clauses .mat-icon.completed,
.ctbox .clauses .material-symbols-outlined.completed {
  color: var(--color-state-completed) !important;
}
.ctbox .clauses .mat-icon.signing,
.ctbox .clauses .material-symbols-outlined.signing {
  color: var(--color-state-signing) !important;
}
.ctbox .clauses .mat-icon.signed,
.ctbox .clauses .material-symbols-outlined.signed {
  color: var(--color-state-signed) !important;
}
.ctbox .clauses .mat-icon.template-clause.cmc,
.ctbox .clauses .material-symbols-outlined.template-clause.cmc {
  border-radius: 100%;
  border-right-color: var(--color-santander-dark);
  border-top-color: var(--color-disabled);
  border-bottom-color: var(--color-santander-dark);
  border-left-color: var(--color-disabled);
  border-width: 12px;
  border-style: solid;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
}
.ctbox .clauses .mat-icon.template-document,
.ctbox .clauses .material-symbols-outlined.template-document {
  border-radius: 100%;
  border-right-color: var(--color-templates);
  border-top-color: var(--color-documents);
  border-bottom-color: var(--color-templates);
  border-left-color: var(--color-documents);
  border-width: 10px;
  border-style: solid;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
}
.ctbox .clauses .mat-icon.type,
.ctbox .clauses .material-symbols-outlined.type {
  font-family: var(--font-family-sans-serif) !important;
  font-size: 0;
}
.ctbox .clauses .mat-icon.type:before,
.ctbox .clauses .material-symbols-outlined.type:before {
  align-content: center;
  align-items: center;
  background-color: var(--color-disabled);
  border-radius: 50%;
  color: var(--color-white);
  content: " ";
  display: flex;
  justify-content: center;
  font-size: 12px;
  height: 22px;
  width: 22px;
}
.ctbox .clauses .mat-icon.type.document:before,
.ctbox .clauses .material-symbols-outlined.type.document:before {
  background-color: var(--color-documents);
  content: "D";
}
.ctbox .clauses .mat-icon.type.template:before,
.ctbox .clauses .material-symbols-outlined.type.template:before {
  background-color: var(--color-templates);
  content: "P";
}
.ctbox .clauses .mat-icon.type.template.cmc:before,
.ctbox .clauses .material-symbols-outlined.type.template.cmc:before {
  background-color: var(--color-disabled);
}
.ctbox .clauses .mat-icon.type.clause.cmc:before,
.ctbox .clauses .material-symbols-outlined.type.clause.cmc:before {
  background-color: var(--color-santander-dark);
  content: "C";
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-primary .mat-button-text-right,
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-primary .mat-button-text-left {
  background: #629c2c;
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-primary .mat-icon {
  background-color: #243910;
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-accent {
  background: #a3a3a3;
  color: var(--color-white);
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-accent .mat-icon {
  background-color: #636363;
  color: var(--color-white);
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-templates {
  background: var(--color-templates);
  color: var(--color-white);
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-templates .mat-icon {
  background-color: #243910;
  color: var(--color-white);
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-clauses {
  background: var(--color-clauses);
  color: var(--color-white);
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-clauses .mat-icon {
  background-color: #13463e;
  color: var(--color-white);
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-administration {
  background: var(--color-administration);
  color: var(--color-white);
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-administration .mat-icon {
  background-color: #72540b;
  color: var(--color-white);
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-warn, .ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-error {
  background: var(--color-error);
  color: var(--color-white);
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-warn .mat-icon, .ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-error .mat-icon {
  background-color: #a21309;
  color: var(--color-white);
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-warn .mat-icon svg, .ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-error .mat-icon svg {
  fill: inherit;
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-santander {
  background: var(--color-santander);
  color: var(--color-white);
}
.ctbox .clauses .mat-flat-button:not(.ctbox .clauses .mat-flat-button.mat-button-disabled).mat-santander .mat-icon {
  color: var(--color-white);
  background-color: var(--color-santander-dark);
}
.ctbox .clauses .mat-stroked-button {
  border-radius: 0;
  line-height: 30px;
  min-width: 30px;
}
.ctbox .clauses .mat-stroked-button:not(.ctbox .clauses .mat-stroked-button.mat-button-disabled).mat-primary {
  border-color: #629c2c;
  background: var(--color-white);
  color: #629c2c;
}
.ctbox .clauses .mat-stroked-button:not(.ctbox .clauses .mat-stroked-button.mat-button-disabled).mat-primary .mat-icon {
  background-color: #629c2c;
  color: var(--color-white);
}
.ctbox .clauses .mat-stroked-button:not(.ctbox .clauses .mat-stroked-button.mat-button-disabled).mat-accent {
  border-color: #a3a3a3;
}
.ctbox .clauses .mat-stroked-button:not(.ctbox .clauses .mat-stroked-button.mat-button-disabled).mat-accent .mat-icon {
  background-color: #636363;
  color: var(--color-white);
}
.ctbox .clauses .mat-stroked-button:not(.ctbox .clauses .mat-stroked-button.mat-button-disabled).mat-warn {
  border-color: #f44336;
}
.ctbox .clauses .mat-stroked-button:not(.ctbox .clauses .mat-stroked-button.mat-button-disabled).mat-warn .mat-icon {
  background-color: #a21309;
  color: var(--color-white);
}
.ctbox .clauses .mat-stroked-button:not(.ctbox .clauses .mat-stroked-button.mat-button-disabled).mat-templates {
  border-color: var(--color-templates);
  color: var(--color-templates);
}
.ctbox .clauses .mat-stroked-button:not(.ctbox .clauses .mat-stroked-button.mat-button-disabled).mat-templates.mat-icon-button-left .mat-icon {
  border-right: 1px solid var(--color-templates);
  color: var(--color-templates);
}
.ctbox .clauses .mat-stroked-button:not(.ctbox .clauses .mat-stroked-button.mat-button-disabled).mat-clauses {
  border-color: var(--color-clauses);
  color: var(--color-clauses);
}
.ctbox .clauses .mat-stroked-button:not(.ctbox .clauses .mat-stroked-button.mat-button-disabled).mat-clauses.mat-icon-button-left .mat-icon {
  border-right: 1px solid var(--color-clauses);
  color: var(--color-clauses);
}
.ctbox .clauses .mat-stroked-button:not(.ctbox .clauses .mat-stroked-button.mat-button-disabled).mat-administration {
  border-color: var(--color-administration);
  color: var(--color-administration);
}
.ctbox .clauses .mat-stroked-button:not(.ctbox .clauses .mat-stroked-button.mat-button-disabled).mat-administration.mat-icon-button-left .mat-icon {
  border-right: 1px solid var(--color-administration);
  color: var(--color-administration);
}
.ctbox .clauses .mat-stroked-button.mat-permission-discard {
  border-radius: 8px;
  border-color: var(--color-error);
  color: var(--color-error);
  font-size: 12px;
  line-height: 16px;
  min-height: 20px;
}
.ctbox .clauses .mat-stroked-button.mat-permission-discard:hover {
  background-color: var(--color-error);
  color: var(--color-white);
}
.ctbox .clauses .mat-stroked-button.mat-permission-assign {
  border-radius: 8px;
  border-color: var(--color-success);
  color: var(--color-success);
  font-size: 12px;
  line-height: 16px;
  min-height: 20px;
}
.ctbox .clauses .mat-stroked-button.mat-permission-assign:hover {
  background-color: var(--color-success);
  color: var(--color-white);
}
.ctbox .clauses .mat-stroked-button .mat-icon {
  height: 30px;
  width: 30px;
}
.ctbox .clauses .mat-button-wrapper {
  display: flex;
  align-items: center;
}
.ctbox .clauses .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  line-height: 24px !important;
  height: 24px !important;
  width: 24px !important;
  max-width: 24px !important;
  min-width: 24px !important;
  max-height: 24px !important;
  min-height: 24px !important;
}
.ctbox .clauses .mat-rrss {
  background-color: transparent;
  color: var(--color-white);
}
.ctbox .clauses .mat-info {
  color: var(--color-info);
}
.ctbox .clauses .mat-success {
  color: var(--color-success);
}
.ctbox .clauses .mat-success.border {
  border: 1px solid var(--color-success);
}
.ctbox .clauses .mat-delete {
  color: var(--color-delete);
}
.ctbox .clauses .mat-state {
  border: var(--border-default);
}
.ctbox .clauses .mat-state.no-border {
  border-color: transparent;
}
.ctbox .clauses .mat-datepicker-close-button::before {
  content: "\e5cd";
  display: block;
  font-family: var(--font-family-icons);
  font-size: 24px;
  line-height: 36px;
}
.ctbox .clauses .mat-datepicker-close-button .mat-button-wrapper {
  display: none;
}
.ctbox .clauses .mat-button-focus-overlay {
  background: transparent;
}
.ctbox .clauses .mat-button-disabled,
.ctbox .clauses .disabled {
  cursor: not-allowed;
  pointer-events: none !important;
  border-color: var(--color-disabled) !important;
}
.ctbox .clauses .mat-button-disabled.mat-icon-button-right .mat-icon, .ctbox .clauses .mat-button-disabled.mat-icon-button-left .mat-icon,
.ctbox .clauses .disabled.mat-icon-button-right .mat-icon,
.ctbox .clauses .disabled.mat-icon-button-left .mat-icon {
  background-color: var(--background-color-disabled);
}
.ctbox .clauses .mat-button-text-right,
.ctbox .clauses .mat-button-text-left {
  padding: 0 16px;
}
.ctbox .clauses .xl .mat-button-text-left,
.ctbox .clauses .xl .mat-button-text-right {
  font-size: 30px;
}
.ctbox .clauses .text-default *[class*=mat-button-text] {
  color: var(--color-text);
}
.ctbox .clauses .cursor-default {
  cursor: default;
}
.ctbox .clauses .mat-account {
  color: var(--color-white);
}
.ctbox .clauses .mat-account .mat-button-wrapper {
  justify-content: center;
}
.ctbox .clauses .mat-account-login {
  background-color: var(--color-blue-300);
}
.ctbox .clauses .mat-account-login:hover {
  background-color: var(--color-blue-700) !important;
}
.ctbox .clauses .mat-account-admin {
  background-color: var(--color-blue-700);
}
.ctbox .clauses .mat-account-admin:hover {
  background-color: var(--color-blue-300) !important;
}
.ctbox .clauses .mat-account-logout {
  background-color: var(--color-gray-300);
  color: var(--color-gray-700);
}
.ctbox .clauses .mat-account-logout:hover {
  background-color: var(--color-gray-700) !important;
  color: var(--color-white);
}
.ctbox .clauses .border-left {
  border-left: var(--border-default) !important;
}
.ctbox .clauses .mat-menu-item {
  height: 32px;
}
.ctbox .templates .mat-button,
.ctbox .templates .mat-icon-button,
.ctbox .templates .mat-raised-button,
.ctbox .templates .mat-flat-button {
  line-height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 0;
}
.ctbox .templates .mat-button.mat-icon-button-left,
.ctbox .templates .mat-icon-button.mat-icon-button-left,
.ctbox .templates .mat-raised-button.mat-icon-button-left,
.ctbox .templates .mat-flat-button.mat-icon-button-left {
  padding: 0;
}
.ctbox .templates .mat-button.mat-icon-button-right,
.ctbox .templates .mat-icon-button.mat-icon-button-right,
.ctbox .templates .mat-raised-button.mat-icon-button-right,
.ctbox .templates .mat-flat-button.mat-icon-button-right {
  padding: 0;
}
.ctbox .templates .mat-button-icon,
.ctbox .templates .mat-icon-button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  min-width: 32px !important;
  padding: 0;
}
.ctbox .templates .mat-button-icon.Small, .ctbox .templates .mat-button-icon.XSmall,
.ctbox .templates .mat-icon-button.Small,
.ctbox .templates .mat-icon-button.XSmall {
  width: 38px;
  height: 38px;
}
.ctbox .templates .mat-button-icon.xsmall,
.ctbox .templates .mat-icon-button.xsmall {
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  min-width: 20px !important;
  min-height: 20px !important;
  font-size: 20px !important;
}
.ctbox .templates .mat-button-icon[class*=mat-calendar],
.ctbox .templates .mat-icon-button[class*=mat-calendar] {
  width: 40px;
  height: 40px;
  line-height: 40px;
  min-width: 40px !important;
}
.ctbox .templates .mat-button-icon.hidden,
.ctbox .templates .mat-icon-button.hidden {
  display: var(--display-go-back);
}
.ctbox .templates .mat-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ctbox .templates .mat-icon svg {
  max-width: 18px;
  max-height: 24px;
}
.ctbox .templates .mat-icon,
.ctbox .templates .material-symbols-outlined {
  font-family: var(--font-family-icons);
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}
.ctbox .templates .mat-icon.filled,
.ctbox .templates .material-symbols-outlined.filled {
  font-variation-settings: "FILL" 1;
}
.ctbox .templates .mat-icon.xxs,
.ctbox .templates .material-symbols-outlined.xxs {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  font-size: 14px !important;
}
.ctbox .templates .mat-icon.small,
.ctbox .templates .material-symbols-outlined.small {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  font-size: 16px !important;
}
.ctbox .templates .mat-icon.xsmall,
.ctbox .templates .material-symbols-outlined.xsmall {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  font-size: 20px !important;
}
.ctbox .templates .mat-icon.medium,
.ctbox .templates .material-symbols-outlined.medium {
  min-width: 36px;
  min-height: 36px;
  font-size: 36px !important;
}
.ctbox .templates .mat-icon.big,
.ctbox .templates .material-symbols-outlined.big {
  min-width: 48px;
  min-height: 48px;
  font-size: 48px !important;
}
.ctbox .templates .mat-icon.xl,
.ctbox .templates .material-symbols-outlined.xl {
  min-width: 56px;
  min-height: 56px;
  font-size: 56px !important;
}
.ctbox .templates .mat-icon.state,
.ctbox .templates .material-symbols-outlined.state {
  width: 32px;
  height: 32px;
  background-color: transparent !important;
}
.ctbox .templates .mat-icon.title,
.ctbox .templates .material-symbols-outlined.title {
  font-size: 32px !important;
}
.ctbox .templates .mat-icon.warning,
.ctbox .templates .material-symbols-outlined.warning {
  color: var(--color-warning);
}
.ctbox .templates .mat-icon.info,
.ctbox .templates .material-symbols-outlined.info {
  color: var(--color-info);
}
.ctbox .templates .mat-icon.done, .ctbox .templates .mat-icon.true,
.ctbox .templates .material-symbols-outlined.done,
.ctbox .templates .material-symbols-outlined.true {
  color: var(--color-success);
}
.ctbox .templates .mat-icon.error, .ctbox .templates .mat-icon.false,
.ctbox .templates .material-symbols-outlined.error,
.ctbox .templates .material-symbols-outlined.false {
  color: var(--color-error);
}
.ctbox .templates .mat-icon.draft,
.ctbox .templates .material-symbols-outlined.draft {
  color: var(--color-state-draft) !important;
}
.ctbox .templates .mat-icon.review,
.ctbox .templates .material-symbols-outlined.review {
  color: var(--color-state-review) !important;
}
.ctbox .templates .mat-icon.completed,
.ctbox .templates .material-symbols-outlined.completed {
  color: var(--color-state-completed) !important;
}
.ctbox .templates .mat-icon.signing,
.ctbox .templates .material-symbols-outlined.signing {
  color: var(--color-state-signing) !important;
}
.ctbox .templates .mat-icon.signed,
.ctbox .templates .material-symbols-outlined.signed {
  color: var(--color-state-signed) !important;
}
.ctbox .templates .mat-icon.template-clause.cmc,
.ctbox .templates .material-symbols-outlined.template-clause.cmc {
  border-radius: 100%;
  border-right-color: var(--color-santander-dark);
  border-top-color: var(--color-disabled);
  border-bottom-color: var(--color-santander-dark);
  border-left-color: var(--color-disabled);
  border-width: 12px;
  border-style: solid;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
}
.ctbox .templates .mat-icon.template-document,
.ctbox .templates .material-symbols-outlined.template-document {
  border-radius: 100%;
  border-right-color: var(--color-templates);
  border-top-color: var(--color-documents);
  border-bottom-color: var(--color-templates);
  border-left-color: var(--color-documents);
  border-width: 10px;
  border-style: solid;
  height: 0;
  width: 0;
  transform: rotate(-45deg);
}
.ctbox .templates .mat-icon.type,
.ctbox .templates .material-symbols-outlined.type {
  font-family: var(--font-family-sans-serif) !important;
  font-size: 0;
}
.ctbox .templates .mat-icon.type:before,
.ctbox .templates .material-symbols-outlined.type:before {
  align-content: center;
  align-items: center;
  background-color: var(--color-disabled);
  border-radius: 50%;
  color: var(--color-white);
  content: " ";
  display: flex;
  justify-content: center;
  font-size: 12px;
  height: 22px;
  width: 22px;
}
.ctbox .templates .mat-icon.type.document:before,
.ctbox .templates .material-symbols-outlined.type.document:before {
  background-color: var(--color-documents);
  content: "D";
}
.ctbox .templates .mat-icon.type.template:before,
.ctbox .templates .material-symbols-outlined.type.template:before {
  background-color: var(--color-templates);
  content: "P";
}
.ctbox .templates .mat-icon.type.template.cmc:before,
.ctbox .templates .material-symbols-outlined.type.template.cmc:before {
  background-color: var(--color-disabled);
}
.ctbox .templates .mat-icon.type.clause.cmc:before,
.ctbox .templates .material-symbols-outlined.type.clause.cmc:before {
  background-color: var(--color-santander-dark);
  content: "C";
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-primary .mat-button-text-right,
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-primary .mat-button-text-left {
  background: #629c2c;
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-primary .mat-icon {
  background-color: #243910;
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-accent {
  background: #a3a3a3;
  color: var(--color-white);
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-accent .mat-icon {
  background-color: #636363;
  color: var(--color-white);
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-templates {
  background: var(--color-templates);
  color: var(--color-white);
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-templates .mat-icon {
  background-color: #243910;
  color: var(--color-white);
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-clauses {
  background: var(--color-clauses);
  color: var(--color-white);
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-clauses .mat-icon {
  background-color: #13463e;
  color: var(--color-white);
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-administration {
  background: var(--color-administration);
  color: var(--color-white);
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-administration .mat-icon {
  background-color: #72540b;
  color: var(--color-white);
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-warn, .ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-error {
  background: var(--color-error);
  color: var(--color-white);
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-warn .mat-icon, .ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-error .mat-icon {
  background-color: #a21309;
  color: var(--color-white);
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-warn .mat-icon svg, .ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-error .mat-icon svg {
  fill: inherit;
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-santander {
  background: var(--color-santander);
  color: var(--color-white);
}
.ctbox .templates .mat-flat-button:not(.ctbox .templates .mat-flat-button.mat-button-disabled).mat-santander .mat-icon {
  color: var(--color-white);
  background-color: var(--color-santander-dark);
}
.ctbox .templates .mat-stroked-button {
  border-radius: 0;
  line-height: 30px;
  min-width: 30px;
}
.ctbox .templates .mat-stroked-button:not(.ctbox .templates .mat-stroked-button.mat-button-disabled).mat-primary {
  border-color: #629c2c;
  background: var(--color-white);
  color: #629c2c;
}
.ctbox .templates .mat-stroked-button:not(.ctbox .templates .mat-stroked-button.mat-button-disabled).mat-primary .mat-icon {
  background-color: #629c2c;
  color: var(--color-white);
}
.ctbox .templates .mat-stroked-button:not(.ctbox .templates .mat-stroked-button.mat-button-disabled).mat-accent {
  border-color: #a3a3a3;
}
.ctbox .templates .mat-stroked-button:not(.ctbox .templates .mat-stroked-button.mat-button-disabled).mat-accent .mat-icon {
  background-color: #636363;
  color: var(--color-white);
}
.ctbox .templates .mat-stroked-button:not(.ctbox .templates .mat-stroked-button.mat-button-disabled).mat-warn {
  border-color: #f44336;
}
.ctbox .templates .mat-stroked-button:not(.ctbox .templates .mat-stroked-button.mat-button-disabled).mat-warn .mat-icon {
  background-color: #a21309;
  color: var(--color-white);
}
.ctbox .templates .mat-stroked-button:not(.ctbox .templates .mat-stroked-button.mat-button-disabled).mat-templates {
  border-color: var(--color-templates);
  color: var(--color-templates);
}
.ctbox .templates .mat-stroked-button:not(.ctbox .templates .mat-stroked-button.mat-button-disabled).mat-templates.mat-icon-button-left .mat-icon {
  border-right: 1px solid var(--color-templates);
  color: var(--color-templates);
}
.ctbox .templates .mat-stroked-button:not(.ctbox .templates .mat-stroked-button.mat-button-disabled).mat-clauses {
  border-color: var(--color-clauses);
  color: var(--color-clauses);
}
.ctbox .templates .mat-stroked-button:not(.ctbox .templates .mat-stroked-button.mat-button-disabled).mat-clauses.mat-icon-button-left .mat-icon {
  border-right: 1px solid var(--color-clauses);
  color: var(--color-clauses);
}
.ctbox .templates .mat-stroked-button:not(.ctbox .templates .mat-stroked-button.mat-button-disabled).mat-administration {
  border-color: var(--color-administration);
  color: var(--color-administration);
}
.ctbox .templates .mat-stroked-button:not(.ctbox .templates .mat-stroked-button.mat-button-disabled).mat-administration.mat-icon-button-left .mat-icon {
  border-right: 1px solid var(--color-administration);
  color: var(--color-administration);
}
.ctbox .templates .mat-stroked-button.mat-permission-discard {
  border-radius: 8px;
  border-color: var(--color-error);
  color: var(--color-error);
  font-size: 12px;
  line-height: 16px;
  min-height: 20px;
}
.ctbox .templates .mat-stroked-button.mat-permission-discard:hover {
  background-color: var(--color-error);
  color: var(--color-white);
}
.ctbox .templates .mat-stroked-button.mat-permission-assign {
  border-radius: 8px;
  border-color: var(--color-success);
  color: var(--color-success);
  font-size: 12px;
  line-height: 16px;
  min-height: 20px;
}
.ctbox .templates .mat-stroked-button.mat-permission-assign:hover {
  background-color: var(--color-success);
  color: var(--color-white);
}
.ctbox .templates .mat-stroked-button .mat-icon {
  height: 30px;
  width: 30px;
}
.ctbox .templates .mat-button-wrapper {
  display: flex;
  align-items: center;
}
.ctbox .templates .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  line-height: 24px !important;
  height: 24px !important;
  width: 24px !important;
  max-width: 24px !important;
  min-width: 24px !important;
  max-height: 24px !important;
  min-height: 24px !important;
}
.ctbox .templates .mat-rrss {
  background-color: transparent;
  color: var(--color-white);
}
.ctbox .templates .mat-info {
  color: var(--color-info);
}
.ctbox .templates .mat-success {
  color: var(--color-success);
}
.ctbox .templates .mat-success.border {
  border: 1px solid var(--color-success);
}
.ctbox .templates .mat-delete {
  color: var(--color-delete);
}
.ctbox .templates .mat-state {
  border: var(--border-default);
}
.ctbox .templates .mat-state.no-border {
  border-color: transparent;
}
.ctbox .templates .mat-datepicker-close-button::before {
  content: "\e5cd";
  display: block;
  font-family: var(--font-family-icons);
  font-size: 24px;
  line-height: 36px;
}
.ctbox .templates .mat-datepicker-close-button .mat-button-wrapper {
  display: none;
}
.ctbox .templates .mat-button-focus-overlay {
  background: transparent;
}
.ctbox .templates .mat-button-disabled,
.ctbox .templates .disabled {
  cursor: not-allowed;
  pointer-events: none !important;
  border-color: var(--color-disabled) !important;
}
.ctbox .templates .mat-button-disabled.mat-icon-button-right .mat-icon, .ctbox .templates .mat-button-disabled.mat-icon-button-left .mat-icon,
.ctbox .templates .disabled.mat-icon-button-right .mat-icon,
.ctbox .templates .disabled.mat-icon-button-left .mat-icon {
  background-color: var(--background-color-disabled);
}
.ctbox .templates .mat-button-text-right,
.ctbox .templates .mat-button-text-left {
  padding: 0 16px;
}
.ctbox .templates .xl .mat-button-text-left,
.ctbox .templates .xl .mat-button-text-right {
  font-size: 30px;
}
.ctbox .templates .text-default *[class*=mat-button-text] {
  color: var(--color-text);
}
.ctbox .templates .cursor-default {
  cursor: default;
}
.ctbox .templates .mat-account {
  color: var(--color-white);
}
.ctbox .templates .mat-account .mat-button-wrapper {
  justify-content: center;
}
.ctbox .templates .mat-account-login {
  background-color: var(--color-blue-300);
}
.ctbox .templates .mat-account-login:hover {
  background-color: var(--color-blue-700) !important;
}
.ctbox .templates .mat-account-admin {
  background-color: var(--color-blue-700);
}
.ctbox .templates .mat-account-admin:hover {
  background-color: var(--color-blue-300) !important;
}
.ctbox .templates .mat-account-logout {
  background-color: var(--color-gray-300);
  color: var(--color-gray-700);
}
.ctbox .templates .mat-account-logout:hover {
  background-color: var(--color-gray-700) !important;
  color: var(--color-white);
}
.ctbox .templates .border-left {
  border-left: var(--border-default) !important;
}
.ctbox .templates .mat-menu-item {
  height: 32px;
}