@use "@angular/material" as mat;
@mixin mat-tab-nav-bar($theme) {
    .mat-tab-nav-bar,
    .mat-tab-header {
        border-bottom: none;
    }
    .mat-tab-list,
    .mat-tab-nav-bar {
        .mat-ink-bar {
            height: 0;
        }
        .mat-tab-label,
        .mat-tab-link {
            opacity: 1;
            border-bottom: 6px solid transparent;
        }
        &.mat-primary {
            &.documents {
                .cdk-focused,
                .mat-tab-label-active {
                    border-bottom: 6px solid var(--color-documents);
                    &.draft {
                        border-bottom-color: var(--color-state-draft) !important;
                    }
                    &.review {
                        border-bottom-color: var(--color-state-review) !important;
                    }
                    &.completed {
                        border-bottom-color: var(--color-state-completed) !important;
                    }
                    &.signing {
                        border-bottom-color: var(--color-state-signing) !important;
                    }
                    &.signed {
                        border-bottom-color: var(--color-state-signed) !important;
                    }
                }
            }
            &.templates {
                .mat-tab-label-active {
                    border-bottom: 6px solid var(--color-templates);
                }
            }
            &.clauses {
                .mat-tab-label-active {
                    border-bottom: 6px solid var(--color-clauses);
                }
            }
            &.administration {
                .mat-tab-label-active {
                    border-bottom: 6px solid var(--color-administration);
                }
            }
        }
        &.menu,
        &.landing-tabs {
            &.ctbox {
                background-color: var(--color-blue-700);
            }
            border-bottom: none;
            overflow: visible;
            padding: 0;
            .disabled {
                opacity: 0.5 !important;
            }
            .mat-tab-links {
                .mat-tab-label,
                .mat-tab-link {
                    color: var(--color-white);
                    font-size: 16px;
                    font-weight: 300;
                    opacity: 1;
                    text-decoration: none !important;
                    border: 0;
                    border-right: 1px solid rgba(255, 255, 255, 0.22);
                    &-active {
                        background-color: var(--color-blue-800);
                    }
                    &.free {
                        background-color: var(--color-red-500);
                        color: var(--color-white);
                        margin-left: 80px;
                        &.active {
                            background-color: var(--color-red-500);
                        }
                    }
                    &:hover {
                        background-color: var(--color-blue-400);
                    }
                }
            }
            .mat-ink-bar {
                background-color: inherit;
                height: 0;
            }
            &.Small,
            &.XSmall {
                width: 100%;
                .mat-tab-links {
                    flex-direction: column;
                    justify-content: center;
                    .free {
                        margin-left: 0;
                    }
                }
            }
        }
        &.menu {
            width: 100vw;
            .mat-tab-links {
                .mat-tab-label,
                .mat-tab-link {
                    min-height: 32px;
                    max-height: 40px;
                    &.tab {
                        border-right: 1px solid rgba(255, 255, 255, 0.22);
                    }
                    &.tab-dashboard {
                        background-color: var(--color-dashboard);
                        &.active,
                        &:hover {
                            background-color: var(--color-dashboard);
                        }
                    }
                    &.tab-contracts {
                        &.active,
                        &:hover {
                            background-color: var(--color-documents);
                        }
                    }
                    &.tab-templates {
                        &.active,
                        &:hover {
                            background-color: var(--color-templates);
                        }
                    }
                    &.tab-clauses {
                        &.active,
                        &:hover {
                            background-color: var(--color-clauses);
                        }
                    }
                    &.tab-administration {
                        &.ctbox {
                            position: absolute;
                            right: 218px;
                            border-left: 1px solid rgba(255, 255, 255, 0.22);
                            &.active,
                            &:hover {
                                background-color: var(--color-administration);
                            }
                        }
                    }
                    &.logo {
                        &.Small,
                        &.XSmall {
                            display: none;
                        }
                        background-color: var(--color-text);
                        height: 40px;
                        position: absolute;
                        right: 0;
                        width: 218px;
                    }
                }
            }
            &.Medium,
            &.Small,
            &.XSmall {
                .mat-tab-links {
                    justify-content: space-between;
                    .mat-tab-label,
                    .mat-tab-link {
                        min-width: auto;
                        flex: 1;
                        &.logo,
                        &.tab-administration {
                            position: relative !important;
                            right: 0;
                        }
                    }
                }
            }
        }
        &.templates,
        &.documents {
            .mat-tab-link {
                padding: 0 8px;
                min-height: 48px;
                min-width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                gap: 8px;
            }
        }
    }
}
