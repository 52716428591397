$versions-sidenav-width: 166px;
.versions {
    &-sidenav {
        &.mat-drawer {
            display: flex !important;
            width: 25%;
            &.mat-drawer-side {
                border-right: 1px solid transparent;
            }
            .mat-drawer-inner-container {
                display: flex !important;
            }
        }
        &.mat-drawer[style*="visibility: hidden"],
        &:not(.mat-drawer-opened) {
            display: flex !important;
            transform: translate3d(0, 0, 0) !important;
            visibility: visible !important;
            width: $versions-sidenav-width !important;
            overflow: hidden;
        }
        &-button {
            margin: 12px 0;
        }
    }
    &-header {
        display: flex;
        justify-content: space-between;
        padding: 12px 8px;
        width: 100%;
        &-buttons {
            display: flex;
            gap: 16px;
        }
    }
    &-editor {
        background-color: var(--color-white);
        padding-right: 24px;
        &.folded {
            max-width: calc(100% - #{$versions-sidenav-width}) !important;
            margin: 0 0 0 $versions-sidenav-width !important;
        }
    }
    &-list {
        padding: 0 0 0 24px;
        width: 100%;
    }
}
