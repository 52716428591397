.spinner {
    transform: scale(2, 4);
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-content: center;
    .preload-icon {
        color: #333;
        display: block;
        font-size: 0;
        &>div {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            background-color: currentColor;
            border: 0 solid currentColor;
            display: inline-block;
            width: 4px;
            height: 12px;
            margin: 1px;
            position: relative;
            -webkit-animation: kf-bouncedelay 1.4s infinite ease-in-out both;
            animation: kf-bouncedelay 1.4s infinite ease-in-out both;
        }
        .bounce1 {
            -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;
        }
        .bounce2 {
            -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
        }
    }
}
