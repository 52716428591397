@use "@angular/material"as mat;
/// COLOR MAPS

/// Define custom colormaps

$mat-ctbox-santander: (50: #fce4e6,
    100: #f7bcc0,
    200: #f29097,
    300: #ed636d,
    400: #ec5862,
    500: #a4021a,
    600: #ec0000,
    700: #640404,
    800: #ad1e1e,
    900: #000000,
    A100: #fae16d,
    contrast: (50: #000000,
        100: #000000,
        200: #5e6767,
        300: #979797,
        400: #444444,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff)) !default;
$mat-ctbox-blue: (50: #7a9ae6,
    100: #95deff,
    200: #80bde1,
    300: #4495cd,
    400: #007ac3,
    500: #409bd2,
    600: #005b92,
    700: #0f4983,
    800: #003d61,
    900: #001b36,
    A50: #eefaff,
    A100: #3a617e,
    A200: #add8e6,
    A400: #426f9e,
    A500: #1b3af2,
    A700: #207699,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff)) !default;
$mat-ctbox-red: (50: #fce4e6,
    100: #f7bcc0,
    200: #f29097,
    300: #ed636d,
    400: #ec5862,
    500: #e5202e,
    600: #b25955,
    700: #92211d,
    800: #ff4f47,
    900: #c91d18,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff)) !default;
$mat-ctbox-green: (50: #deead2,
    100: #c6efb8,
    200: #85de66,
    300: #a4cd58,
    400: #85bc20,
    500: #629c2c,
    600: #238274,
    700: #7ba13b,
    800: #46801b,
    900: #346e10,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff)) !default;
$mat-ctbox-orange: (50: #fcf5e3,
    100: #f8e6b9,
    200: #f3d58b,
    300: #eec45c,
    400: #eab739,
    500: #e6aa16,
    600: #d2a922,
    700: #c3a920,
    800: #a08c1b,
    900: #c18318,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff)) !default;
$mat-ctbox-gray: (50: #ffffff,
    100: #f6f6f6,
    200: #ededed,
    300: #dadada,
    400: #bfbfbf,
    500: #a3a3a3,
    600: #757575,
    700: #474747,
    800: #232323,
    900: #000000,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff)) !default;
/// CUSTOM THEMES
/// Define palettes and create the objects of the custom themes
/// Light Theme (Default on all themes)
/// Dark Theme (alternative for another theme),
/// Default

$ctbox-primary-palette: mat.define-palette($mat-ctbox-blue, 500, 600, 900);
$ctbox-accent-palette: mat.define-palette(mat.$light-green-palette, 600, 800, 900);
$ctbox-warn-palette: mat.define-palette(mat.$red-palette, 600, 800, 900);

$default-theme: mat.define-light-theme((color: (primary: $ctbox-primary-palette,
            accent: $ctbox-accent-palette,
            warn: $ctbox-warn-palette),
        typography: $typography,
        density: -3));
/// Slide toogle
$slide-toogle-primary-palette: mat.define-palette(mat.$red-palette, 300, 800, 900);
$slide-toogle-accent-palette: mat.define-palette(mat.$grey-palette, 300, 600, 900);
$slide-toggle-theme: mat.define-light-theme((color: (primary: $slide-toogle-primary-palette,
            accent: $slide-toogle-accent-palette),
        typography: $typography));
/// Admin
$admin-primary-palette: mat.define-palette($mat-ctbox-orange);
$admin-accent-palette: mat.define-palette($mat-ctbox-gray);
$admin-theme: mat.define-light-theme((color: (primary: $admin-primary-palette,
            accent: $admin-accent-palette),
        typography: $typography,
        density: -3));
/// Clauses
$clauses-primary-palette: mat.define-palette($mat-ctbox-green);
$clauses-accent-palette: mat.define-palette($mat-ctbox-gray);
$clauses-theme: mat.define-light-theme((color: (primary: $clauses-primary-palette,
            accent: $clauses-accent-palette),
        typography: $typography,
        density: -3));
/// templates
///
$templates-primary-palette: mat.define-palette($mat-ctbox-green);
$templates-accent-palette: mat.define-palette($mat-ctbox-gray);
$templates-theme: mat.define-light-theme((color: (primary: $templates-primary-palette,
            accent: $templates-accent-palette),
        typography: $typography,
        density: -3));

/// administration
///
$administration-primary-palette: mat.define-palette($mat-ctbox-orange);
$administration-accent-palette: mat.define-palette($mat-ctbox-gray);
$administration-theme: mat.define-light-theme((color: (primary: $administration-primary-palette,
            accent: $administration-accent-palette),
        typography: $typography,
        density: -3));

/// SANTANDER
///
$santander-primary-palette: mat.define-palette($mat-ctbox-santander);
$santander-accent-palette: mat.define-palette(mat.$light-green-palette, 600, 800, 900);
$santander-theme: mat.define-light-theme((color: (primary: $santander-primary-palette,
            accent: $santander-accent-palette),
        typography: $typography,
        density: -3));

/// semantic-colors
$color-dashboard: #{map-get($mat-ctbox-gray, 800)};
$color-documents: #{map-get($mat-ctbox-blue, 300)};
$color-templates: #{map-get($mat-ctbox-green, 500)};
$color-clauses: #{map-get($mat-ctbox-green, 600)};
$color-administration: #{map-get($mat-ctbox-orange, 600)};
$color-santander: #{map-get($mat-ctbox-santander, 600)};
$color-santander-dark: #{map-get($mat-ctbox-santander, 800)};
$color-santander-black: #{map-get($mat-ctbox-santander, 900)};
$color-highlight: #{map-get($mat-ctbox-santander, A100)};
///
$color-state-draft: #{map-get($mat-ctbox-gray, 600)};
$color-state-review: #{map-get($mat-ctbox-blue, 50)};
$color-state-completed: #{map-get($mat-ctbox-blue, 100)};
$color-state-signing: #{map-get($mat-ctbox-orange, 500)};
$color-state-signed: #{map-get($mat-ctbox-green, 200)};

$color-update: #{map-get($mat-ctbox-blue, 600)};
$color-info: #{map-get($mat-ctbox-blue, 500)};
$color-white: #{map-get($mat-ctbox-gray, 50)};
$color-black: #{map-get($mat-ctbox-gray, 900)};
$color-text-default: #{map-get($mat-ctbox-gray, 800)};
$color-text-secondary: #{map-get($mat-ctbox-gray, 600)};
$color-disabled: #{map-get($mat-ctbox-gray, 300)};
$color-folder-icon: #{map-get($mat-ctbox-orange, 500)};
$border-color-default: #{map-get($mat-ctbox-gray, 300)};
$border-default: 1px solid #{$border-color-default};

$shadow-background: #{map-get($mat-ctbox-gray, 100)};
$background-color-insert: #{map-get($mat-ctbox-green, 50)};
$background-color-disabled: #{map-get($mat-ctbox-gray, 400)};
$background-color-deleted: #{map-get($mat-ctbox-red, 100)};
$tree-background-color: #{map-get($mat-ctbox-gray, 200)};
$tree-folder-icon-color: #{map-get($mat-ctbox-gray, 500)};
$tree-node-selected-background-color: #{map-get($mat-ctbox-gray, 100)};
$tree-drag-background-color: #{map-get($mat-ctbox-gray, 50)};

$background-color-input: #{map-get($mat-ctbox-gray, 200)};

$color-link-default: #{map-get($mat-ctbox-gray, 800)};
$color-link-hover: #{map-get($mat-ctbox-gray, 900)};
$color-link-editor-default: #{map-get($mat-ctbox-blue, A500)};
$color-link-editor-hover: #{map-get($mat-ctbox-blue, A500)};
$color-landing: #{map-get($mat-ctbox-blue, 700)};
$color-created: #{map-get($mat-ctbox-blue, 500)};
$color-title: #{map-get($mat-ctbox-blue, 500)};
$color-success: #{map-get($mat-ctbox-green, 400)};
$color-deleted: #{map-get($mat-ctbox-red, 400)};
$color-delete: #{map-get($mat-ctbox-red, 500)};
$color-error: #{map-get($mat-ctbox-red, 500)};
$color-warning-soft: #{map-get($mat-ctbox-orange, 50)};
$color-warning: #{map-get($mat-ctbox-orange, 500)};
$box-shadow: 0 2px 6px 0 rgb(0 0 0 / 16%);

///
:root {
    --color-dashboard: #{$color-dashboard};
    --color-documents: #{$color-documents};
    --color-templates: #{$color-templates};
    --color-clauses: #{$color-clauses};
    --color-administration: #{$color-administration};
    --color-state-draft: #{$color-state-draft};
    --color-state-review: #{$color-state-review};
    --color-state-completed: #{$color-state-completed};
    --color-state-signing: #{$color-state-signing};
    --color-state-signed: #{$color-state-signed};
    --box-shadow: #{$box-shadow};
    --shadow-background: #{$shadow-background};
    --border-color-default: #{$border-color-default};
    --border-default: #{$border-default};
    --color-white: #{$color-white};
    --color-black: #{$color-black};
    --color-info: #{$color-info};
    --color-text: #{$color-text-default};
    --color-text-secondary: #{$color-text-secondary};
    --color-link: #{$color-link-default};
    --color-link-hover: #{$color-link-hover};
    --color-link-editor: #{$color-link-editor-default};
    --color-link-editor-hover: #{$color-link-editor-hover};
    --color-disabled: #{$color-disabled};
    --color-update: #{$color-update};
    --background-color-disabled: #{$background-color-disabled};
    --background-color-deleted: #{$background-color-deleted};
    --background-color-insert: #{$background-color-insert};
    --background-color-input: #{$background-color-input};
    --tree-background-color: #{$tree-background-color};
    --tree-node-selected-background-color: #{$tree-node-selected-background-color};
    --tree-drag-background-color: #{$tree-drag-background-color};
    --tree-folder-icon-color: #{$tree-folder-icon-color};
    --color-deleted: #{$color-deleted};
    --color-delete: #{$color-delete};
    --color-created: #{$color-created};
    --color-success: #{$color-success};
    --color-error: #{$color-error};
    --color-warning: #{$color-warning};
    --color-warning-soft: #{$color-warning-soft};
    --color-title: #{$color-title};
    --color-landing: #{$color-landing};
    --color-santander: #{$color-santander};
    --color-santander-dark: #{$color-santander-dark};
    --color-santander-black: #{$color-santander-black};
    --color-highlight: #{$color-highlight};
    --color-folder-icon: #{$color-folder-icon};

    ///
    --color-red-500: #{map-get($mat-ctbox-red, 500)};
    --color-red-600: #{map-get($mat-ctbox-red, 600)};
    --color-red-700: #{map-get($mat-ctbox-red, 700)};
    --color-red-800: #{map-get($mat-ctbox-red, 800)};
    ///
    --color-blue-100: #{map-get($mat-ctbox-blue, 100)};
    --color-blue-200: #{map-get($mat-ctbox-blue, 200)};
    --color-blue-300: #{map-get($mat-ctbox-blue, 300)};
    --color-blue-400: #{map-get($mat-ctbox-blue, 400)};
    --color-blue-500: #{map-get($mat-ctbox-blue, 500)};
    --color-blue-600: #{map-get($mat-ctbox-blue, 600)};
    --color-blue-700: #{map-get($mat-ctbox-blue, 700)};
    --color-blue-800: #{map-get($mat-ctbox-blue, 800)};
    --color-blue-900: #{map-get($mat-ctbox-blue, 900)};
    --color-blue-A50: #{map-get($mat-ctbox-blue, A50)};
    --color-blue-A100: #{map-get($mat-ctbox-blue, A100)};
    --color-blue-A200: #{map-get($mat-ctbox-blue, A200)};
    --color-blue-A400: #{map-get($mat-ctbox-blue, A400)};
    --color-blue-A700: #{map-get($mat-ctbox-blue, A700)};
    ///
    --color-green-50: #{map-get($mat-ctbox-green, 50)};
    --color-green-100: #{map-get($mat-ctbox-green, 100)};
    --color-green-200: #{map-get($mat-ctbox-green, 200)};
    --color-green-300: #{map-get($mat-ctbox-green, 300)};
    --color-green-400: #{map-get($mat-ctbox-green, 400)};
    --color-green-500: #{map-get($mat-ctbox-green, 500)};
    --color-green-700: #{map-get($mat-ctbox-green, 700)};
    ///
    --color-orange-50: #{map-get($mat-ctbox-orange, 50)};
    --color-orange-200: #{map-get($mat-ctbox-orange, 200)};
    --color-orange-500: #{map-get($mat-ctbox-orange, 500)};
    --color-orange-600: #{map-get($mat-ctbox-orange, 600)};
    --color-orange-700: #{map-get($mat-ctbox-orange, 700)};
    --color-orange-800: #{map-get($mat-ctbox-orange, 800)};
    --color-orange-900: #{map-get($mat-ctbox-orange, 900)};
    ///
    --color-gray-100: #{map-get($mat-ctbox-gray, 100)};
    --color-gray-200: #{map-get($mat-ctbox-gray, 200)};
    --color-gray-300: #{map-get($mat-ctbox-gray, 300)};
    --color-gray-400: #{map-get($mat-ctbox-gray, 400)};
    --color-gray-500: #{map-get($mat-ctbox-gray, 500)};
    --color-gray-600: #{map-get($mat-ctbox-gray, 600)};
    --color-gray-700: #{map-get($mat-ctbox-gray, 700)};
    --color-gray-800: #{map-get($mat-ctbox-gray, 800)};
    ///
    $root-typography-config: $typography;
    $root-font-family-text: mat.font-family($root-typography-config);
    $root-font-family-icons: mat.font-family($root-typography-config, "display-4");
    --font-family-text: #{$root-font-family-text};
    --font-family-icons: #{$root-font-family-icons};
    ///
    --display-go-back: none;
}
