@use "@angular/material" as mat;
@mixin mat-tab-group($theme) {
    .mat-tab-group {
        &.templates {
            .mat-tab-label-active {
                border-bottom: 6px solid var(--color-templates);
            }
        }
        &.clauses {
            .mat-tab-label-active {
                border-bottom: 6px solid var(--color-clauses);
            }
        }
        &.administration {
            .mat-tab-label-active {
                border-bottom: 6px solid var(--color-administration);
            }
        }
        &.documents {
            .mat-tab-label-active {
                border-bottom: 6px solid var(--color-documents);
            }
        }
        &.draft {
            .mat-tab-label-active {
                border-bottom: 6px solid var(--color-state-draft);
            }
        }
        &.review {
            .mat-tab-label-active {
                border-bottom: 6px solid var(--color-state-review);
            }
        }
        &.signing {
            .mat-tab-label-active {
                border-bottom: 6px solid var(--color-state-signing);
            }
        }
        &.signed {
            .mat-tab-label-active {
                border-bottom: 6px solid var(--color-state-signed);
            }
        }
        &.completed {
            .mat-tab-label-active {
                border-bottom: 6px solid var(--color-state-completed);
            }
        }
        &.santander {
            border-bottom: 6px solid var(--color-santander);

            .mat-tab-label-active {
                border-bottom: 6px solid var(--color-santander-dark);
            }
        }
        &.c-help {
            .mat-tab-label {
                height: 48px;
                padding: 0 8px;
                min-width: 50px;
            }
            .mat-tab-header {
                height: 48px;
            }
            .mat-tab-body-wrapper {
                overflow: auto;
                height: calc(100% - 48px);
                max-height: 56vh;
            }
        }
    }
}
