$ctbox-title-bar-height: 40px;
.title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    min-height: $ctbox-title-bar-height;
    padding: 0 16px 0 0;
    &-info {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 8px;
    }
    &.head {
        &::after {
            content: "";
            border-top: var(--border-default);
            width: 100%;
            height: 0;
            position: absolute;
            top: 40px;
        }
    }
}
.ctbox-edit-name {
    display: block;
    max-width: 50%;
    width: 100%;
    &.head {
        align-items: center;
        display: flex;
        max-width: 100%;
        gap: 8px;
        padding-right: 8px;
        width: auto;
    }
}
