.cdk-drop-list {
    .cdk-drag {
        position: relative;
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        &:not(.cdk-drag-disabled) {
            &:active {
                cursor: move;
            }
        }
    }
    .cdk-drag-preview {
        background-color: var(--shadow-background);
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow:
            0 5px 5px -3px rgba(0, 0, 0, 0.2),
            0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
        &.signature-list-item {
            max-width: 400px !important;
            min-height: 200px !important;
            padding: 32px;
            background-color: rgba(255, 255, 255, 0.5);
        }
        &.cdk-tree-node {
            max-width: auto !important;
            min-height: auto !important;
            padding: 0;
        }
    }
    .cdk-drag-animating,
    .cdk-drop-list-dragging .mat-tree-node:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        &:hover {
            background-color: var(--color-blue-100);
        }
    }
    .cdk-drag-placeholder {
        opacity: 1;
    }
    .cdk-drag-disabled {
        cursor: default;
    }
    .over-drop-target {
        background-color: var(--color-warning-soft);
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow:
            0 5px 5px -3px rgba(0, 0, 0, 0.2),
            0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
        &.cdk-tree-node {
            max-width: auto !important;
            min-height: auto !important;
            padding: 0 20px;
        }
    }
}
