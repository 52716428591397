.clause-in-editor {
    margin    : 5px 0;
    padding   : 16px;
    border    : 1px dotted gray;
    background: lightblue;
}

.clause-in-editor p {
    pointer-events: none;
    user-select: none;
}

.clause-in-editor div *:not(> input):not(> div > input){
    pointer-events: none;
    user-select: none;
}

.clause-in-editor p:has(> span) {
    pointer-events: auto;
    user-select: auto;
}

.clause-in-editor div > div > p {
    pointer-events: none !important;
    user-select: none !important;
}

.clause-in-editor div > div > div > p {
    pointer-events: none  !important;
    user-select: none  !important;
}

.cke_editable:not(.FLITE-Tracking) .clause-in-editor.ice-del {
    display: none;
}

.icon-alert {
    background: transparent url("../assets/icons/icon-alert.svg") 0% 35% no-repeat padding-box;
    opacity   : 1;
    padding   : 0 10px;
    border    : none;
}

.cmc-santander .clause-in-editor {
    background: #a4021a12 !important;
}
