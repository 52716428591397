@use "@angular/material" as mat;
@mixin mat-table($theme) {
    $tr-even-background-color: var(--shadow-background);
    $tr-active-background-color: var(--color-gray-200);
    $tr-selected-background-color: var(--color-orange-50);
    $tr-selected-hover-background-color: var(--color-orange-200);
    $tr-permission-background-color: var(--color-gray-200);
    $td-permission-border-color: var(--color-gray-400);
    $td-permission-icon-admin-color: var(--color-orange-600);
    $td-permission-icon-access-color: var(--color-blue-500);

    .mat-table {
        caption {
            display: none;
        }
        &.table-list {
            width: 100%;
            .icon {
                display: inline-block;
                font-size: 24px;
                font-style: normal;
                text-align: center;
                margin: 3px 0 0 0;
                width: 24px;
                height: 24px;
            }
            tr.mat-header-row {
                height: auto;
                &.xl {
                    height: 56px;
                }
            }
            th.mat-header-cell {
                color: var(--color-text);
                border-bottom-color: var(--border-color-default);
                font-size: 14px;
                padding: 4px;
                vertical-align: middle;
                line-height: 24px;
            }
            .mat-row {
                &:nth-child(odd) {
                    background-color: var(--shadow-background);
                }
            }
            td.mat-cell {
                border-bottom: none;
                color: var(--color-text);
                cursor: default;
                font-size: 13px;
                line-height: 1.75em;
                padding: 0 4px;
                vertical-align: middle;
                &.mat-column-expandedDetail {
                    padding: 0;
                    .mat-column-description {
                        margin-left: 40px;
                    }
                }
            }
        }
        &.table-bg-white {
            box-sizing: border-box;
            max-width: 100%;
            min-width: 100%;
            width: 100%;
            tr.mat-row,
            tr.mat-footer-row,
            tr.mat-header-row {
                height: auto;
            }
            .mat-cell {
                vertical-align: middle;
                padding: 4px 8px 0 0;
                &-check {
                    width: 32px;
                    line-height: 32px;
                    text-align: center;
                    vertical-align: middle;
                }
            }
            .mat-row {
                &:nth-child(even) {
                    background-color: $tr-even-background-color;
                }
                &:focus,
                &:hover,
                &:active {
                    background-color: $tr-active-background-color;
                    transition: background-color 200ms ease-in-out;
                }
                &.selected {
                    background-color: $tr-selected-background-color;
                    &:focus,
                    &:hover,
                    &:active {
                        background-color: $tr-selected-hover-background-color;
                        transition: background-color 200ms ease-in-out;
                    }
                }
            }
        }
        &.table-permission {
            width: 100%;
            th.mat-header-cell {
                background-color: $tr-permission-background-color;
                border-left: 1px solid $td-permission-border-color;
                padding: 8px;
            }
            td.mat-cell {
                padding: 8px 16px;
                border-left: 1px solid $td-permission-border-color;
            }
        }
        &.table-ctbox-border {
            box-shadow: 0 2px 6px 0 rgb(0 0 0 / 16%);
            border: var(--border-default);
        }
        &.table-legal-alert {
            th.mat-header-cell {
                color: var(--color-santander-dark);
                font-size: 1.2em;
                padding: 4px;
                vertical-align: top;
                .mat-sort-header-content {
                    padding: 4px 0;
                }
            }
            td.mat-cell {
                padding: 8px;
            }
        }
    }
    .mat-paginator-container {
        min-height: auto;
    }
    .mat-paginator-range-label {
        margin: 0;
    }
    .mat-cell-flex {
        display: flex;
        align-items: center;
    }
    .mat-cell-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mat-cell-text {
            font-size: 1em;
        }
        .mat-cell-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border-radius: 50%;
            &.administrator {
                color: var(--color-white);
                background-color: var(--color-administration);
            }
        }
    }
    .mat-sort-header-content {
        text-align: left;
        flex-direction: column;
        align-items: flex-start;
        white-space: nowrap;
    }

    .mat-column {
        &-icon,
        &-iconAdditional,
        &-inForceColumn {
            width: 60px;
            max-width: 60px;
            vertical-align: middle;
        }
        &-nameVersion,
        &-annexedName,
        &-contractName,
        &-name {
            width: 100%;
            max-width: 200px;
        }
        &-signers,
        &-modificationTimeStamp {
            min-width: 150px;
        }
        &-documentStatus {
            min-width: 150px;
        }

        &-lastModificationUser,
        &-creationUser {
            min-width: 200px;
        }
        &-clauseFolder,
        &-templateFolder,
        &-contractFolder {
            max-width: 200px;
        }
    }
    .mat-cell {
        .name,
        .link {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
