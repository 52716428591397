@use "@angular/material" as mat;
@mixin mat-button($theme, $clauses: null) {
    $mat-icon-stroked-button-size: 30px;
    $mat-icon-button-size-s: 24px !important;
    $mat-icon-button-size: 32px;
    $mat-icon-button-font-size: 24px;
    $mat-icon-button-size-m: 40px;
    $mat-icon-button-size-xs: 38px;
    $color-config: mat.get-color-config($theme);

    $primary-palette: map-get($color-config, "primary");
    $warn-palette: map-get($color-config, "warn");
    $accent-palette: map-get($color-config, "accent");

    $mat-flat-button-bg-color: mat.get-color-from-palette($primary-palette, 500);
    $mat-stroked-button-border-color: mat.get-color-from-palette($primary-palette, 500);
    $mat-stroked-button-icon-mat-icon-bg-color: mat.get-color-from-palette($primary-palette, 500);
    $mat-stroked-button-color: mat.get-color-from-palette($primary-palette, 500);
    $mat-icon-button-mat-icon-bg-color: darken(mat.get-color-from-palette($primary-palette, 500), 25%);

    $mat-icon-button-mat-icon-bg-color-administration: darken(mat.get-color-from-palette($administration-primary-palette, 500), 25%);
    $mat-icon-button-mat-icon-bg-color-templates: darken(mat.get-color-from-palette($templates-primary-palette, 500), 25%);
    $mat-icon-button-mat-icon-bg-color-clauses: darken(mat.get-color-from-palette($clauses-primary-palette, 600), 15%);

    $mat-stroked-button-color-warn: mat.get-color-from-palette($warn-palette, 500);
    $mat-stroked-button-color-accent: mat.get-color-from-palette($accent-palette, 500);
    $mat-icon-button-mat-icon-bg-color-warn: darken(mat.get-color-from-palette($warn-palette, 500), 25%);
    $mat-stroked-button-icon-mat-icon-bg-color-warn: darken(mat.get-color-from-palette($warn-palette, 500), 25%);
    $mat-stroked-button-icon-mat-icon-bg-color-accent: darken(mat.get-color-from-palette($accent-palette, 500), 25%);

    .mat-button,
    .mat-icon-button,
    .mat-raised-button,
    .mat-flat-button {
        line-height: $mat-icon-button-size;
        min-width: $mat-icon-button-size;
        min-height: $mat-icon-button-size;
        border-radius: 0;
        &.mat-icon-button-left {
            padding: 0;
        }
        &.mat-icon-button-right {
            padding: 0;
        }
    }
    .mat-button-icon,
    .mat-icon-button {
        align-items: center;
        display: inline-flex;
        justify-content: center;
        width: $mat-icon-button-size;
        height: $mat-icon-button-size;
        line-height: $mat-icon-button-size;
        min-width: $mat-icon-button-size !important;
        padding: 0;
        &.Small,
        &.XSmall {
            width: $mat-icon-button-size-xs;
            height: $mat-icon-button-size-xs;
        }
        &.xsmall {
            width: 20px !important;
            height: 20px !important;
            line-height: 20px !important;
            min-width: 20px !important;
            min-height: 20px !important;
            font-size: 20px !important;
        }
        &[class*="mat-calendar"] {
            width: $mat-icon-button-size-m;
            height: $mat-icon-button-size-m;
            line-height: $mat-icon-button-size-m;
            min-width: $mat-icon-button-size-m !important;
        }
        &.hidden {
            display: var(--display-go-back);
        }
    }
    .mat-icon {
        width: $mat-icon-button-size;
        height: $mat-icon-button-size;
        line-height: $mat-icon-button-size;
        font-size: $mat-icon-button-font-size;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            max-width: 18px;
            max-height: 24px;
        }
    }
    .mat-icon,
    .material-symbols-outlined {
        font-family: var(--font-family-icons);
        font-variation-settings:
            "FILL" 0,
            "wght" 400,
            "GRAD" 0,
            "opsz" 48;
        &.filled {
            font-variation-settings: "FILL" 1;
        }
        &.xxs {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            font-size: 14px !important;
        }
        &.small {
            width: 16px;
            height: 16px;
            min-width: 16px;
            min-height: 16px;
            font-size: 16px !important;
        }
        &.xsmall {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            font-size: 20px !important;
        }
        &.medium {
            min-width: 36px;
            min-height: 36px;
            font-size: 36px !important;
        }
        &.big {
            min-width: 48px;
            min-height: 48px;
            font-size: 48px !important;
        }
        &.xl {
            min-width: 56px;
            min-height: 56px;
            font-size: 56px !important;
        }

        &.state {
            width: 32px;
            height: 32px;
            background-color: transparent !important;
        }
        &.title {
            font-size: 32px !important;
        }
        &.warning {
            color: var(--color-warning);
        }
        &.info {
            color: var(--color-info);
        }
        &.done,
        &.true {
            color: var(--color-success);
        }
        &.error,
        &.false {
            color: var(--color-error);
        }
        &.draft {
            color: var(--color-state-draft) !important;
        }
        &.review {
            color: var(--color-state-review) !important;
        }
        &.completed {
            color: var(--color-state-completed) !important;
        }
        &.signing {
            color: var(--color-state-signing) !important;
        }
        &.signed {
            color: var(--color-state-signed) !important;
        }

        &.template-clause {
            &.cmc {
                border-radius: 100%;
                border-right-color: var(--color-santander-dark);
                border-top-color: var(--color-disabled);
                border-bottom-color: var(--color-santander-dark);
                border-left-color: var(--color-disabled);
                border-width: 12px;
                border-style: solid;
                height: 0;
                width: 0;
                transform: rotate(-45deg);
            }
        }
        &.template-document {
            border-radius: 100%;
            border-right-color: var(--color-templates);
            border-top-color: var(--color-documents);
            border-bottom-color: var(--color-templates);
            border-left-color: var(--color-documents);
            border-width: 10px;
            border-style: solid;
            height: 0;
            width: 0;
            transform: rotate(-45deg);
        }
        &.type {
            font-family: var(--font-family-sans-serif) !important;
            font-size: 0;
            &:before {
                align-content: center;
                align-items: center;
                background-color: var(--color-disabled);
                border-radius: 50%;
                color: var(--color-white);
                content: " ";
                display: flex;
                justify-content: center;
                font-size: 12px;
                height: 22px;
                width: 22px;
            }
            &.document {
                &:before {
                    background-color: var(--color-documents);
                    content: "D";
                }
            }
            &.template {
                &:before {
                    background-color: var(--color-templates);
                    content: "P";
                }
                &.cmc {
                    &:before {
                        background-color: var(--color-disabled);
                    }
                }
            }
            &.clause {
                &.cmc {
                    &:before {
                        background-color: var(--color-santander-dark);
                        content: "C";
                    }
                }
            }
        }
    }

    .mat-flat-button {
        &:not(&.mat-button-disabled) {
            &.mat-primary {
                .mat-button-text-right,
                .mat-button-text-left {
                    background: $mat-flat-button-bg-color;
                }
                .mat-icon {
                    background-color: $mat-icon-button-mat-icon-bg-color;
                }
            }
            &.mat-accent {
                background: $mat-stroked-button-color-accent;
                color: var(--color-white);
                .mat-icon {
                    background-color: $mat-stroked-button-icon-mat-icon-bg-color-accent;
                    color: var(--color-white);
                }
            }
            &.mat-templates {
                background: var(--color-templates);
                color: var(--color-white);
                .mat-icon {
                    background-color: $mat-icon-button-mat-icon-bg-color-templates;
                    color: var(--color-white);
                }
            }
            &.mat-clauses {
                background: var(--color-clauses);
                color: var(--color-white);
                .mat-icon {
                    background-color: $mat-icon-button-mat-icon-bg-color-clauses;
                    color: var(--color-white);
                }
            }
            &.mat-administration {
                background: var(--color-administration);
                color: var(--color-white);
                .mat-icon {
                    background-color: $mat-icon-button-mat-icon-bg-color-administration;
                    color: var(--color-white);
                }
            }
            &.mat-warn,
            &.mat-error {
                background: var(--color-error);
                color: var(--color-white);
                .mat-icon {
                    background-color: $mat-icon-button-mat-icon-bg-color-warn;
                    color: var(--color-white);
                    svg {
                        fill: inherit;
                    }
                }
            }
            &.mat-santander {
                background: var(--color-santander);
                color: var(--color-white);
                .mat-icon {
                    color: var(--color-white);
                    background-color: var(--color-santander-dark);
                }
            }
        }
    }

    .mat-stroked-button {
        border-radius: 0;
        line-height: $mat-icon-stroked-button-size;
        min-width: $mat-icon-stroked-button-size;
        &:not(&.mat-button-disabled) {
            &.mat-primary {
                border-color: $mat-stroked-button-border-color;
                background: var(--color-white);
                color: $mat-stroked-button-color;
                .mat-icon {
                    background-color: $mat-stroked-button-icon-mat-icon-bg-color;
                    color: var(--color-white);
                }
            }
            &.mat-accent {
                border-color: $mat-stroked-button-color-accent;
                .mat-icon {
                    background-color: $mat-stroked-button-icon-mat-icon-bg-color-accent;
                    color: var(--color-white);
                }
            }
            &.mat-warn {
                border-color: $mat-stroked-button-color-warn;
                .mat-icon {
                    background-color: $mat-stroked-button-icon-mat-icon-bg-color-warn;
                    color: var(--color-white);
                }
            }
            &.mat-templates {
                border-color: var(--color-templates);
                color: var(--color-templates);
                &.mat-icon-button-left {
                    .mat-icon {
                        border-right: 1px solid var(--color-templates);
                        color: var(--color-templates);
                    }
                }
            }
            &.mat-clauses {
                border-color: var(--color-clauses);
                color: var(--color-clauses);
                &.mat-icon-button-left {
                    .mat-icon {
                        border-right: 1px solid var(--color-clauses);
                        color: var(--color-clauses);
                    }
                }
            }
            &.mat-administration {
                border-color: var(--color-administration);
                color: var(--color-administration);
                &.mat-icon-button-left {
                    .mat-icon {
                        border-right: 1px solid var(--color-administration);
                        color: var(--color-administration);
                    }
                }
            }
        }
        &.mat-permission-discard {
            border-radius: 8px;
            border-color: var(--color-error);
            color: var(--color-error);
            font-size: 12px;
            line-height: 16px;
            min-height: 20px;
            &:hover {
                background-color: var(--color-error);
                color: var(--color-white);
            }
        }
        &.mat-permission-assign {
            border-radius: 8px;
            border-color: var(--color-success);
            color: var(--color-success);
            font-size: 12px;
            line-height: 16px;
            min-height: 20px;
            &:hover {
                background-color: var(--color-success);
                color: var(--color-white);
            }
        }
        .mat-icon {
            height: $mat-icon-stroked-button-size;
            width: $mat-icon-stroked-button-size;
        }
    }
    .mat-button-wrapper {
        display: flex;
        align-items: center;
    }
    .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix {
        .mat-icon-button {
            line-height: $mat-icon-button-size-s;
            height: $mat-icon-button-size-s;
            width: $mat-icon-button-size-s;
            max-width: $mat-icon-button-size-s;
            min-width: $mat-icon-button-size-s;
            max-height: $mat-icon-button-size-s;
            min-height: $mat-icon-button-size-s;
        }
    }
    .mat-rrss {
        background-color: transparent;
        color: var(--color-white);
    }
    .mat-info {
        color: var(--color-info);
    }
    .mat-success {
        color: var(--color-success);
        &.border {
            border: 1px solid var(--color-success);
        }
    }
    .mat-delete {
        color: var(--color-delete);
    }
    .mat-state {
        border: var(--border-default);
        &.no-border {
            border-color: transparent;
        }
    }

    .mat-datepicker-close-button {
        &::before {
            content: "\e5cd";
            display: block;
            font-family: var(--font-family-icons);
            font-size: 24px;
            line-height: 36px;
        }
        .mat-button-wrapper {
            display: none;
        }
    }
    .mat-button-focus-overlay {
        background: transparent;
    }

    .mat-button-disabled,
    .disabled {
        cursor: not-allowed;
        pointer-events: none !important;
        border-color: var(--color-disabled) !important;
        &.mat-icon-button-right,
        &.mat-icon-button-left {
            .mat-icon {
                background-color: var(--background-color-disabled);
            }
        }
    }
    .mat-button-text-right,
    .mat-button-text-left {
        padding: 0 16px;
    }
    .xl {
        .mat-button-text-left,
        .mat-button-text-right {
            font-size: 30px;
        }
    }
    .text-default {
        *[class*="mat-button-text"] {
            color: var(--color-text);
        }
    }
    .cursor-default {
        cursor: default;
    }

    .mat-account {
        color: var(--color-white);
        .mat-button-wrapper {
            justify-content: center;
        }
        &-login {
            background-color: var(--color-blue-300);
            &:hover {
                background-color: var(--color-blue-700) !important;
            }
        }
        &-admin {
            background-color: var(--color-blue-700);
            &:hover {
                background-color: var(--color-blue-300) !important;
            }
        }
        &-logout {
            background-color: var(--color-gray-300);
            color: var(--color-gray-700);
            &:hover {
                background-color: var(--color-gray-700) !important;
                color: var(--color-white);
            }
        }
    }
    .border-left {
        border-left: var(--border-default) !important;
    }

    .mat-menu-item {
        height: 32px;
    }
}
